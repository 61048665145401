import axios from 'axios'

export const all = (): Promise<any> => {
  return axios.get('/reportment-authorizations')
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const update = (id: number, data: any): Promise<any> => {
  return axios.put(`/reportment-authorizations/${id}`, data)
}
