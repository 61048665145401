import React, { useEffect, useState, useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import IUser from '@interfaces/IUser'
import * as $ManagerMail from '@services/ManagerMail'
import columns from '@data/ReactTableManagerMailColumns'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import ManagerMailEdit from './ManagerMail.edit'
import { getCssProperty } from '@helpers/Theme'

const ManagerMail: React.FC<any> = () => {

  const [ managers, setManagers ] = useState<IUser[]>([] as IUser[])
  const [ isLoading, setIsLoading ] = useState(true)
  const [ search, setSearch ] = useState<string>('')

  const [ manager, setManager ] = useState<IUser|null>(null)

  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)

  const getManagerMails = () => {
    $ManagerMail.all().then(({ data }) => setManagers(data)).finally(() => setIsLoading(false))
  }

  useEffect(getManagerMails, [])

  const data = useMemo(() => managers.filter((manager: IUser) => {
    let exists = true

    if (search.length > 0)
      exists = manager.clientId.toString().includes(search) ||
      manager.clientName.toLowerCase().includes(search.toLowerCase()) ||
      manager.email.toLowerCase().includes(search.toLowerCase())

    return exists
  }), [managers, search])

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const handleOnUpdate = () => {
    setIsLoading(true)

    SweetAlert.showLoading()

    $ManagerMail.updateSchoolManager().then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'E-mails atualizados com sucesso!',
        icon: 'success'
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao atualizar e-mails!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  const update = () => {
    if (manager === null || !manager?.id)
      return

    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde',
      text: 'Salvando atualizações...',
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $ManagerMail.update(manager)
      .then(() => {
        getManagerMails()

        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'E-mail atualizado com sucesso!',
          icon: 'success',
          didOpen: () => SweetAlert.hideLoading(),
        })
      })
      .catch(() => setIsLoading(false))
  }

  const destroy = (manager: IUser) => {
    SweetAlert.fire({
      title: 'Deseja prosseguir?',
      text: 'Deseja prosseguir com a exclusão? Você não poderá voltar atrás dessa decisão.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--secondary-color'),
      cancelButtonColor: getCssProperty('--primary-color'),
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result: any) => {
      if (result.value && manager?.id) {
        setIsLoading(true)

        SweetAlert.showLoading()

        $ManagerMail.destroy(manager.id as number).then(() => {
          setManagers(managers => managers.filter(managerFilter => managerFilter.id !== manager.id))
        }).finally(() => {
          setIsLoading(false)
          SweetAlert.hideLoading()
        })
      }
    })
  }

  const cancel = () => setManager(null)

  const onChangeChecked = (manager: IUser) => {
    setIsLoading(true)
    SweetAlert.showLoading()

    $ManagerMail.updateGestao(manager.id, manager.gestaoUsuarios)
      .then(() => {
        getManagerMails()
        SweetAlert.fire({
          title: 'Sucesso!',
          icon: 'success',
          didOpen: () => SweetAlert.hideLoading(),
        })
      })
      .catch(() => setIsLoading(false))
  }

  return (
    <>
      <div className="row align-items-end mb-3">
        <div className="col-8 col-md-8">
          <div className="form-group">
            <label htmlFor="search">Pesquisar:</label>
            <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo cliente ou e-mail" onChange={e => setSearch(e.target.value)} disabled={isLoading} />
          </div>
        </div>
        <div className="col-4 col-md-4 d-flex justify-content-end align-items-end">
          <button className="btn btn-primary" onClick={() => navigate('/email-gestores/cadastrar')} disabled={isLoading}>Cadastrar</button>
        </div>
      </div>

      <div className="row">
        <div className={'col-12 ' + (!manager ? 'col-md-12' : 'col-md-8') + ' order-2 order-md-1'}>
          <div className="card">
            <div className="card-header">E-mail Gestores</div>
            <div className="card-body p-0">
              {managers.length ? (
                <div className="table-responsive">
                  <table {...getTableProps()} className="table table-default mb-0">
                    <thead>
                      {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                          {headerGroup.headers.map((column: any, columnIndex: number) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-nowrap" key={columnIndex}>
                              {column.render('Header')}
                              <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                            </th>
                          ))}
                          <th style={{ width: 150 }}>Gestão de usuários</th>
                          <th style={{ width: 150 }}>Ações</th>
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {data.length > 0 ? page.map((row: any, rowIndex: number) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()} key={rowIndex}>
                            {row.cells.map((cell: any, index: number) => {
                              return (
                                <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                                  {cell.render('Cell')}
                                </td>
                              )
                            })}
                            <td style={{ verticalAlign: 'middle' }}>
                              <div className="d-flex justify-content-center">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  style={{ minWidth: 14 }}
                                  checked={row.original.gestaoUsuarios}
                                  onChange={() => onChangeChecked(row.original)}
                                />
                              </div>
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              <div className="d-flex justify-content-end">
                                <button
                                  className="btn btn-sm btn-primary pe-3 ps-3 ms-2"
                                  onClick={() => setManager(row.original)}
                                  disabled={row.original.id === manager?.id || isLoading}
                                >Editar</button>
                                <button
                                  className="btn btn-sm btn-danger text-white pe-3 ps-3 ms-2"
                                  onClick={() => destroy(row.original)}
                                  disabled={row.original.id === manager?.id || isLoading}
                                >Excluir</button>
                              </div>
                            </td>
                          </tr>
                        )
                      }) : (
                        <tr>
                          <td colSpan={4} style={{textAlign: 'center'}}>Nenhum resultado encontrado.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (isLoading ? (
                <div className="d-flex justify-content-center p-3 bg-light">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div className="bg-light text-center p-3">
                  Nenhum e-mail de gestor cadastrado.
                </div>
              ))}
            </div>

            <div className="card-footer pb-0">
              <div className="row align-items-center">
                <div className="col d-flex justify-content-center justify-content-md-start mb-3">
                  <button className="btn btn-primary" onClick={handleOnUpdate} disabled={isLoading}>Atualizar escola gestores</button>
                </div>
                {pageCount > 1 && (
                  <div className="col d-flex justify-content-center justify-content-md-end mb-3">
                    <Pagination
                      page={pageIndex}
                      pageCount={pageCount}
                      canPreviousPage={canPreviousPage}
                      canNextPage={canNextPage}
                      nextPage={nextPage}
                      previousPage={previousPage}
                      onClick={(page: number) => gotoPage(page)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {manager ? (
          <div className="col-12 col-md-4 order-1 order-md-2">
            {manager?.id ? (
              <ManagerMailEdit
                manager={manager}
                setManager={setManager}
                isLoading={isLoading}
                onSave={update}
                onCancel={cancel}
              />
            ) : null }
          </div>
        ) : null}
      </div>
    </>
  )
}

export default ManagerMail