import IUserGroup from '@interfaces/IUserGroup'
import $User from '@services/User'
import moment from 'moment'
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'

type Props = {
  currentUserId: number
  userGroup: IUserGroup
  user: IUserGroup['users'][0]
  onLoad: (userGroup: IUserGroup) => void
}

const User: React.FC<Props> = ({ currentUserId, userGroup, user, onLoad }: Props) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const onRemove = () => {
    setIsLoading(true)

    $User.userGroup(currentUserId).remove(user.id).then(() => {
      onLoad({
        ...userGroup,
        users: userGroup.users.filter(({ id }) => (
          id !== user.id
        )),
      })
    }).finally(() => setIsLoading(false))
  }

  return (
    <tr>
      <td valign="middle">{user.id}</td>
      <td valign="middle">{user.usercode}</td>
      <td valign="middle">{user.name}</td>
      <td valign="middle">
        {user.email && user.email.length > 0 ? user.email : (
          <small className="text-muted">N/A</small>
        )}
      </td>
      <td valign="middle">{moment(user.createdAt).format('DD/MM/YYYY')}</td>
      <td valign="middle" className="d-flex justify-content-end">
        <button className="btn btn-danger pe-3 ps-3" onClick={onRemove} disabled={isLoading}>
          Remover {isLoading && <Spinner className="ps-1" animation="border" size="sm" />}
        </button>
      </td>
    </tr>
  )
}

export default User
