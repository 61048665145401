import React from 'react'
import * as $Utils from '@helpers/Utils'

const download = () => $Utils.exportXLSX([
  [
    'ID da Escola',
    'RA',
    'RE',
    'ID da Plataforma',
    'ID do Simulado',
    'Excluir',
  ],
], 'Cadastro de usuários', 'Planilha modelo.xlsx')

const Download: React.FC<any> = () => {
  return (
    <button className="btn btn-primary text-nowrap me-3 pe-3 ps-3" onClick={download}>Baixar modelo</button>
  )
}

export default Download
