import React, { useState } from 'react'
import Button from '@components/Button/Button'
import * as $User from '@services/User'

type Props = {
  userId: number
  password: string
  disabled?: boolean
}

const Password: React.FC<any> = ({ userId, password, disabled }: Props) => {
  const [ passwordSent, setPasswordSent ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ hasError, setHasError ] = useState(false)

  const sendPassword = () => {
    setIsLoading(true)

    $User.sendPassword(userId).then(() => {
      setPasswordSent(true)
      setHasError(false)
    }).catch(() => {
      setPasswordSent(false)
      setHasError(true)
    }).finally(() => setIsLoading(false))
  }

  if (!passwordSent && password.length > 20) {
    return (
      <>
        <Button
          className={'btn btn-link text-nowrap' + (hasError && !isLoading ? ' text-danger' : '')}
          onClick={sendPassword}
          isLoading={isLoading}
          disabled={isLoading || passwordSent}
          loadingText="Enviando, aguarde..."
        >
          {hasError ? 'Erro ao enviar, tentar novamente?' : 'Enviar nova senha'}
        </Button>
      </>
    )
  }

  if (passwordSent) {
    return <small className="text-success">Senha enviada</small>
  }

  return (
    <Button
      className={'btn btn-link text-nowrap' + (hasError && !isLoading ? ' text-danger' : '')}
      onClick={sendPassword}
      isLoading={isLoading}
      disabled={isLoading || passwordSent || disabled}
      loadingText="Enviando, aguarde..."
    >
      {hasError ? 'Erro ao enviar, tentar novamente?' : 'Enviar senha por e-mail'}
    </Button>
  )
}

export default Password
