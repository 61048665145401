import React, { useMemo } from 'react'
import moment from 'moment'

const useReportmentCategoryColumns = (onClick: (category: any) => void, onDelete: (category: any) => void, canEdit: boolean): any => {
  return useMemo(() => [
    {
      Header: '',
      accessor: 'media',
      disableSortBy: true,
      Cell: ({ row: { original: category } }: any) => (
        <button
          className="thumbnail"
          style={{
            backgroundImage: category.images ? `url(${category.images.thumbnail})` : '',
          }}
          onClick={() => onClick(category)} disabled={!canEdit}
        ></button>
      ),
      style: {
        width: 80,
      },
    },
    {
      Header: 'ID',
      accessor: 'id',
      style: {
        width: 60,
      },
    },
    {
      Header: 'Nome',
      accessor: 'name',
    },
    {
      Header: 'Data de criação',
      accessor: 'createdAt',
      Cell: ({ row: { original: category } }: any) => moment(category.createdAt).format('DD/MM/YYYY [ás] hh:mm'),
      style: {
        width: 160,
      },
    },
    {
      Header: 'Ações',
      disableSortBy: true,
      Cell: ({ row: { original: category } }: any) => (
        <>
          <button className="btn btn-sm ps-3 pe-3 btn-primary me-2" onClick={() => onClick(category)} disabled={!canEdit}>Editar</button>
          <button className="btn btn-sm ps-3 pe-3 btn-danger" onClick={() => onDelete(category)} disabled={!canEdit}>Excluir</button>
        </>
      ),
      style: {
        width: 160,
        textAlign: 'right',
      },
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [canEdit])
}

export default useReportmentCategoryColumns
