/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'

export const all = (): Promise<any> => axios.get('/questions/question-category-types')

export const find = (id: number): Promise<any> => axios.get(`/questions/question-category-types/${id}`)

export const create = (questionCategoryType: any): Promise<any> => axios.post('/questions/question-category-types', questionCategoryType)

export const update = (id: number, questionCategoryType: any): Promise<any> => axios.put(`/questions/question-category-types/${id}`, questionCategoryType)

export const destroy = (id: number): Promise<any> => axios.delete(`/questions/question-category-types/${id}`)
