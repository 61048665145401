import styled from 'styled-components'

export const Search = styled.div`
  @media (min-width: 576px) {
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;

    > .row {
      width: 100%;
      margin-right: calc(-.5 * var(--bs-gutter-x));
      margin-left: calc(-.5 * var(--bs-gutter-x));
    }
  }

  @media (max-width: 768px) {
    > .btn {
      width: 100%;
      display: block;
    }
  }
`

export const Buttons = styled.div`
  @media (max-width: 768px) {
    > .btn {
      width: 50%;
      display: block;
    }
  }
`
