import React from 'react'
import IEntityValidationError from '@interfaces/IEntityValidationError'
import { BsChevronRight } from 'react-icons/bs'
import { Error } from './Errors.styles'
import { Modal } from 'react-bootstrap'

type IErrorsProps = {
  errors: IEntityValidationError[];
  onClick: (error: IEntityValidationError) => void;
}

const Errors: React.FC<any> = ({ errors, onClick }: IErrorsProps) => {
  return (
    <Modal.Body className="pb-0">
      <div className="errors">
        {errors?.map((error: IEntityValidationError) => {
          return (
            <Error className="mb-3" key={error.id}>
              <button className="btn btn-outline-danger" onClick={() => onClick(error)}>
                <span>{error?.description}</span> <BsChevronRight size={22} />
              </button>
            </Error>
          )
        })}
      </div>
    </Modal.Body>
  )
}

export default Errors
