import axios from 'axios'

export const all = (reportmentId: number): Promise<any> => axios.get(`/reportments/${reportmentId}/charts`)

export const store = (reportmentId: number, data: FormData): Promise<any> => axios.post(`/reportments/${reportmentId}/charts`, data, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

export const destroy = (reportmentId: number, chartId: number): Promise<any> => axios.delete(`/reportments/${reportmentId}/charts/${chartId}`)

export const update = (reportmentId: number, chartId: number, data: FormData): Promise<any> => axios.put(`/reportments/${reportmentId}/charts/${chartId}`, data, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
