import React, { useEffect, useState } from 'react'
import { Buttons } from '../StudyPlan.styles'
import IClient from '@interfaces/IClient'
import * as $Client from '@services/Client'
import * as $StudyPlan from '@services/StudyPlan'
import Select from '@components/Select/Select'
import IEvaluation from '@interfaces/IEvaluation'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Button from '@components/Button/Button'
import IStudyPlan from '@interfaces/IStudyPlan'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

type Props = {
  studyPlan: IStudyPlan
  setStudyPlan: React.Dispatch<React.SetStateAction<IStudyPlan>>
  onCancel: () => void
  onSuccess: () => void
}

const Study: React.FC<any> = ({ studyPlan, setStudyPlan, onCancel, onSuccess }: Props) => {
  const [ clients, setClients ] = useState<IClient[]>([])
  const [ clientId, setClientId ] = useState<number>(0)
  const [ evaluations, setEvaluations ] = useState<IEvaluation[]>([])
  const [ evaluationId, setEvaluationId ] = useState<number>(0)

  const [ file, setFile ] = React.useState<File|null>(null)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0])
      setStudyPlan({
        ...studyPlan,
        file: e.target.files[0]
      } as IStudyPlan)
    }
  }

  useEffect(() => {
    $Client.all().then(({ data }: any) => setClients(data))
  }, [])

  useEffect(() => {
    $StudyPlan.findEvaluations(clientId).then(({ data }: any) => setEvaluations(data))
  }, [clientId])

  const handleInputChange = (e: any) => {
    const { name, value } = e.target

    setStudyPlan({
      ...studyPlan,
      [name]: value,
    })
  }

  const SweetAlert = withReactContent(Swal)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    SweetAlert.showLoading()

    const formData = new FormData(event.currentTarget)

    if (studyPlan.id > 0) {
      formData.append('id', studyPlan.id.toString())
      formData.append('evaluationId', studyPlan.evaluationId.toString())
      formData.append('link', studyPlan.link)
      formData.append('name', studyPlan.name)

      $StudyPlan.update(studyPlan.id, formData).then(() => {
        onSuccess()
        setIsLoading(false)
        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Plano de estudo atualizado com sucesso.',
          icon: 'success',
          didOpen: () => SweetAlert.hideLoading(),
        })
      }).catch((e) => {
        setIsLoading(false)
        SweetAlert.fire({
          title: e.response?.data?.message ?? 'Erro ao atualizar plano de estudo',
          icon: 'error',
        })
      })
    } else
      $StudyPlan.store(formData).then(() => {
        onSuccess()
        setIsLoading(false)
        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Plano de estudo cadastrado com sucesso.',
          icon: 'success',
          didOpen: () => SweetAlert.hideLoading(),
        })
      }).catch((e) => {
        setIsLoading(false)
        SweetAlert.fire({
          title: e.response?.data?.message ?? 'Erro ao cadastrar plano de estudo',
          icon: 'error',
        })
      })
  }

  return (
    <>
      <div className="row align-items-end">
        <Buttons className="col-12 d-flex justify-content-end">
          <button className="btn btn-outline-primary mb-3" onClick={onCancel}>Voltar</button>
        </Buttons>
      </div>
      <div className="card">
        <div className="card-header">
          { studyPlan.id > 0 ? 'Atualizar' : 'Cadastrar'} plano de estudo
        </div>
        <form encType="multipart/form-data" onSubmit={onSubmit}>
          <div className="card-body">
            { studyPlan.id > 0 ? (
              <div className="row pt-2 pb-2 ps-3 mb-3">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="search">Nome:</label>
                    <input
                      ref={inputRef}
                      type="text"
                      name="name"
                      className="form-control"
                      defaultValue={studyPlan.name}
                      onChange={handleInputChange}
                      disabled={isLoading}
                      autoFocus
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <Buttons>
                    <a target="_blank" className="btn btn-sm btn-outline-primary mt-4 me-2" href={studyPlan.link} rel="noreferrer">Visualizar plano</a>
                  </Buttons>
                </div>
              </div>
            ): (
              <div className="row pt-2 pb-2 ps-3 mb-3">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="search">Cliente:</label>
                    {!isLoading && clients.length > 0 ? (
                      <Select
                        type="primary"
                        onChange={(e: any) => setClientId(e?.value ?? 0)}
                        options={clients.map((client: IClient) => ({
                          value: client.id,
                          label: client.id + ' - ' + client.name,
                        }))}
                        isSearchable
                        isClearable
                        placeholder="Selecione um cliente"
                        def
                      />
                    ) : (
                      <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                        <Skeleton count={1} height={36} />
                      </SkeletonTheme>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="search">Prova:</label>
                    {!isLoading && evaluations.length > 0 ? (
                      <Select
                        type="primary"
                        name="evaluationId"
                        onChange={(e: any) => {
                          setEvaluationId(e?.value ?? 0),
                          setStudyPlan({
                            ...studyPlan,
                            evaluationId: e?.value ?? 0,
                          } as IStudyPlan)
                        }}
                        options={evaluations.map((evaluation: IEvaluation) => ({
                          value: evaluation.id,
                          label: evaluation.id + ' - ' + evaluation.name,
                        }))}
                        isSearchable
                        isClearable
                        placeholder="Selecione uma prova"
                      />
                    ) : (
                      <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                        <Skeleton count={1} height={36} />
                      </SkeletonTheme>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="row pt-2 pb-2 ps-3">
              <div className={studyPlan.id > 0 ? 'col-12 col-md-8' : 'col-12'}>
                <div className="input-group">
                  <input
                    ref={inputRef}
                    type="file"
                    name="file"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <Button
              className="btn btn-primary"
              disabled={(studyPlan.id > 0 ? false : clientId <= 0 || evaluationId <= 0 || !file)}
              isLoading={false}
              type="submit"
            >
              { studyPlan.id > 0 ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default Study
