import React, { useContext, useEffect, useMemo, useState } from 'react'
import Steps from '@components/Steps/Steps'
import RegistrationContext from '@contexts/Registration/Registration'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $EntityRegistration from '@services/Users/Registration'
import Selection from '@components/Users/Registration/Selection/Selection'
import Finish from '@components/Users/Registration/Finish/Finish'
import Upload from '@components/Users/Registration/RegistrationTypes/Upload/Upload'
import Manual from '@components/Users/Registration/RegistrationTypes/Manual/Manual'
import CopyPaste from '@components/Users/Registration/RegistrationTypes/CopyPaste/CopyPaste'
import RegistrationStepEnum from '@enums/RegistrationStep.enum'
import RegistrationTypeEnum from '@enums/RegistrationType.enum'
import RoleEnum from '@enums/Role.enum'
import { useNavigate } from 'react-router-dom'

const ManagersRegister: React.FC<any> = () => {
  const { token, registrationType, step, setStep, setRole, setEntityLabels, clearAll } = useContext(RegistrationContext)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const SweetAlert = withReactContent(Swal)
  const navigate = useNavigate()

  useEffect(() => {
    setRole(RoleEnum.Manager)
    setStep(RegistrationStepEnum.Selection)
    setEntityLabels([ 'Gestores', 'Gestor' ])
  }, [setEntityLabels, setRole, setStep])

  const handleStepClick = (step: number) => {
    switch (step) {
    case 1:
      setStep(RegistrationStepEnum.Selection)
      break

    case 2:
      setStep(RegistrationStepEnum.Data)
      break

    case 3:
      setStep(RegistrationStepEnum.Finish)
      break
    }
  }

  const currentStep = useMemo((): number => {
    switch (step) {
    case RegistrationStepEnum.Selection:
      return 1
    case RegistrationStepEnum.Data:
      return 2
    case RegistrationStepEnum.Finish:
      return 3
    }

    return 1
  }, [step])

  const onFinish = () => {
    setIsLoading(true)

    $EntityRegistration.finish(token).then(() => {
      clearAll()

      navigate('/gestores')

      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Gestores cadastrados com sucesso!',
        icon: 'success',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao cadastrar os gestores!',
        icon: 'error'
      })
    }).finally(() => setIsLoading(false))
  }

  return (
    <div className="card mb-4">
      <div className="card-header d-flex justify-content-between align-items-center">
        <div className="mb-0">Cadastro de gestores</div>
        <Steps
          current={currentStep}
          range={[ 1, 3 ]}
          onClick={handleStepClick}
        />
      </div>

      {step === RegistrationStepEnum.Selection && (
        <Selection
          instructions={{
            upload: (
              <>Inscreva os gestores via upload de planilha de Excel. Caso selecionado este modelo, o modelo de planilha e forma de preenchimento serão mostrados na próxima etapa.</>
            ),
            copyPaste: (
              <>Copie e cole os dados dos gestores de um arquivo salvo atentando-se aos dados informados e espaçamentos. Caso selecionado este formato, o modelo de preenchimento será mostrado na etapa a seguir.</>
            ),
            manual: (
              <>Preencha manualmente um formulário na plataforma com as informações dos gestores. Caso selecionado este formato, o modelo de preenchimento será exibido na etapa a seguir.</>
            )
          }}
        />
      )}

      {step === RegistrationStepEnum.Data && registrationType === RegistrationTypeEnum.Upload && (
        <Upload
          instruction={(
            <>
              Preencher a planilha com o apelido único de cada gestor, nome completo do gestor, e o e-mail do gestor. O campo de senha é opcional.
              <br/>
              Atenção: dois professores, gestores ou corretores diferentes não podem possuir o mesmo apelido em uma mesma instituição.
            </>
          )}
        />
      )}

      {step === RegistrationStepEnum.Data && registrationType === RegistrationTypeEnum.Manual && (
        <Manual
          instruction={(
            <>
              Preencher dados com o apelido único de cada gestor, nome completo do gestor, e o e-mail do gestor. O campo de senha é opcional.
              <br/>
              Atenção: dois professores, gestores ou corretores diferentes não podem possuir o mesmo apelido em uma mesma instituição.
            </>
          )}
        />
      )}

      {step === RegistrationStepEnum.Data && registrationType === RegistrationTypeEnum.CopyPaste && (
        <CopyPaste
          instruction={(
            <>
              Preencher uma planilha com o apelido único de cada gestor, nome completo do gestor, e o e-mail do gestor e, em seguida, copiar tabela e colar na área lateral. O campo de senha é opcional.
              <br/>
              Atenção: dois professores, gestores ou corretores diferentes não podem possuir o mesmo apelido em uma mesma instituição.
            </>
          )}
        />
      )}

      {step === RegistrationStepEnum.Finish && (
        <Finish onFinish={onFinish} isLoading={isLoading} />
      )}
    </div>
  )
}

export default ManagersRegister
