import React, { useEffect, useState } from 'react'
import Select from '@components/Select/Select'
import IPlatform from '@interfaces/IPlatform'
import IReaderTemplateAssociation from '@interfaces/IReaderTemplateAssociation'
import IClient from '@interfaces/IClient'
import IAssessment from '@interfaces/IAssessment'
import * as $ReaderTemplate from '@services/ReaderTemplate'
import { random } from '@helpers/Utils'

type Props = {
  platforms: IPlatform[]
  onSave: (association: IReaderTemplateAssociation) => void
  onCancel: () => void
}

const Association: React.FC<any> = ({ platforms, onSave, onCancel }: Props) => {
  const [ platform, setPlatform ] = useState<IPlatform|null>(null)
  const [ client, setClient ] = useState<IClient|null>(null)
  const [ clients, setClients ] = useState<IClient[]>([])
  const [ totalQuestions, setTotalQuestions ] = useState<number|null>(null)
  const [ totalQuestionsList, setTotalQuestionsList ] = useState<number[]>([])
  const [ day, setDay ] = useState<number|null>(null)
  const [ days, setDays ] = useState<number[]>([])
  const [ assessment, setAssessment ] = useState<IAssessment|null>(null)
  const [ assessments, setAssessments ] = useState<IAssessment[]>([])

  useEffect(() => {
    if (platform) {
      $ReaderTemplate.clients(platform.id).then(({ data: clients }) => setClients(clients))
    }
  }, [platform])

  useEffect(() => {
    if (platform && client) {
      $ReaderTemplate.totalQuestions(platform.id, client.id).then(({ data: totalQuestionsList }) => setTotalQuestionsList(totalQuestionsList))
    } else {
      setTotalQuestions(null)
      setTotalQuestionsList([])
    }
  }, [client, platform])

  useEffect(() => {
    if (platform && client && totalQuestions) {
      $ReaderTemplate.days(platform.id, client.id, totalQuestions).then(({ data: days }) => setDays(days))
    } else {
      setDay(null)
      setDays([])
    }
  }, [client, platform, totalQuestions, day])

  useEffect(() => {
    if (platform && client && totalQuestions && day) {
      $ReaderTemplate.assessments(platform.id, client.id, totalQuestions, day).then(({ data: assessments }) => setAssessments(assessments))
    } else {
      setAssessment(null)
      setAssessments([])
    }
  }, [client, platform, totalQuestions, day])

  const handlePlatformChange = ({ value: platformId, label }: any) => setPlatform({ id: platformId, name: label } as IPlatform)

  const handleClientChange = ({ value: clientId, label }: any) => setClient(clientId ? { id: clientId, name: label } as IClient : null)

  const handleTotalQuestionChange = ({ value: totalQuestions }: any) => setTotalQuestions(totalQuestions)

  const handleAssessmentChange = ({ value: assessmentId, label }: any) => setAssessment(assessmentId ? { id: assessmentId, name: label } as IAssessment : null)

  const handleDayChange = ({ value: day }: any) => setDay(day)

  const handleSave = () => {
    if (platform) {
      onSave({
        id: -random(),
        readerTemplateId: -random(),
        platformId: platform?.id ?? null,
        platform,
        clientId: client?.id ?? null,
        client,
        totalQuestions,
        day,
        assessmentId: assessment?.id ?? null,
        assessment,
        createdAt: new Date(),
      })
    }
  }

  return (
    <div className="card-body pb-0 border-bottom">
      <div className="row">
        <div className="col-12 col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="clientId">Plataforma:</label>
            <Select
              type="primary"
              options={platforms?.map((platform: IPlatform) => ({ value: platform.id, label: platform.name }))}
              placeholder="Selecione"
              onChange={handlePlatformChange}
              isDisabled={!platforms.length}
              isSearchable
              required
            />
          </div>
        </div>

        <div className="col-12 col-md-2 mb-3">
          <div className="form-group">
            <label htmlFor="clientId">Cliente:</label>
            <Select
              type="primary"
              options={clients.length ? [
                { value: null, label: 'Todos' },
                ...clients.map((client: IClient) => ({ value: client.id, label: client.name })),
              ] : []}
              placeholder="Selecione"
              onChange={handleClientChange}
              isDisabled={!clients.length || !platform}
              isSearchable
              required
            />
          </div>
        </div>

        <div className="col-12 col-md-2 mb-3">
          <div className="form-group">
            <label htmlFor="clientId">Questões:</label>
            <Select
              type="primary"
              options={totalQuestionsList.length ? [
                { value: null, label: 'Todos' },
                ...totalQuestionsList.map((totalQuestion) => ({ value: totalQuestion, label: totalQuestion })),
              ] : []}
              placeholder="Selecione"
              onChange={handleTotalQuestionChange}
              isDisabled={!totalQuestionsList.length || !client || !platform}
              isSearchable
              required
            />
          </div>
        </div>

        <div className="col-12 col-md-2 mb-3">
          <div className="form-group">
            <label htmlFor="clientId">Dia:</label>
            <Select
              type="primary"
              options={days.length ? [
                { value: null, label: 'Todos' },
                ...days.map(day => ({ value: day, label: day })),
              ] : []}
              placeholder="Selecione"
              onChange={handleDayChange}
              isDisabled={!days.length || !totalQuestions || !client || !platform}
              isSearchable
              required
            />
          </div>
        </div>

        <div className="col-12 col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="clientId">Simulado:</label>
            <Select
              type="primary"
              options={assessments.length ? [
                { value: null, label: 'Todos' },
                ...assessments.map((assessment: IAssessment) => ({ value: assessment.id, label: assessment.name })),
              ] : []}
              placeholder="Selecione"
              onChange={handleAssessmentChange}
              isDisabled={!assessments.length || !day || !totalQuestions || !client || !platform}
              isSearchable
              required
            />
          </div>
        </div>

        <div className="col-12 col-12 mb-3 d-flex gap-10">
          <button className="btn btn-primary" disabled={!platform} onClick={handleSave}>Adicionar</button>
          <button className="btn btn-outline-danger" onClick={onCancel}>Cancelar</button>
        </div>
      </div>
    </div>
  )
}

export default Association
