import styled from 'styled-components'

export const OutlineCard = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px dashed #ccc;
  box-shadow: none;

  > .card-header {
    background-color: transparent;
    border-bottom: 0;
  }
`

export const AccessLevel = styled.div`
  > .card-header {
    > .btn {
      color: #999;
      border-color: #eee;

      &:hover {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
    }
  }
`
