const columns: any[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Nome',
    accessor: 'fantasyName',
  },
]

export default columns
