import React, { useContext, useEffect, useState } from 'react'
import QuestionCategoryContext from '@contexts/QuestionCategory'
import * as $QuestionCategory from '@services/QuestionCategory'
import * as $QuestionCategoryType from '@services/QuestionCategoryType'
import { Spinner } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Select from '@components/Select/Select'

const QuestionCategory: React.FC<any> = () => {
  const { questionCategory, setQuestionCategory, setQuestionCategories, isLoading, setIsLoading, setCanEdit } = useContext(QuestionCategoryContext)

  const [ questionCategoryTypes, setQuestionCategoryTypes ] = useState<any[]>([])

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    $QuestionCategoryType.all().then(({ data: questionCategoryTypes }: any) => setQuestionCategoryTypes(questionCategoryTypes))
  }, [])

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde...',
      html: questionCategory.id > 0 ? 'Atualizando categoria...' : 'Criando categoria...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    if (questionCategory.id > 0) {
      $QuestionCategory.update(questionCategory.id, questionCategory).then(({ data: categories }: any) => {
        setQuestionCategories(categories)
        SweetAlert.close()
        onClose()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao atualizar a categoria!',
        })
      }).finally(() => setIsLoading(false))
    } else {
      $QuestionCategory.create(questionCategory).then(({ data: categories }: any) => {
        setQuestionCategories(categories)
        SweetAlert.close()
        onClose()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao registrar categoria!',
        })
      }).finally(() => setIsLoading(false))
    }
  }

  const onClose = () => {
    setCanEdit(true)
    setIsLoading(false)
    setQuestionCategory(null)
  }

  const handleQuestionCategoryTypeChange = ({ value: questionCategoryTypeId }: any) => setQuestionCategory({
    ...questionCategory,
    questionCategoryTypeId,
  })

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => setQuestionCategory({
    ...questionCategory,
    [name]: value.length > 0 ? value : null,
  })

  if (questionCategory === null) {
    return null
  }

  return (
    <div className="col-12 col-md-3">
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="card-body">
            <div className="form-group mb-3">
              <label htmlFor="name" className="form-label mb-0">Nome</label>
              <input type="text" id="name" name="name" className="form-control" defaultValue={questionCategory?.name} onChange={handleInputChange} required />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="subgroup" className="form-label mb-0">Subgrupo</label>
              <input type="text" id="subgroup" name="subgroup" className="form-control" defaultValue={questionCategory?.subgroup} onChange={handleInputChange} />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="questionCategoryTypeId" className="form-label mb-0">Tipo de Categoria de Questão</label>
              {questionCategoryTypes.length > 0 && (
                <Select
                  name="questionCategoryTypeId"
                  placeholder="Selecione um cliente"
                  options={questionCategoryTypes.map((questionCategoryType: any) => ({ value: questionCategoryType.id, label: questionCategoryType.name }))}
                  onChange={handleQuestionCategoryTypeChange}
                  defaultValue={{ value: questionCategory?.questionCategoryType?.id, label: questionCategory?.questionCategoryType?.name }}
                  isSearchable
                  required
                />
              )}
            </div>

            <div className="form-group">
              <label htmlFor="description" className="form-label mb-0">Descrição</label>
              <textarea name="description" className="form-control p-3" rows={5} id="description" defaultValue={questionCategory?.description} onChange={handleInputChange} maxLength={255}></textarea>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end">
            <button type="button" className="btn btn-outline-secondary pe-3 ps-3 me-2" onClick={onClose} disabled={isLoading}>Fechar</button>
            <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={isLoading}>
              <span>{questionCategory?.id > 0 ? 'Atualizar' : 'Salvar'}</span> {isLoading && <Spinner animation="border" size="sm" variant="light" className="ms-2" />}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default QuestionCategory
