import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '@contexts/Auth'
import IClient from '@interfaces/IClient'
import Select from '@components/Select/Select'
import * as $StatusReleaseDate from '@services/StatusReleaseDate'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const StatusReleaseDate: React.FC<any> = () => {
  const { clients } = useContext(AuthContext)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ clientId, setClientId ] = useState<number>(0)
  const [ statusReleaseDate, setStatusReleaseDate ] = useState<any[]>([] as any[])

  useEffect(() => {
    setStatusReleaseDate([])
    if (clientId > 0)
      $StatusReleaseDate.find(clientId).then(({ data }) => setStatusReleaseDate(data)).finally(() => setIsLoading(false))
  }, [clientId])

  const handleStatusReleaseDate = (statusReleaseDateTypeId: number, type: number) => {
    $StatusReleaseDate.remove(clientId, statusReleaseDateTypeId).then(() => {
      if (type != 0) {
        $StatusReleaseDate.add(clientId, statusReleaseDateTypeId, type).then().finally(() => setIsLoading(false))
      }
    }).finally(() => setIsLoading(false))
  }

  return (
    <>
      <div className="row align-items-end mb-3">
        <div className="col-8 col-md-8">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="search">Cliente:</label>
                {clients.length > 0 && (
                  <Select
                    type="primary"
                    onChange={(e: any) => setClientId(e?.value ?? 0)}
                    options={clients.map((client: IClient) => ({
                      value: client.id,
                      label: client.name,
                    }))}
                    isSearchable
                    placeholder="Selecione um cliente"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Divulgação de gabarito por cliente</div>
        <div className="card-body p-3">
          {!isLoading && statusReleaseDate.length > 0 ? (
            statusReleaseDate.map((status: any) => (
              <div className="row m-2" key={status.statusReleaseDateTypeId}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="col-6">
                    <b>{status.name}</b>
                  </div>
                  <div className="col-6">
                    <div className="btn-group"
                      role={'group' + status.statusReleaseDateTypeId}
                      aria-label="Basic radio toggle button group"
                    >
                      <input type="radio" className="btn-check" name={'btnradio'+ status.statusReleaseDateTypeId}
                        defaultChecked={true}
                        onClick={() => handleStatusReleaseDate(status.statusReleaseDateTypeId, 0)}
                        id={'btnradio1' + status.statusReleaseDateTypeId} autoComplete="off"
                      />
                      <label className="btn btn-sm btn-outline-primary" htmlFor={'btnradio1' + status.statusReleaseDateTypeId}>Sem restrição</label>

                      <input type="radio" className="btn-check" name={'btnradio'+ status.statusReleaseDateTypeId}
                        defaultChecked={status.typeDate == 1 ? true : false}
                        onClick={() => handleStatusReleaseDate(status.statusReleaseDateTypeId, 1)}
                        id={'btnradio2' + status.statusReleaseDateTypeId} autoComplete="off"
                      />
                      <label className="btn btn-sm btn-outline-primary" htmlFor={'btnradio2' + status.statusReleaseDateTypeId}>Data 1</label>

                      <input type="radio" className="btn-check" name={'btnradio'+ status.statusReleaseDateTypeId}
                        defaultChecked={status.typeDate == 2 ? true : false}
                        onClick={() => handleStatusReleaseDate(status.statusReleaseDateTypeId, 2)}
                        id={'btnradio3' + status.statusReleaseDateTypeId} autoComplete="off"
                      />
                      <label className="btn btn-sm btn-outline-primary" htmlFor={'btnradio3' + status.statusReleaseDateTypeId}>Data 2</label>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ): (
            <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
              <Skeleton count={1} height={36} />
            </SkeletonTheme>)}
        </div>
      </div>
    </>
  )
}

export default StatusReleaseDate
