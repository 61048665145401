import React, { useCallback, useContext } from 'react'
import Button from '@components/Button/Button'
import Download from './Download/Download'
import Upload from './Upload/Upload'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as $AdminImport from '@services/AdminImport'
import AdminImportManagerContext from '@contexts/AdminImportManager'
import SearchImport from './SearchImport/SearchImport'

const ImportManagers: React.FC<any> = () => {
  const { refreshToken, adminImportRows, setAdminImportRows, isLoading, setIsLoading,
    fileRef, setFile, adminImportId, setAdminImportId, showSearch, setShowSearch } = useContext(AdminImportManagerContext)

  const SweetAlert = withReactContent(Swal)

  const store = useCallback(() => {
    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde',
      text: 'Validando...',
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading()
    })
    $AdminImport.bulkStore(adminImportId.toString(), adminImportRows).then(() => {
      SweetAlert.fire({
        title: 'Inserção efetuada!',
        text: 'Inserção em massa efetuada com sucesso!',
        icon: 'success',
      }).then(() => {
        setFile(null)
        setAdminImportRows([])
        refreshToken()
        fileRef.current.value = null
        setAdminImportId(0)
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao efetuar a inserção em massa, tente novamente!',
        icon: 'error',
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
      setAdminImportId(0)
    })
  }, [setIsLoading, SweetAlert, adminImportId, adminImportRows, setFile, setAdminImportRows, refreshToken, fileRef, setAdminImportId])

  const deleteAll = useCallback(() => {
    fileRef.current.value = null
    setFile(null)
    setAdminImportRows([])
  }, [fileRef, setAdminImportRows, setFile])

  return (
    <>
      {!showSearch ?
        <div className="card">
          <div className="card-header">
            Importar em massa
          </div>
          <div className="card-body">
            <div className="row pt-5 pb-5">
              <div className="col-12 col-md-8 offset-md-2 text-center">
                <div className="d-flex align-items-center">
                  <Download />
                  <Upload />
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-between">
            <div>
              <button
                className="btn btn-primary"
                onClick={() => setShowSearch(true)}
              >Pesquisar importações</button>
            </div>
            <div>
              <button
                className="btn btn-danger me-2"
                onClick={deleteAll}
                disabled={!adminImportRows?.length}
              >Descartar</button>

              <Button
                className="btn btn-primary"
                disabled={isLoading || !adminImportRows?.length}
                isLoading={isLoading && adminImportRows.length > 0 && adminImportId > 0}
                onClick={store}
              >
                Executar cadastro
              </Button>
            </div>
          </div>
        </div>
        : <SearchImport /> }
    </>
  )
}

export default ImportManagers
