/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const columns: any[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Nome',
    accessor: 'name',
  },
  {
    Header: 'Série',
    accessor: 'degreeName',
  },
  {
    Header: 'Ano',
    accessor: 'year',
  },
]

export default columns
