import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import * as $Utils from '@helpers/Utils'
import * as $Class from '@services/Class'
import * as $Platform from '@services/Platform'
import { AiOutlineDownload } from 'react-icons/ai'
import axios from 'axios'
import IPlatform from '@interfaces/IPlatform'
import IDegree from '@interfaces/IDegree'

const download = () => $Utils.exportXLSX([
  [
    'ID da Escola',
    'Nome',
    'Apelido',
    'E-mail',
    'Senha',
    'Tipo de acesso',
    'RA',
    'ID Série',
    'Turma (Nome)',
    'ID Plataforma',
  ],
], 'Importação em massa', 'Planilha de importacao.xlsx')

const Download: React.FC<any> = () => {
  const [ modalShow, setModalShow ] = useState<boolean>(false)
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ platforms, setPlatforms ] = useState<IPlatform[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    axios.all([
      $Platform.all(),
      $Class.allDegrees()
    ]).then(axios.spread(({ data: platforms }: any, { data: degrees }: any) => {
      setPlatforms(platforms)
      setDegrees(degrees.sort((a: IDegree, b: IDegree) => (a.id > b.id) ? 1 : -1))
    })).finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <button className="btn btn-primary text-nowrap me-3 pe-3 ps-3" onClick={() => setModalShow(true)}>Instruções</button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Instruções</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-bottom pb-0 ms-3">
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              <h5 className="mb-0">Download da planilha modelo</h5>
              <hr/>
              <div className="input-group mb-5">
                <div className="form-control">Baixe o modelo e siga as instruções</div>
                <button className="input-group-text btn-primary" onClick={download}>
                  <span>Baixar modelo</span> <AiOutlineDownload size={22} />
                </button>
              </div>
            </div>
            <div className="col-12  col-md-6">
              <h5 className="mb-0">Instruções de preenchimento</h5>
              <hr/>
              <p>Preencher ID da Escola e tipo de acesso são obrigatórios. RA (Identifier) é opcional. A seguir será disponibilizado a lista de séries, tipos de acesso e plataformas.</p>
            </div>
            <div className="col-12 col-md-6">
              <h5 className="mb-0">Séries</h5>
              <hr/>
              <div className="input-group mb-5">
                <hr/>
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                    </tr>
                  </thead>
                  <tbody>
                    {degrees.map((degree: IDegree) => {
                      return (<tr key={degree.id}>
                        <td>{degree.id}</td>
                        <td>{degree.shortname}</td>
                      </tr>)
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <h5 className="mb-0">Tipos de acesso</h5>
              <hr/>
              <div className="input-group mb-5">
                <hr/>
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Estudante</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Gestor</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Professor</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5 className="mb-0">Plataformas</h5>
              <hr/>
              <div className="input-group mb-5">
                <hr/>
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                    </tr>
                  </thead>
                  <tbody>
                    {platforms.map((platform: IPlatform) => {
                      return (<tr key={platform.id}>
                        <td>{platform.id}</td>
                        <td>{platform.name}</td>
                      </tr>)
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Download
