import React, { useEffect, useState } from 'react'
import IUser from '@interfaces/IUser'
import * as $Revisor from '@services/Users/Revisor'
import { Link, useParams } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const RevisorsEdit: React.FC<any> = () => {
  const { id: revisorId, schoolId }: any = useParams()

  const [ revisor, setRevisor ] = useState<IUser>({} as IUser)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    $Revisor.find(schoolId, revisorId).then(({ data }) => setRevisor(data)).finally(() => setIsLoading(false))
  }, [schoolId, revisorId])

  const handleUserChange = (e: any) => {
    const { name, value } = e.target

    setRevisor({
      ...revisor,
      [name]: value,
    })
  }

  const update = () => {
    setIsLoading(true)

    SweetAlert.showLoading()

    $Revisor.update(schoolId, revisor.id as number, {
      name: revisor.name,
      email: revisor.email,
      nickname: revisor?.nickname,
    } as IUser).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Corretor atualizado com sucesso!',
        icon: 'success'
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao atualizar o corretor!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/corretores" className="btn btn-outline-primary">Voltar</Link>
      </div>

      <div className="card">
        <div className="card-header">
          Editar corretor ({revisor?.nickname})
        </div>

        <div className="card-body pb-0">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="nickname">Apelido</label>
                <input type="text" className="form-control" name="nickname" id="nickname" placeholder="Apelido" defaultValue={revisor?.nickname} onChange={handleUserChange} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="name">Nome</label>
                <input type="text" className="form-control" name="name" id="name" placeholder="Nome" defaultValue={revisor?.name} onChange={handleUserChange} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="email">E-mail</label>
                <input type="email" className="form-control" name="email" id="email" placeholder="E-mail" defaultValue={revisor?.email} onChange={handleUserChange} />
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <button className="btn btn-primary" onClick={update} disabled={isLoading}>Atualizar</button>
        </div>
      </div>
    </>
  )
}

export default RevisorsEdit
