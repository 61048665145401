import React, { useMemo } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import ISchedule from '@interfaces/ISchedule'

const useScheduleColumns = () : Column<ISchedule>[] => {
  return useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Nome',
      accessor: 'name',
    },
    {
      Header: 'Agendamento',
      accessor: 'expression',
      style: {
        width: 160,
      },
    },
    {
      Header: 'Início',
      accessor: 'startAt',
      Cell: ({ row: { original: schedule } }: any) => moment(schedule.startAt).format('DD/MM/YYYY [ás] hh:mm'),
      style: {
        width: 160,
      },
    },
    {
      Header: 'Última execução',
      accessor: 'executedAt',
      Cell: ({ row: { original: schedule } }: any) => schedule.executedAt ? moment(schedule.executedAt).format('DD/MM/YYYY [ás] hh:mm') : 'Nunca executado',
      style: {
        width: 160,
      },
    },
    {
      Header: 'Ações',
      disableSortBy: false,
      Cell: ({ row: { original: schedule } }: any) => (
        <Link to={`/agendamentos/${schedule.id}/editar`} className="btn btn-sm ps-3 pe-3 btn-primary me-2">
          Editar
        </Link>
      ),
      style: {
        width: 160,
        textAlign: 'right',
      },
    },
  ], [])
}

export default useScheduleColumns
