import React, { createContext, useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import IPermission from '@interfaces/IPermission'
import IPermissionProfile from '@interfaces/IPermissionProfile'
import IPermissionProfileAssign from '@interfaces/IPermissionProfileAssign'
import IPermissionProfilePermission from '@interfaces/IPermissionProfilePermission'
import * as $Permission from '@services/Permission'
import * as $PermissionProfile from '@services/PermissionProfile'
import axios from 'axios'

type PermissionProfileContextProps = {
  permissionProfileId: number
  permissions: IPermission[]
  setPermissions: React.Dispatch<React.SetStateAction<IPermission[]>>
  permissionProfile: IPermissionProfile
  setPermissionProfile: React.Dispatch<React.SetStateAction<IPermissionProfile>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const PermissionProfileContext = createContext<PermissionProfileContextProps>({ } as PermissionProfileContextProps)

export const PermissionProfileProvider: React.FC<any> = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ permissions, setPermissions ] = useState<IPermission[]>([])
  const [ permissionProfile, setPermissionProfile ] = useState<IPermissionProfile>({
    permissionProfilePermissions: [] as IPermissionProfilePermission[],
    permissionProfileAssigns: [] as IPermissionProfileAssign[],
  } as IPermissionProfile)

  const { id: permissionProfileId }: any = useParams()

  useEffect(() => {
    const $requests = [
      $Permission.all(),
    ]

    if (permissionProfileId) {
      $requests.push($PermissionProfile.find(permissionProfileId))
    }

    axios.all($requests).then(axios.spread(({ data: permissions }: any, ...responses) => {
      setPermissions(permissions)

      if (responses.length) {
        setPermissionProfile(responses[0].data)
      }
    })).finally(() => setIsLoading(false))
  }, [permissionProfileId, setPermissionProfile, setPermissions])

  return (
    <PermissionProfileContext.Provider
      value={{
        permissionProfileId,
        permissions,
        setPermissions,
        permissionProfile,
        setPermissionProfile,
        isLoading,
        setIsLoading,
      }}
    >
      <Outlet />
    </PermissionProfileContext.Provider>
  )
}

export default PermissionProfileContext
