const columns: any[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'ID Escola',
    accessor: 'schoolId',
  },
  {
    Header: 'Nome',
    accessor: 'name',
  },
  {
    Header: 'Apelido',
    accessor: 'nickName',
  },
  {
    Header: 'E-mail',
    accessor: 'email',
  },
  {
    Header: 'Senha',
    accessor: 'password',
  },
  {
    Header: 'Nível de acesso',
    accessor: 'roleId',
  },
  {
    Header: 'RA',
    accessor: 'identifier',
  },
  {
    Header: 'ID Plataforma',
    accessor: 'platformId',
  },
  {
    Header: 'Data de Migração',
    accessor: 'migratedDate',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    Cell: (props: { value: Date }) => {
      return props.value ? new Date(props.value).toLocaleString() : 'Não Migrado'
    },
  },
]

export default columns
