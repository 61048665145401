import styled from 'styled-components'

export const Error = styled.div`
  > button {
    width: 100%;
    position: relative;
    padding-right: 30px;
    text-align: left;

    > svg {
      position: absolute;
      transform: translateY(-50%);
      right: 8px;
      top: 50%;
    }
  }
`
