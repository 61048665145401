import axios from 'axios'
import IPermission from '@interfaces/IPermission'

export const all = (): Promise<any> => {
  return axios.get('/permissions')
}

export const store = (permission: IPermission): Promise<any> => {
  return axios.post('/permissions', permission)
}

export const update = (permission: IPermission): Promise<any> => {
  return axios.put('permissions/' + permission.id, permission)
}
