import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Admin from '@services/Admin'
import IAdmin from '@interfaces/IAdmin'
import * as $AccessLevel from '@services/AccessLevel'
import axios from 'axios'
import AuthContext from '@contexts/Auth'
import AccessLevelPermissionEnum from '@enums/AccessLevelPermission.enum'
import IAccessLevel from '@interfaces/IAccessLevel'

const AdminsEdit: React.FC<any> = () => {
  const { admin: currAdmin } = useContext(AuthContext)

  const [ admin, setAdmin ] = useState<IAdmin>({
    id: 0,
  } as IAdmin)
  const [ accessLevels, setAccessLevels ] = useState<IAccessLevel[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const { id: adminId }: any = useParams()

  useEffect(() => {
    const $requests = [
      $AccessLevel.all(),
    ]

    if (adminId)
      $requests.push($Admin.find(adminId))

    axios.all($requests).then(axios.spread(({ data: accessLevels }: any, ...responses) => {
      setAccessLevels(accessLevels)

      if (responses.length > 0) {
        setAdmin(responses[0].data)
      }
    })).finally(() => setIsLoading(false))
  }, [adminId])

  const SweetAlert = withReactContent(Swal)

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => setAdmin({
    ...admin,
    [name]: value,
  })

  const handleAccessLevelChange = ({ target: { value: accessLevel } }: React.ChangeEvent<HTMLSelectElement>) => setAdmin({
    ...admin,
    accessLevel: isNaN(parseInt(accessLevel)) ? 0 : parseInt(accessLevel),
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsLoading(true)

    SweetAlert.showLoading()

    if (admin?.id > 0) {
      $Admin.update(adminId, admin).then(() => SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Administrador atualizado com sucesso!',
        icon: 'success'
      })).catch(e => SweetAlert.fire({
        title: 'Erro!',
        text: e.response.data.message,
        icon: 'error'
      })).finally(() => {
        setIsLoading(false)
        SweetAlert.hideLoading()
      })
    } else {
      $Admin.create(admin).then(() => SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Administrador cadastrado com sucesso!',
        icon: 'success'
      })).catch(e => SweetAlert.fire({
        title: 'Erro!',
        text: e.response.data.message,
        icon: 'error'
      })).finally(() => {
        setIsLoading(false)
        SweetAlert.hideLoading()
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/administradores" className="btn btn-outline-primary me-3">Voltar</Link>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
          {adminId ? 'Atualizar' : 'Salvar'}
        </button>
      </div>

      <div className="card mb-4">
        <div className="card-header">
          {admin?.name?.length > 0 ? `Dados do administrador: ${admin.name}` : 'Dados do administrador'}
        </div>

        <div className="card-body pb-0">
          {!adminId || admin?.id > 0 ? (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Nome</label>
                    <input type="text" name="name" className="form-control" id="name" placeholder="Nome" defaultValue={admin?.name} onChange={handleInputChange} required />
                  </div>
                </div>

                <div className={'col-12 ' + (currAdmin.permissions.includes(AccessLevelPermissionEnum.VIEW_ACCESSLEVEL) ? 'col-md-6' : '')}>
                  <div className="form-group mb-3">
                    <label htmlFor="email">E-mail</label>
                    <input type="email" name="email" className="form-control" id="email" placeholder="E-mail" defaultValue={admin?.email} onChange={handleInputChange} required />
                  </div>
                </div>

                {currAdmin.permissions.includes(AccessLevelPermissionEnum.VIEW_ACCESSLEVEL) && (
                  <div className="col-12 col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="accessLevel">Nível</label>
                      {accessLevels.length > 0 && (
                        <select className="form-control" id="accessLevel" name="accessLevel" defaultValue={admin.accessLevel} onChange={handleAccessLevelChange} required>
                          <option value="">Selecione um nível</option>
                          {accessLevels.filter(({ id }) => id !== 0).map((accessLevel: IAccessLevel) => (
                            currAdmin.accessLevel < accessLevel.id || currAdmin.isSuperAdmin == true ?
                              <option value={accessLevel.id} key={accessLevel.id}>Nível {accessLevel.id}</option> : null
                          ))}
                        </select>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          {adminId ? 'Alterar senha' : 'Definir senha'}
        </div>

        <div className="card-body pb-0">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="password">Senha</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  id="password"
                  placeholder="Digite a nova senha"
                  onChange={handleInputChange}
                  autoComplete="new-password"
                  required={!admin?.id}
                />
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="passwordConfirmation">Confirmação de senha</label>
                <input
                  type="password"
                  name="passwordConfirmation"
                  className="form-control"
                  id="passwordConfirmation"
                  placeholder="Confirme a nova senha"
                  onChange={handleInputChange}
                  autoComplete="new-password"
                  required={!admin?.id || admin?.password?.length > 0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AdminsEdit
