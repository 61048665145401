import IAccessLevel from '@interfaces/IAccessLevel'
import IAccessLevelPermission from '@interfaces/IAccessLevelPermission'
import axios from 'axios'

export const all = (): Promise<any> => {
  return axios.get('/access-levels')
}

export const create = (accessLevel: IAccessLevel): Promise<any> => {
  return axios.post('/access-levels', accessLevel)
}

export const createPermission = (accessLevelId: number, permission: IAccessLevelPermission): Promise<any> => {
  return axios.post(`/access-levels/${accessLevelId}/permissions`, permission)
}

export const updatePermission = (accessLevelId: number, permissionId: number, newAccessLevelId: number): Promise<any> => {
  return axios.put(`/access-levels/${accessLevelId}/permissions/${permissionId}`, {
    accessLevelId: newAccessLevelId,
  })
}
