import axios from 'axios'

export const find = (clientId: number): Promise<any> => {
  return axios.get(`status-release/${clientId}`)
}

export const remove = (clientId: number, statusReleaseDateTypeId: number): Promise<any> => {
  return axios.delete(`status-release/${clientId}/${statusReleaseDateTypeId}/delete`)
}

export const add = (clientId: number, statusReleaseDateTypeId: number, type: number): Promise<any> => {
  return axios.post(`status-release/${clientId}/${statusReleaseDateTypeId}/${type}`)
}