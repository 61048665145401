import React, { useContext, useEffect } from 'react'
import ReportmentContext from '@contexts/Reportment'
import * as $Filter from '@services/Filter'
import IFilter from '@interfaces/IFilter'

const Filters: React.FC<any> = () => {
  const { reportment, setReportment } = useContext(ReportmentContext)

  const [ filters, setFilters ] = React.useState<any[]>([])

  const selectRef = React.useRef<HTMLSelectElement>(null)

  useEffect(() => {
    $Filter.all().then(({ data }) => setFilters(data))
  }, [])

  const addFilter = () => {
    if (selectRef.current === null)
      return

    const filterId = parseInt(selectRef.current.value)

    if (filterId === 0)
      return

    const filter = filters.find(filter => filter.id === filterId)

    if (!filter)
      return

    if (!reportment)
      return

    if (!reportment.filters)
      reportment.filters = []

    if (reportment.filters.find((filter: any) => filter.id === filterId))
      return

    reportment.filters.push(filter)

    setReportment({ ...reportment })

    selectRef.current.value = ''
  }

  const deleteFilter = (filterId: number) => {
    if (!reportment)
      return

    if (!reportment.filters)
      return

    const index = reportment.filters.findIndex((filter: any) => filter.id === filterId)

    if (index === -1)
      return

    reportment.filters.splice(index, 1)

    setReportment({ ...reportment })
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        <strong>Filtros</strong>
      </div>

      <div className="card-body p-0">
        {reportment.filters && reportment.filters.length > 0 ? (
          <table className="table table-default mb-0">
            <thead>
              <tr>
                <td width="60">ID</td>
                <td>Nome</td>
                <td>Tag</td>
                <td>Valor padrão</td>
                <td>Query</td>
                <td>Ações</td>
              </tr>
            </thead>

            <tbody>
              {reportment.filters.map((filter: IFilter) => (
                <tr key={filter.id}>
                  <td>{filter.id}</td>
                  <td>{filter.name}</td>
                  <td>{filter.tag}</td>
                  <td>{filter.defaultValue == null ? 'NULL' : (filter.defaultValue.length > 0 ? filter.defaultValue : 0)}</td>
                  <td>
                    <div className="text-elipsis">
                      {filter.query}
                    </div>
                  </td>
                  <td align="right">
                    <button type="button" className="btn btn-sm btn-outline-danger btn-sm" onClick={() => deleteFilter(filter.id)}>Excluir</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center text-muted p-3">Nenhum filtro adicionado.</div>
        )}
      </div>

      <div className="card-footer d-flex justify-content-end">
        <select ref={selectRef} className="form-control form-control-sm me-3">
          <option value="">Selecione um filtro</option>
          {filters.map((filter: any) => (
            <option key={filter.id} value={filter.id}>#{filter.id}: {filter.name}</option>
          ))}
        </select>

        <button type="button" className="btn btn-primary" onClick={addFilter}>Adicionar filtro</button>
      </div>
    </div>
  )
}

export default Filters
