import axios from 'axios'
import IUser from '@interfaces/IUser'

export const all = (schoolId: number): Promise<any> => {
  return axios.get(`users/managers/${schoolId}`)
}

export const find = (schoolId: number, managerId: number): Promise<any> => {
  return axios.get(`users/managers/${schoolId}/${managerId}`)
}

export const update = (schoolId: number, managerId: number, manager: IUser): Promise<any> => {
  return axios.put(`users/managers/${schoolId}/${managerId}`, manager)
}

export const platforms = (schoolId: number, managerId: number): Promise<any> => {
  return axios.get(`users/managers/${schoolId}/${managerId}/platforms-association`)
}

export const updatePlatforms = (schoolId: number, managerId: number, platformIds: number[]): Promise<any> => {
  return axios.put(`users/managers/${schoolId}/${managerId}/platforms-association`, platformIds)
}
