import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react'
import IUser from '@interfaces/IUser'
import * as $Revisor from '@services/Users/Revisor'
import * as Utils from '@helpers/Utils'
import AuthContext from '@contexts/Auth'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableAcademicColumns'
import { Spinner } from 'react-bootstrap'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import Password from '@components/Users/Password/Password'
import Delete from './Delete/Delete'
import axios from 'axios'
import * as $School from '@services/School'
import useUpdateEffect from '@hooks/useUpdateEffect'
import Select from '@components/Select/Select'
import ISchool from '@interfaces/ISchool'
import { Buttons } from './Revisors.styles'

const Revisors: React.FC<any> = () => {
  const { client } = useContext(AuthContext)

  const [ revisors, setRevisors ] = useState<IUser[]>([] as IUser[])
  const [ schools, setSchools ] = useState<any[]>([])
  const [ isLoading, setIsLoading ] = useState(true)
  const [ search, setSearch ] = useState<string>('')
  const [ schoolId, setSchoolId ] = useState<number>(0)

  const navigate = useNavigate()

  useEffect(() => {
    if (client?.id) {
      setSchoolId(0)
      setRevisors([])
      setIsLoading(true)

      axios.all([
        $School.all(),
      ]).then(axios.spread(({ data: schools }: any) => {
        setSchools(schools)
      })).finally(() => setIsLoading(false))
    }
  }, [client])

  useUpdateEffect(() => {
    if (schoolId) {
      setIsLoading(true)
      $Revisor.all(schoolId).then(({ data: revisors }: any) => setRevisors(revisors)).finally(() => setIsLoading(false))
    }
  }, [schoolId])

  const data = useMemo(() => {
    return revisors.filter((revisor: IUser) => {
      let exists = true

      if (search.length > 0) {
        exists = revisor.name.toLowerCase().includes(search.toLowerCase()) ||
          revisor.nickname.toLowerCase().includes(search.toLowerCase()) ||
          revisor.email === search
      }

      return exists
    })
  }, [revisors, search])

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const exportRevisors = () => {
    const data = revisors.map((revisor: IUser) => [
      revisor.nickname,
      revisor.name,
      revisor.email,
      revisor.password && revisor.password?.length > 20 ? 'Senha alterada' : revisor.password,
    ])

    data.unshift([
      'Apelido', 'Nome', 'Email', 'Senha',
    ])

    Utils.exportXLSX(data as string[][], 'Corretores', 'Corretores.xlsx')
  }

  const handleOnDelete = (revisorId: number) => {
    setRevisors(revisors => revisors.filter(revisor => revisor.id !== revisorId))
  }

  const handleSchoolIdChange = ({ value: schoolId }: any) => setSchoolId(schoolId)

  const options = useMemo(() => schools.map((school: ISchool) => ({
    value: school.id,
    label: school.name,
  })), [schools])

  const SelectComponent = useCallback(() => (
    <Select
      type="primary"
      onChange={handleSchoolIdChange}
      defaultValue={options.find(option => option.value === 297658)}
      options={options}
      isSearchable={true}
      placeholder="Selecione uma escola"
    />
  ), [options])

  return (
    <>
      <div className="row align-items-end">
        <div className="col-12 col-md-6">
          <div className="row">
            {options.length > 0 && (
              <div className="col-12 col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="search">Escola:</label>
                  <div className="select-schools">
                    <SelectComponent />
                  </div>
                </div>
              </div>
            )}

            <div className="col-12 col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="search">Pesquisar:</label>
                <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo nome ou e-mail" onChange={e => setSearch(e.target.value)} />
              </div>
            </div>
          </div>
        </div>

        <Buttons className="col-12 col-md-6 mb-3 d-flex justify-content-end align-items-end">
          <button className="btn btn-outline-primary me-2" onClick={exportRevisors}>Exportar</button>
          <button className="btn btn-primary" onClick={() => navigate('/corretores/cadastrar')}>Cadastrar</button>
        </Buttons>
      </div>

      <div className="card">
        <div className="card-header">Corretores ({revisors.length})</div>

        <div className="card-body p-0">
          {!isLoading ? (revisors.length ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                      <th style={{ width: 120 }}>Senha</th>
                      <th style={{ width: 120 }}>Ações</th>
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row: any, rowIndex: number) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                        <td style={{ verticalAlign: 'middle' }}>
                          <Password userId={row.original.id} password={row.original.password} />
                        </td>

                        <td style={{ verticalAlign: 'middle' }}>
                          <div className="d-flex justify-content-end">
                            <Link to={`/corretores/${row.original.schoolId}/${row.original.id}/editar`} className="btn btn-sm btn-primary">Editar</Link>
                            <Delete revisor={row.original} onDelete={handleOnDelete} />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              {schoolId > 0 ? 'Nenhum corretor encontrado.' : 'Selecione uma escola para que sejam exibidos os resultados.'}
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Revisors
