const columns: any[] = [
  {
    Header: 'Nome',
    accessor: 'nickname',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'RA',
    accessor: 'identifier',
  },
  {
    Header: 'RE',
    accessor: 'usercode',
  },
  {
    Header: 'Série',
    accessor: 'degreeName',
  },
  {
    Header: 'Turma',
    accessor: 'className',
  },
]

export default columns
