import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import IClient from '@interfaces/IClient'
import * as $Client from '@services/Client'
import { Spinner } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $PermissionProfiles from '@services/PermissionProfile'

const ClientsEdit: React.FC<any> = () => {
  const { id: clientId }: any = useParams()

  const [ client, setClient ] = useState<IClient>({} as IClient)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ permissions, setPermissions ] = useState<[]>([])

  useEffect(() => {
    $Client.find(clientId).then(({ data }: any) => setClient(data)).finally(() => setIsLoading(false))
    $PermissionProfiles.all().then(({ data }: any) => setPermissions(data))
  }, [clientId])

  const SweetAlert = withReactContent(Swal)

  const handleInputChange = (e: any) => {
    const { name, value } = e.target

    setClient({
      ...client,
      [name]: value,
    })
  }

  const handleCanEditChange = (e: any, client: IClient) => {
    const { value: canEdit } = e.target

    setClient({
      ...client,
      canEdit: canEdit ? true : false,
    })
  }

  const handleActiveChange = (e: any, client: IClient) => {
    const { value: active } = e.target

    setClient({
      ...client,
      active: active ? true : false,
    })
  }

  const handlePermissionChange = (e: any, client: IClient) => {
    const { value: permissionId } = e.target

    setClient({
      ...client,
      permissionProfileId: permissionId,
    })
  }

  const handleUpdate = () => {
    setIsLoading(true)

    SweetAlert.showLoading()

    $Client.update(client.id as number, {
      name: client.name,
      primaryColor: client.primaryColor,
      secondaryColor: client.secondaryColor,
      logotipoUrl: client.logotipoUrl,
      logotipoMinUrl: client.logotipoMinUrl,
      domain: client.domain,
      active: client.active,
      canEdit: client.canEdit,
      permissionProfileId: client.permissionProfileId
    }).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Cliente atualizado com sucesso!',
        icon: 'success'
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao atualizar o cliente!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/clientes" className="btn btn-outline-primary">Voltar</Link>
      </div>

      <div className="card">
        <div className="card-header">
          Editar cliente ({client?.name})
        </div>
        {!isLoading ? (
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="name">Nome</label>
                  <input type="text" name="name" className="form-control" id="name" placeholder="Nome" defaultValue={client?.name} onChange={handleInputChange} />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="primaryColor">Cor primária</label>
                  <input type="color" name="primaryColor" className="form-control" id="primaryColor" placeholder="Cor primária" defaultValue={client?.primaryColor} onChange={handleInputChange} />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="secondaryColor">Cor secundária</label>
                  <input type="color" name="secondaryColor" className="form-control" id="secondaryColor" placeholder="Cor secundária" defaultValue={client?.secondaryColor} onChange={handleInputChange} />
                </div>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="logotipoUrl">Logotipo</label>
                  <input type="text" name="logotipoUrl" className="form-control" id="logotipoUrl" defaultValue={client?.logotipoUrl} onChange={handleInputChange} />
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="logotipoMinUrl">Logotipo Min</label>
                  <input type="text" name="logotipoMinUrl" className="form-control" id="logotipoMinUrl" defaultValue={client?.logotipoMinUrl} onChange={handleInputChange} />
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="domain">Domínio</label>
                  <input type="text" name="domain" className="form-control" id="domain" defaultValue={client?.domain} onChange={handleInputChange} />
                </div>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="active">Ativo</label>
                  <select className="form-control" defaultValue={client?.active ? 1 : 0} onChange={(e: any) => handleActiveChange(e, client)}>
                    <option value="0">Não</option>
                    <option value="1">Sim</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="canEdit">Editável</label>
                  <select className="form-control" defaultValue={client?.canEdit ? 1 : 0} onChange={(e: any) => handleCanEditChange(e, client)}>
                    <option value="0">Não</option>
                    <option value="1">Sim</option>
                  </select>
                </div>
              </div>
              { permissions.length ? (
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <label htmlFor="permissionProfileId">Permissão</label>
                    <select className="form-control" defaultValue={client?.permissionProfileId} onChange={(e: any) => handlePermissionChange(e, client)}>
                      {permissions.map((item: any) => {
                        return <option key={item.id} value={item.id}>{item.name}</option>
                      })}
                    </select>
                  </div>
                </div>
              ) : null }
            </div>
          </div>
        ) : (
          <div className="card-body">
            <div className="d-flex justify-content-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        )}
        <div className="card-footer d-flex justify-content-end">
          <button className="btn btn-primary" onClick={handleUpdate} disabled={isLoading}>Atualizar</button>
        </div>
      </div>
    </>
  )
}

export default ClientsEdit
