import styled from 'styled-components'

export const OutlineBox = styled.div`
  display: flex;
  border: 1px solid #c4cbdd;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 12px;

  > .item {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    > h4 {
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 4px;
    }
  }
`
