import React, { useContext } from 'react'
import FilterContext from '@contexts/Filter'
import * as $Filter from '@services/Filter'
import { Spinner } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const Filter: React.FC<any> = () => {
  const { filter, setFilter, setFilters, isLoading, setIsLoading, setCanEdit } = useContext(FilterContext)

  const SweetAlert = withReactContent(Swal)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsLoading(true)

    const formData = new FormData(event.currentTarget)

    formData.set('multiSelect', formData.get('multiSelect') === '1' ? 'true' : 'false')

    SweetAlert.fire({
      title: 'Aguarde...',
      html: filter.id > 0 ? 'Atualizando filtro...' : 'Criando filtro...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    if (filter.id > 0) {
      $Filter.update(filter.id, formData).then(({ data: categories }: any) => {
        setFilters(categories)
        SweetAlert.close()
        onClose()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao atualizar a filtro!',
        })
      }).finally(() => setIsLoading(false))
    } else {
      $Filter.create(formData).then(({ data: categories }: any) => {
        setFilters(categories)
        SweetAlert.close()
        onClose()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao registrar filtro!',
        })
      }).finally(() => setIsLoading(false))
    }
  }

  const onClose = () => {
    setCanEdit(true)
    setIsLoading(false)
    setFilter(null)
  }

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => setFilter({
    ...filter,
    [name]: value.length > 0 ? value : null,
  })

  if (filter === null) {
    return null
  }

  return (
    <div className="col-12 col-md-3">
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="card-body">
            <div className="form-group mb-3">
              <label htmlFor="name" className="form-label mb-0">Nome</label>
              <input type="text" id="name" name="name" className="form-control" defaultValue={filter?.name} onChange={handleInputChange} required />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="tag" className="form-label mb-0">Tag</label>
              <input type="text" id="tag" name="tag" className="form-control" defaultValue={filter?.tag} onChange={handleInputChange} required />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="multiSelect" className="form-label mb-0">Seleção Múltipla</label>
              <select name="multiSelect" id="multiSelect" className="form-control" defaultValue={filter?.multiSelect ? 1 : 0} onChange={handleInputChange}>
                <option value="1">Sim</option>
                <option value="0">Não</option>
              </select>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="defaultValue" className="form-label mb-0">Valor Padrão</label>
              <input type="text" id="defaultValue" name="defaultValue" className="form-control" defaultValue={filter?.defaultValue} onChange={handleInputChange} />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="query" className="form-label mb-0">Query</label>
              <textarea name="query" className="form-control p-3" rows={5} id="query" defaultValue={filter?.query} onChange={handleInputChange} maxLength={2000}></textarea>
            </div>

            <div className="form-group">
              <label htmlFor="description" className="form-label mb-0">Descrição</label>
              <textarea name="description" className="form-control p-3" rows={5} id="description" defaultValue={filter?.description} onChange={handleInputChange} maxLength={255}></textarea>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end">
            <button type="button" className="btn btn-outline-secondary pe-3 ps-3 me-2" onClick={onClose} disabled={isLoading}>Fechar</button>
            <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={isLoading}>
              <span>{filter?.id > 0 ? 'Atualizar' : 'Salvar'}</span> {isLoading && <Spinner animation="border" size="sm" variant="light" className="ms-2" />}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Filter
