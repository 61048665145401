import React, { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import Icon from '@components/Icon/Icon'
import { Button, MenuItemChildren, MenuItemContainer } from './MenuItem.styles'

type Props = {
  title: string
  icon?: React.ReactNode
  path: string|string[]
  children?: React.ReactNode
}

const MenuItem: React.FC<Props> = ({ title, icon, path, children }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleMenuClick = useCallback(() => navigate(typeof path === 'string' ? path : path[0]), [navigate, path])

  const activeMenuPath = useMemo(() => {
    const parts = location.pathname.split('/').map(part => {
      if (Number(part)) return ''
      return part
    }).filter(part => part !== '')

    if (parts.length === 0)
      return '/'

    return `/${parts.join('/')}`
  }, [location])

  const isActive = activeMenuPath === path || (Array.isArray(path) && path.includes(activeMenuPath))

  return (
    <MenuItemContainer className="menu-item">
      <Button className={(isActive ? ' is-active' : '')} onClick={handleMenuClick}>
        <div className="btn-inner">
          {icon && (
            <div className="icon">
              <Icon name={icon} />
            </div>
          )}
          <div className="title">{title}</div>
        </div>
      </Button>

      {isActive && children && (
        <MenuItemChildren className="menu-item-children">{children}</MenuItemChildren>
      )}
    </MenuItemContainer>
  )
}

export default MenuItem
