import axios from 'axios'

export const all = (): Promise<any> => {
  return axios.get('/possible-errors/')
}

export const update = (answerCardAuditId: number, answers: string): Promise<any> => {
  return axios.put('/possible-errors/' + answerCardAuditId, answers)
}

export const remove = (answerCardAuditId: number): Promise<any> => {
  return axios.delete(`/possible-errors/${answerCardAuditId}`)
}