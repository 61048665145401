const columns: any[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Prova',
    accessor: 'evaluationName',
  },
  {
    Header: 'Escola',
    accessor: 'schoolName',
  },
  {
    Header: 'Cód. Barras',
    accessor: 'barCode',
  },
  {
    Header: 'Respostas',
    accessor: 'answers',
  },
]

export default columns
