import React, { useState, useEffect, useMemo, useRef, useCallback, useContext } from 'react'
import * as $Tools from '@services/Tools'
import { Modal, Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableAssociationPoliedroColumns'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import Pagination from '@components/Pagination/Pagination'
import { Link } from 'react-router-dom'
import { Buttons } from '@screens/Permissions/Permissions.styles'
import * as $Utils from '@helpers/Utils'
import { generateToken } from '@helpers/Utils'
import Button from '@components/Button/Button'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as XLSX from 'xlsx'
import IAutomaticAssociation from '@interfaces/IAutomaticAssociation'
import { random } from '@helpers/Utils'
import AuthContext from '@contexts/Auth'
import moment from 'moment'

const intValue = (value: string) => {
  if (isNaN(parseInt(value))) return 0
  return parseInt(value)
}

const stringValue = (value: string) => {
  if (value?.toString().trim().length === 0) return ''
  return value?.toString() ?? ''
}

// const dateValue = (value: string) => {
//   if (isNaN(parseInt(value))) return ''
//   return value?.toString() ? new Date((parseInt(value) - (25567 + 1)) * 86400 * 1000) : ''
// }

const convertDate = (value: string) => {
  const [date, time] = value.split(' ')
  const [day, month, year] = date.split('/')
  const formattedDate = `${year}-${month}-${day} ${time}`
  return formattedDate
}

const AssociationPoliedro: React.FC<any> = () => {
  const { admin } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [associations, setAssociations] = useState<any[]>([])
  const [search, setSearch] = useState<string>('')
  const [modal, setModal] = useState<boolean>(false)
  const [associationAutomatic, setAssociationAutomatic] = useState<IAutomaticAssociation[]>([])

  const [token] = useState<string>(generateToken(32))
  const [file, setFile] = useState<File | null>(null)
  const fileRef = useRef<any>()
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false)

  const SweetAlert = withReactContent(Swal)

  const getAssociations = () => $Tools.associationAutomatic(0).then(({ data }: any) => setAssociations(data)).finally(() => setIsLoading(false))

  useEffect(() => {
    getAssociations()
  }, [])

  const data = useMemo(() => associations.filter((association: any) => {
    let exists = true

    if (search.length > 0)
      exists = association.assessmentId?.toString().toLowerCase().includes(search.toLowerCase()) ||
        association.assessmentName?.toString().toLowerCase().includes(search.toLowerCase()) ||
        association.schoolId?.toString().toLowerCase().includes(search.toLowerCase()) ||
        association.schoolName?.toString().toLowerCase().includes(search.toLowerCase()) ||
        association.description?.toString().toLowerCase().includes(search.toLowerCase()) ||
        association.degreeName?.toString().toLowerCase().includes(search.toLowerCase()) ||
        association.clientId?.toString().toLowerCase().includes(search.toLowerCase())

    return exists
  }), [associations, search])

  const newAssociation = () => {
    setFile(null)
    setAssociationAutomatic([])
    setModal(true)
  }

  const upload = useCallback(() => {
    if (!file)
      return

    setIsLoadingModal(true)

    const error = (text: string, index?: number) => {
      setIsLoading(false)

      SweetAlert.fire({
        title: 'Erro!',
        text: index ? `${text}, na linha ${index + 1}` : text,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }

    const reader = new FileReader()

    reader.onload = (e: any) => {
      const workbook = XLSX.read(e.target.result, {
        type: 'binary'
      })

      const first_sheet_name = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[first_sheet_name]

      const rows = XLSX.utils.sheet_to_json(worksheet, {
        header: 1
      }) as string[][]

      rows.shift()

      if (!rows.length) {
        error('O arquivo não contém dados válidos!')
        return
      }

      const associationAutomaticRow: IAutomaticAssociation[] = []

      if (rows[0].length < 6) {
        error('O arquivo não contém todas as colunas necessárias!')
        return
      }

      for (let i = 0; i < rows.length; i++) {
        if (rows[i].length > 0) {
          // console.log(rows[i][7] != undefined ? convertDate(stringValue(rows[i][7])) : '')
          const schoolId = intValue(rows[i][0])
          const assessmentId = intValue(rows[i][1])
          const description = rows[i][2] != undefined ? stringValue(rows[i][2]) : ''
          const degreeId = intValue(rows[i][3])
          const clientId = intValue(rows[i][4])
          const networkId = intValue(rows[i][5])
          const startDate = rows[i][6] != undefined ? convertDate(stringValue(rows[i][6])) : ''
          const endDate = rows[i][7] != undefined ? convertDate(stringValue(rows[i][7])) : ''
          const optInclude = intValue(rows[i][8])

          associationAutomaticRow.push({
            id: -random(),
            schoolId: schoolId,
            assessmentId: assessmentId,
            description: description,
            degreeId: degreeId,
            clientId: clientId,
            networkId: networkId,
            start: new Date(moment(new Date(startDate)).subtract(3, 'hours').format('MM/DD/YYYY HH:mm:ss')),
            end: new Date(moment(new Date(endDate)).subtract(3, 'hours').format('MM/DD/YYYY HH:mm:ss')),
            include: optInclude,
            adminId: admin.id
          })
        }
      }
      pushAssociations(associationAutomaticRow).finally(() => setIsLoadingModal(false))
    }

    reader.readAsBinaryString(file)
  }, [SweetAlert, admin.id, file])

  const pushAssociations = (associations: IAutomaticAssociation[]): Promise<void> => new Promise((resolve) => {
    setAssociationAutomatic(associations)
    setTimeout(() => resolve(), 1000)
  })

  const store = useCallback(() => {
    setIsLoading(true)
    setModal(false)

    SweetAlert.fire({
      title: 'Aguarde',
      text: 'Associando...',
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading()
    })

    $Tools.automaticAssociationStore(token, associationAutomatic).then(() => {
      SweetAlert.fire({
        title: 'Associação efetuada!',
        text: 'Associação efetuada com sucesso!',
        icon: 'success',
      }).then(() => {
        location.reload()
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao efetuar a associação, tente novamente!',
        icon: 'error',
      })
    }).finally(() => {
      setIsLoading(true)
      SweetAlert.hideLoading()
      getAssociations()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associationAutomatic])

  const exportAssociation = () => {
    const excel = associations.map((association: any) => ([
      association.schoolId != null && association.schoolId != 0 ? association.schoolId : null,
      association.assessmentId != null && association.assessmentId != 0 ? association.assessmentId : null,
      association.description != null ? association.description : null,
      association.degreeId != null && association.degreeId != 0 ? association.degreeId : null,
      association.clientId,
      association.networkId != null && association.networkId != 0 ? association.networkId : null,
      association.start ? moment(association.start).format('DD/MM/YYYY HH:mm') : null,
      association.end ? moment(association.end).format('DD/MM/YYYY HH:mm') : null,
      association.include ? 1 : 0
    ] as string[]))

    excel.unshift([
      'ID Escola',
      'ID Simulado',
      'Descrição',
      'ID Série',
      'ID Cliente',
      'ID Netword',
      'Data Início',
      'Data Fim',
      'Incluir(1)/Excluir(0)',
    ])

    $Utils.exportXLSX(excel, 'Lista Associação', 'Lista_Associacao.xlsx')
  }

  const download = () => $Utils.exportXLSX([
    [
      'ID Escola',
      'ID Simulado',
      'Descrição',
      'ID Série',
      'ID Cliente',
      'ID Netword',
      'Data Início',
      'Data Fim',
      'Incluir(1)/Excluir(0)',
    ],
  ], 'Associacao_Automatica', 'Associacao_Automatica.xlsx')

  const {
    getTableProps,
    // getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  return (
    <>
      <div className="row align-items-end">
        <div className="col-12 col-md-7 col-lg-7 mb-3">
          <div className="form-group">
            <label htmlFor="search">Pesquisar:</label>
            <input type="text" id="search" name="search" className="form-control"
              placeholder="Pesquise pelo ID, simulado ou escola"
              onChange={e => setSearch(e.target.value)}
              disabled={!associations.length || isLoading}
            />
          </div>
        </div>
        <Buttons className="col-12 col-lg-5 mb-3 d-flex justify-content-end">
          <button className="btn btn-outline-primary me-2" onClick={exportAssociation}>Exportar</button>
          <Link to="/ferramentas" className="btn btn-outline-primary me-2">Voltar</Link>
          <button className="btn btn-primary" onClick={newAssociation}>Nova associação</button>
        </Buttons>
      </div>
      <div className="card">
        <div className="card-header">Associações</div>
        <div className="card-body p-3">
          {!isLoading ? (data.length > 0 ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row: any, rowIndex: number) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                        <td style={{ verticalAlign: 'middle' }}>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Nenhum registro encontrado.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {!isLoading && data.length > 0 && pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end align-items-center">
            {pageCount > 1 && (
              <Pagination
                page={pageIndex}
                pageCount={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                onClick={(page: number) => gotoPage(page)}
              />
            )}
          </div>
        )}
      </div>
      <Modal
        backdrop="static"
        show={modal}
        onHide={() => setModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Associação automática</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isLoading ? (
            <>
              <div className="d-flex flex-column text-center">
                <div className="input-group">
                  <input
                    type="file"
                    id="file-input"
                    ref={fileRef}
                    className="form-control"
                    onChange={e => e.target.files?.length && setFile(e.target.files[0])}
                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                  <Button
                    className="btn btn-primary pe-3 ps-3"
                    loadingText="Aguarde..."
                    isLoading={isLoadingModal}
                    disabled={isLoadingModal || !file || associationAutomatic.length > 0}
                    onClick={upload}
                  >Carregar planilha</Button>
                </div>
                <label className="mt-2" style={{ color: 'red' }}>Ao inserir o excel verifique possibilidade de erro ao não inserir a data corretamente. <br /> Coloque data e hora e o tipo da coluna como texto.</label>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 d-flex justify-content-between">
            <button className="btn btn-outline-primary" onClick={download}>Baixar modelo</button>
            <Button
              className="btn btn-primary"
              disabled={isLoadingModal || !associationAutomatic?.length}
              isLoading={isLoadingModal}
              onClick={store}
            >
              Associar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AssociationPoliedro
