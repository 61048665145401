import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import ReportmentContext from '@contexts/Reportment'

const Buttons: React.FC<any> = () => {
  const { reportment, isLoading } = useContext(ReportmentContext)

  return (
    <div className="d-flex justify-content-end mb-3">
      <Link to="/relatorios" className="btn btn-outline-primary me-3">Voltar</Link>

      {!reportment?.id ? (
        <button type="submit" className="btn btn-primary" disabled={isLoading}>Salvar</button>
      ) : (
        <button type="submit" className="btn btn-primary" disabled={isLoading}>Atualizar</button>
      )}
    </div>
  )
}

export default Buttons
