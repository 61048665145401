const columns: any[] = [
  {
    Header: 'ID do Cliente',
    accessor: 'clientId',
  },
  {
    Header: 'Cliente',
    accessor: 'clientName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
]

export default columns
