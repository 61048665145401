import axios from 'axios'

export const all = (): Promise<any> => {
  return axios.get('/reportments/categories')
}

export const create = (category: FormData): Promise<any> => {
  return axios.post('/reportments/categories', category, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const update = (categoryId: number, category: FormData): Promise<any> => {
  return axios.put(`/reportments/categories/${categoryId}`, category, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const destroy = (id: number): Promise<any> => {
  return axios.delete(`/reportments/categories/${id}`)
}
