/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const columns: any[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Finalizado',
    accessor: 'finalized',
    // Cell: (props: { value: boolean }) => {
    //   return props.value ? 'Sim' : 'Não'
    // },
  },
  {
    Header: 'Data criação',
    accessor: 'date',
    // Cell: (props: { value: Date }) => {
    //   return props.value ? new Date(props.value).toLocaleString() : 'Não possui'
    // },
  },
  {
    Header: 'Data finalização',
    accessor: 'finalizedDate',
  },
  {
    Header: 'Data primeiro upload',
    accessor: 'firstUploadDate',
  },
  {
    Header: 'Data último upload',
    accessor: 'lastUploadDate',
  },
  {
    Header: 'ID escola',
    accessor: 'schoolId',
  },
  {
    Header: 'Nome escola',
    accessor: 'schoolName',
  },
  {
    Header: 'ID simulado',
    accessor: 'assessmentId',
  },
  {
    Header: 'Nome simulado',
    accessor: 'assessmentName',
  },
  {
    Header: 'ID prova',
    accessor: 'evaluationId',
  },
  {
    Header: 'Nome prova',
    accessor: 'evaluationName',
  },
]

export default columns
