import React, { createContext, useRef, useState } from 'react'
import { generateToken } from '@helpers/Utils'
import { Outlet } from 'react-router-dom'
import IAdminImportManagerRow from '@interfaces/IAdminImportManagerRow'
import IAdminImportManager from '@interfaces/IAdminImportManager'
import * as $AdminImport from '@services/AdminImport'

interface IAdminImportManagerContextContextProps {
  token: string
  adminImportRows: IAdminImportManagerRow[]
  setAdminImportRows: React.Dispatch<React.SetStateAction<IAdminImportManagerRow[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  pushAdmin: (adminImports: IAdminImportManagerRow[]) => Promise<void>
  file: File|null
  setFile: React.Dispatch<React.SetStateAction<File|null>>
  fileRef: React.MutableRefObject<any>
  refreshToken: () => void
  adminImportId: number
  setAdminImportId: React.Dispatch<React.SetStateAction<number>>
  adminImport: (name: string, amount: number) => void
  showSearch: boolean
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>
}

const AdminImportManagerContext = createContext<IAdminImportManagerContextContextProps>({ } as IAdminImportManagerContextContextProps)

export const AdminImportManagerProvider: React.FC<any> = () => {
  const [ token, setToken ] = useState<string>(generateToken(32))
  const [ adminImportRows, setAdminImportRows ] = useState<IAdminImportManagerRow[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ file, setFile ] = useState<File|null>(null)
  const [ adminImportId, setAdminImportId ] = useState<number>(0)
  const [ showSearch, setShowSearch ] = useState<boolean>(false)

  const fileRef = useRef<any>()

  const refreshToken = () => setToken(generateToken(32))

  const pushAdmin = (adminImportRows: IAdminImportManagerRow[]) : Promise<void> => new Promise((resolve) => {
    setAdminImportRows(adminImportRows)
    setTimeout(() => resolve(), 1000)
  })

  const adminImport = (name: string, amountRows: number) => {
    $AdminImport.store({ Name: name, Amount: amountRows } as unknown as IAdminImportManager).then(({ data }: any) => {
      setAdminImportId(data.id)
    })
  }

  const value = {
    token,
    adminImportRows,
    setAdminImportRows,
    isLoading,
    setIsLoading,
    pushAdmin,
    file,
    setFile,
    fileRef,
    refreshToken,
    adminImportId,
    setAdminImportId,
    adminImport,
    showSearch,
    setShowSearch
  }

  return (
    <AdminImportManagerContext.Provider value={value}>
      <Outlet />
    </AdminImportManagerContext.Provider>
  )
}

export default AdminImportManagerContext
