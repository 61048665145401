import React, { useContext } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { PanelProvider } from '@contexts/Panel'
import { AccessAssociationProvider } from '@contexts/AccessAssociation'
import { RegistrationProvider } from '@contexts/Registration/Registration'
import AuthContext from '@contexts/Auth'
import App from '@layouts/App/App'
import Auth from '@layouts/Auth/Auth'
import Login from '@screens/Login/Login'
import ManagersRegister from '@screens/Managers/Managers.register'
import Managers from '@screens/Managers/Managers'
import ManagersEdit from '@screens/Managers/Managers.edit'
import Clients from '@screens/Clients/Clients'
import ClientsEdit from '@screens/Clients/Clients.edit'
import Permissions from '@screens/Permissions/Permissions'
import Students from '@screens/Students/Students'
import StudentsEdit from '@screens/Students/Students.edit'
import Admins from '@screens/Admins/Admins'
import Admin from '@screens/Admins/Admin/Admin'
import AccessAssociations from '@screens/AccessAssociations/AccessAssociations'
import PermissionProfiles from '@screens/PermissionProfiles/PermissionProfiles'
import StudentsRegister from '@screens/Students/Students.register'
import Teachers from '@screens/Teachers/Teachers'
import TeachersRegister from '@screens/Teachers/Teachers.register'
import Revisors from '@screens/Revisors/Revisors'
import RevisorsEdit from '@screens/Revisors/Revisors.edit'
import RevisorsRegister from '@screens/Revisors/Revisors.register'
import TeachersEdit from '@screens/Teachers/Teachers.edit'
import Loading from '@components/Loading/Loading'
import ManagerMail from '@screens/ManagerMail/ManagerMail'
import ManagerMailRegister from '@screens/ManagerMail/ManagerMail.register'
import UpdateCache from '@screens/ReportCards/UpdateCache/UpdateCache'
import Reportments from '@screens/Reportments/Reportments'
import { ReportmentProvider } from '@contexts/Reportment'
import Reportment from '@screens/Reportments/Reportment/Reportment'
import PermissionProfile from '@screens/PermissionProfiles/PermissionProfile/PermissionProfile'
import { PermissionProfileProvider } from '@contexts/PermissionProfile'
import Tools from '@screens/Tools/Tools'
import Envelope from '@screens/Envelope/Envelope'
import Classes from '@screens/Classes/Classes'
import Classroom from '@screens/Classes/Classroom/Classroom'
import ReportmentAuthorizations from '@screens/ReportmentAuthorizations/ReportmentAuthorizations'
import AccessLevels from '@screens/AccessLevels/AccessLevels'
import AccessLevelPermissionEnum from '@enums/AccessLevelPermission.enum'
import PossibleErrors from '@screens/PossibleErrors/PossibleErrors'
import { PossibleErrorProvider } from '@contexts/PossibleError'
import ReportmentAudits from '@screens/ReportmentAudits/ReportmentAudits'
import { ReportmentAuditProvider } from '@contexts/ReportmentAudit'
import ReportmentInscriptions from '@screens/ReportmentInscriptions/ReportmentInscriptions'
import ImportManagers from '@screens/ImportManagers/ImportManagers'
import { AdminImportManagerProvider } from '@contexts/AdminImportManager'
import { BulkClassesProvider } from '@contexts/BulkClasses'
import ReaderTemplates from '@screens/ReaderTemplates/ReaderTemplates'
import ReaderTemplate from '@screens/ReaderTemplates/ReaderTemplate/ReaderTemplate'
import Apps from '@screens/Apps/Apps'
import { AppProvider } from '@contexts/App'
import BulkClasses from '@screens/BulkClasses/BulkClasses'
import AdminImportManagerRow from '@components/AdminImportManagerRow/AdminImportManagerRow'
import ReportmentCategories from '../screens/ReportmentCategories/ReportmentCategories'
import { ReportmentCategoryProvider } from '@contexts/ReportmentCategory'
import AssociationSchoolAssessments from '@screens/AssociationSchoolAssessments/AssociationSchoolAssessments'
import StudyPlan from '@screens/StudyPlan/StudyPlan'
import AssociationSchoolPlatforms from '@screens/AssociationSchoolPlatforms/AssociationSchoolPlatforms'
import QuestionCategoryTypes from '@screens/Questions/QuestionCategoryTypes/QuestionCategoryTypes'
import QuestionCategories from '@screens/Questions/QuestionCategories/QuestionCategories'
import { QuestionCategoryProvider } from '@contexts/QuestionCategory'
import { QuestionCategoryTypeProvider } from '@contexts/QuestionCategoryType'
import DisablePassword from '@screens/Tools/DisablePassword/DisablePassword'
import Filters from '@screens/Filters/Filters'
import { FilterProvider } from '@contexts/Filter'
import { QuestionCategoryQuestionProvider } from '@contexts/QuestionCategoryQuestion'
import QuestionCategoryQuestions from '@screens/Questions/QuestionCategoryQuestions/QuestionCategoryQuestions'
import Users from '@screens/Users/Users'
import User from '@screens/Users/User/User'
import Schedules from '@screens/Schedules/Schedules'
import Schedule from '@screens/Schedules/Schedule/Schedule'
import StatusReleaseDate from '@screens/StatusReleaseDate/StatusReleaseDate'
import RedMilReportment from '@screens/Tools/RedMilReportment/RedMilReportment'
import AssociationPoliedro from '@screens/Tools/AssociationPoliedro/AssociationPoliedro'
import UserGroups from '@screens/UserGroups/UserGroups'
import UserGroup from '@screens/UserGroups/UserGroup/UserGroup'

const Main: React.FC = () => {
  const { admin, isLoading, isAuthenticated } = useContext(AuthContext)

  return (
    <>
      <Loading text="Aguarde..." isLoading={isLoading} />

      <Router>
        <Routes>
          {isAuthenticated ? (
            <Route element={<PanelProvider />}>
              <Route element={<App />}>
                <Route index element={<Students />} />
                <Route path="/" element={<Students />} />

                {/* Students */}
                {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_STUDENTS) && (
                  <>
                    <Route path="/alunos" element={<Students />} />
                    <Route path="/alunos/:schoolId/:id/editar/:year" element={<StudentsEdit />} />
                    <Route path="/alunos/cadastrar" element={<RegistrationProvider />}>
                      <Route index element={<StudentsRegister />} />
                    </Route>
                  </>
                )}

                {/* Teachers */}
                {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_TEACHERS) && (
                  <>
                    <Route path="/professores" element={<Teachers />} />
                    <Route path="/professores/:schoolId/:id/editar" element={<TeachersEdit />} />
                    <Route path="/professores/cadastrar" element={<RegistrationProvider />}>
                      <Route index element={<TeachersRegister />} />
                    </Route>
                  </>
                )}

                {/* Revisors */}
                {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_REVISORS) && (
                  <>
                    <Route path="/corretores" element={<Revisors />} />
                    <Route path="/corretores/:schoolId/:id/editar" element={<RevisorsEdit />} />
                    <Route path="/corretores/cadastrar" element={<RegistrationProvider />}>
                      <Route index element={<RevisorsRegister />} />
                    </Route>
                  </>
                )}

                {/* Managers */}
                {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_MANAGERS) && (
                  <>
                    <Route path="/gestores" element={<Managers />} />
                    <Route path="/gestores/:schoolId/:id/editar" element={<ManagersEdit />} />
                    <Route path="/gestores/cadastrar" element={<RegistrationProvider />}>
                      <Route index element={<ManagersRegister />} />
                    </Route>
                  </>
                )}

                {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_TEACHERS) && (
                  <>
                  </>
                )}
                <Route path="/associacao-em-massa" element={<AccessAssociationProvider />}>
                  <Route index element={<AccessAssociations />} />
                </Route>

                {/* Admins */}
                {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_ADMINS) && (
                  <>
                    <Route path="/administradores" element={<Admins />} />
                    <Route path="/administradores/:id/editar" element={<Admin />} />
                    <Route path="/administradores/cadastrar" element={<Admin />} />
                  </>
                )}

                <Route path="/clientes" element={<Clients />} />
                <Route path="/clientes/:id/editar" element={<ClientsEdit />} />

                <Route path="/permissoes" element={<Permissions />} />
                <Route element={<AppProvider />}>
                  <Route path="/chaves-de-app" element={<Apps />} />
                </Route>

                <Route path="/templates-de-leitura" element={<ReaderTemplates />} />
                <Route path="/templates-de-leitura/:id/editar" element={<ReaderTemplate />} />

                <Route path="/perfil-de-permissoes" element={<PermissionProfiles />} />
                <Route element={<PermissionProfileProvider />}>
                  <Route path="/perfil-de-permissoes/:id/editar" element={<PermissionProfile />} />
                  <Route path="/perfil-de-permissoes/cadastrar" element={<PermissionProfile />} />
                </Route>

                <Route path="/email-gestores" element={<ManagerMail />} />
                <Route path="/email-gestores/cadastrar" element={<ManagerMailRegister />} />

                <Route path="/gerar-boletim" element={<UpdateCache />} />

                {/* Reportments */}
                {admin.isSuperAdmin && (
                  <>
                    <Route path="/relatorios" element={<Reportments />} />
                    <Route element={<ReportmentProvider />}>
                      <Route path="/relatorios/:id/editar" element={<Reportment />} />
                      <Route path="/relatorios/cadastrar" element={<Reportment />} />
                    </Route>
                  </>
                )}

                <Route element={<ReportmentCategoryProvider />}>
                  <Route path="/relatorios/categorias" element={<ReportmentCategories />} />
                </Route>

                <Route element={<FilterProvider />}>
                  <Route path="/relatorios/filtros" element={<Filters />} />
                </Route>

                <Route path="/relatorios/autorizacoes" element={<ReportmentAuthorizations />} />

                <Route path="/agendamentos" element={<Schedules />} />
                <Route path="/agendamentos/cadastrar" element={<Schedule />} />
                <Route path="/agendamentos/:id/editar" element={<Schedule />} />

                <Route path="/ferramentas" element={<Tools />} />
                <Route path="/ferramentas/desabilitar-senha" element={<DisablePassword />} />
                <Route path="/ferramentas/relatorio-redmil" element={<RedMilReportment />} />
                <Route path="/ferramentas/associacao-poliedro" element={<AssociationPoliedro />} />
                <Route path="/envelopes" element={<Envelope />} />

                {/* Classes */}
                <Route path="/turmas" element={<Classes />} />
                <Route path="/turmas/:id/editar" element={<Classroom />} />
                <Route path="/turmas/cadastrar" element={<Classroom />} />

                <Route path="/turmas-em-massa" element={<BulkClassesProvider />}>
                  <Route index element={<BulkClasses />} />
                </Route>

                {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_ACCESSLEVEL) && (
                  <Route path="/niveis-de-acesso" element={<AccessLevels />} />
                )}

                <Route element={<PossibleErrorProvider />}>
                  <Route path="/validar-possiveis-erros" element={<PossibleErrors />} />
                </Route>

                <Route element={<ReportmentAuditProvider />}>
                  <Route path="/relatorio-auditoria-erros" element={<ReportmentAudits />} />
                </Route>

                <Route path="/relatorio-responsavel-inscricao" element={<ReportmentInscriptions />} />

                <Route element={<AdminImportManagerProvider />}>
                  <Route path="/importar-usuarios/:id/visualizar" element={<AdminImportManagerRow />} />
                  <Route path="/importar-usuarios" element={<ImportManagers />} />
                </Route>

                <Route path="/associacao-escola-simulado" element={<AssociationSchoolAssessments />} />
                <Route path="/associacao-escola-plataforma" element={<AssociationSchoolPlatforms />} />

                <Route path="/plano-estudo" element={<StudyPlan />} />

                <Route element={<QuestionCategoryTypeProvider />}>
                  <Route path="/tipos-de-categoria-de-questao" element={<QuestionCategoryTypes />} />
                </Route>

                <Route element={<QuestionCategoryProvider />}>
                  <Route path="/categorias-de-questao" element={<QuestionCategories />} />
                </Route>

                <Route element={<QuestionCategoryQuestionProvider />}>
                  <Route path="/questoes-de-categorias-de-questao" element={<QuestionCategoryQuestions />} />
                </Route>

                {/* Users */}
                <Route path="/usuarios" element={<Users />} />
                <Route path="/usuarios/:id/editar" element={<User />} />
                <Route path="/multiusuarios" element={<UserGroups />} />
                <Route path="/multiusuarios/:id" element={<UserGroup />} />

                <Route path="/divulgacao-gabarito" element={<StatusReleaseDate />} />
              </Route>
            </Route>
          ) : (
            <Route element={<Auth />}>
              <Route path="*" element={<Login />} />
            </Route>
          )}
        </Routes>
      </Router>
    </>
  )
}

export default Main
