import axios from 'axios'
import IUser from '@interfaces/IUser'

export const all = (schoolId: number): Promise<any> => {
  return axios.get(`users/revisors/${schoolId}`)
}

export const find = (schoolId: number, revisorId: number): Promise<any> => {
  return axios.get(`users/revisors/${schoolId}/${revisorId}`)
}

export const update = (schoolId: number, revisorId: number, revisor: IUser): Promise<any> => {
  return axios.put(`users/revisors/${schoolId}/${revisorId}`, revisor)
}
