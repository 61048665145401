import React, { useMemo } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

const useReportmentColumns = (onActive: (reportment: any) => void, onDelete: (reportment: any) => void): any => {
  return useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Título',
      accessor: 'name',
    },
    {
      Header: 'Plataforma',
      accessor: 'platform.name',
    },
    {
      Header: 'Categoria',
      accessor: 'category.name',
    },
    {
      Header: 'Status',
      accessor: 'query',
      Cell: ({ row: { original: reportment } }: any) => !reportment.deletedAt ? (reportment.query ? 'Disponível': 'Em desenvolvimento') : 'Inativo',
    },
    {
      Header: 'Data de criação',
      accessor: 'createdAt',
      Cell: ({ row: { original: reportment } }: any) => moment(reportment.createdAt).format('DD/MM/YYYY [ás] hh:mm'),
    },
    {
      Header: 'Ações',
      disableSortBy: false,
      Cell: ({ row: { original: reportment } }: any) => (
        <>
          <Link to={`/relatorios/${reportment.id}/editar`} className="btn btn-sm ps-4 pe-4 btn-primary">Editar</Link>
          {!reportment.deletedAt ? (
            <button className="btn btn-sm ps-4 pe-4 btn-danger text-white ms-2" onClick={() => onDelete(reportment)}>Desativar</button>
          ) : (
            <button className="btn btn-sm ps-4 pe-4 btn-outline-primary ms-2" onClick={() => onActive(reportment)}>Ativar</button>
          )}
        </>
      ),
      style: {
        width: 260,
        textAlign: 'right',
      },
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])
}

export default useReportmentColumns
