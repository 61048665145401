import React, { useEffect, useState } from 'react'
import * as $AccessLevel from '@services/AccessLevel'
import IAccessLevel from '@interfaces/IAccessLevel'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { AccessLevel, OutlineCard } from './AccessLevels.styles'
import { AiOutlinePlus } from 'react-icons/ai'
import { getCssProperty } from '@helpers/Theme'
import { generateToken, random } from '@helpers/Utils'
import IAccessLevelPermission from '@interfaces/IAccessLevelPermission'

const AccessLevels: React.FC<any> = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ accessLevels, setAccessLevels ] = useState<IAccessLevel[]>([])

  const SweetAlert = withReactContent(Swal)

  const getAccessLevels = () => $AccessLevel.all().then(({ data: accessLevels }: any) => setAccessLevels(() => accessLevels.sort((a: IAccessLevel, b: IAccessLevel) => a.id - b.id))).finally(() => setIsLoading(false))

  useEffect(() => {
    getAccessLevels()
  }, [])

  const onDragStart = (event: React.DragEvent<HTMLDivElement|HTMLButtonElement>, permission: IAccessLevelPermission) => {
    event.dataTransfer.setData('permission', JSON.stringify(permission))
  }

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => event.preventDefault()

  const removeFromOldAccessLevel = (permission: IAccessLevelPermission) => {
    const index = accessLevels.findIndex(({ id }) => id === permission.accessLevelId)
    accessLevels[index].permissions = accessLevels[index].permissions.filter(({ id }) => id !== permission.id)
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>, accessLevel: IAccessLevel|null) => {
    const permission = JSON.parse(event.dataTransfer.getData('permission')) as IAccessLevelPermission

    removeFromOldAccessLevel(permission)

    if (accessLevel) {
      const index = accessLevels.findIndex(({ id }) => id === accessLevel.id)
      accessLevels[index].permissions.push(permission)
    } else {
      accessLevels.push({
        id: 0,
        token: generateToken(20),
        permissions: [
          permission,
        ],
      })

      accessLevels.sort((a, b) => a.id - b.id)
    }

    $AccessLevel.updatePermission(permission.accessLevelId, permission.id, accessLevel?.id ?? 0)

    setAccessLevels([
      ...accessLevels,
    ])
  }

  const handleCreatePermission = (accessLevelId: number) => SweetAlert.fire({
    title: 'Insira o nome da permissão:',
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off'
    },
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: getCssProperty('--primary-color'),
    cancelButtonColor: getCssProperty('--secondary-color'),
    confirmButtonText: 'Adicionar',
    cancelButtonText: 'Cancelar',
  }).then((result: any) => {
    if (result.value) {
      const value = result.value.replace(/\s/g, '_').toUpperCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

      const data = {
        id: -random(),
        accessLevelId,
        permission: value,
      }

      if (value.length > 0) {
        const index = accessLevels.findIndex(({ id }) => id === accessLevelId)

        if (index === -1) {
          accessLevels.push({
            id: 0,
            token: generateToken(20),
            permissions: [
              data,
            ],
          })
        } else {
          accessLevels[index].permissions.push(data)
        }

        setAccessLevels([
          ...accessLevels,
        ])

        $AccessLevel.createPermission(accessLevelId, data).then(({ data }) => setAccessLevels(data))
      }
    }
  })

  const handleCreateAccessLevel = () => {
    setIsLoading(true)

    $AccessLevel.create({
      token: generateToken(20),
    } as IAccessLevel).then(({ data }) => setAccessLevels(data)).finally(() => setIsLoading(false))
  }

  const defaultAccessLevel = accessLevels.find(({ id }) => id === 0) ?? null

  return (
    <div className="row">
      <div className="col-12">
        <AccessLevel
          className="card mb-3"
          onDragOver={onDragOver}
          onDrop={e => onDrop(e, accessLevels.find(({ id }) => id === 0) ?? null)}
        >
          <div className="card-header d-flex align-items-center justify-content-between">
            Nível de inativação
            <button className="btn btn-outline-light p-1 mb-0" onClick={() => handleCreatePermission(accessLevels.find(({ id }) => id === 0)?.id ?? 0)} disabled={isLoading}>
              Permissão <AiOutlinePlus size={20} color={getCssProperty('--primary-color')} />
            </button>
          </div>

          <div className="card-body pb-0">
            {defaultAccessLevel !== null && defaultAccessLevel.permissions.length > 0 ? (
              <div className="row">
                {accessLevels.find(({ id }) => id === 0)?.permissions.map(permission => (
                  <div className="col-12 col-md-2 mb-3" key={permission.id}>
                    <button
                      className="btn btn-sm btn-primary d-block w-100 text-center ps-2 pe-2"
                      draggable="true"
                      onDragStart={e => onDragStart(e, permission)}
                      disabled={isLoading}
                    >{permission.permission}</button>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-muted mb-3">Sem permissões relacionadas.</div>
            )}
          </div>
        </AccessLevel>
      </div>

      {accessLevels.filter(({ id }) => id !== 0).length > 0 && accessLevels.filter(({ id }) => id !== 0).map((accessLevel, index) => (
        <div className="col-12 col-sm-6 col-md-4" key={accessLevel.id}>
          <AccessLevel
            className="card mb-3"
            onDragOver={onDragOver}
            onDrop={e => onDrop(e, accessLevel)}
          >
            <div className="card-header d-flex align-items-center justify-content-between">
              Nível {index + 1} {index === 0 ? '(Super-Admin)' : ''}
              <button className="btn btn-outline-light p-1 mb-0" onClick={() => handleCreatePermission(accessLevel.id)} disabled={isLoading}>
                Permissão <AiOutlinePlus size={20} color={getCssProperty('--primary-color')} />
              </button>
            </div>

            <div className="card-body pb-0">
              {accessLevel.permissions.length > 0 ? (
                <div className="row">
                  {accessLevel.permissions.map(permission =>
                    <div className="col-12 col-md-6 mb-3" key={permission.id}>
                      <button
                        draggable="true"
                        className="btn btn-sm btn-primary d-block w-100"
                        onDragStart={(e) => onDragStart(e, permission)}
                        disabled={isLoading}
                      >{permission.permission}</button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-muted mb-3">Sem permissões relacionadas.</div>
              )}
            </div>
          </AccessLevel>
        </div>
      ))}

      <div className="col-12 col-sm-6 col-md-4">
        <OutlineCard className="card mb-3">
          <div className="card-header d-flex align-items-center justify-content-between">
            Novo nível
            <button className="btn btn-outline-light p-1 mb-0" onClick={handleCreateAccessLevel} disabled={isLoading}>
              <AiOutlinePlus size={20} color={getCssProperty('--primary-color')} />
            </button>
          </div>
        </OutlineCard>
      </div>
    </div>
  )
}

export default AccessLevels
