import axios from 'axios'
import IUser from '@interfaces/IUser'

export const all = (schoolId: number): Promise<any> => {
  return axios.get(`users/teachers/${schoolId}`)
}

export const find = (schoolId: number, teacherId: number): Promise<any> => {
  return axios.get(`users/teachers/${schoolId}/${teacherId}`)
}

export const update = (schoolId: number, teacherId: number, teacher: IUser): Promise<any> => {
  return axios.put(`users/teachers/${schoolId}/${teacherId}`, teacher)
}
