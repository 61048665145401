import styled from 'styled-components'

export const Buttons = styled.div`
  @media (max-width: 768px) {
    > .btn {
      width: 100%;
      display: block;
    }
  }
`
