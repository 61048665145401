import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  position: relative;

  > label {
    width: 100%;
    position: relative;
    cursor: pointer;
    aspect-ratio: 1/.56;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #eee;
    border-radius: 6px;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    padding: 1rem;

    > input {
      opacity: 0;
      position: absolute;
      left: 50%;
    }
  }

  > .btn-group {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    z-index: 9;
  }
`
