import React, { useMemo, useContext, useState, useEffect } from 'react'
import * as $AdminImport from '@services/AdminImport'
import AdminImportManagerContext from '@contexts/AdminImportManager'
import { Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableAdminImportManagerRowColumns'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import Pagination from '@components/Pagination/Pagination'
import { Buttons } from '../AdminImportManagerRow/AdminImportManagerRow.styles'
import IAdminImportManagerRow from '@interfaces/IAdminImportManagerRow'
import * as Utils from '@helpers/Utils'
import { Link, useParams } from 'react-router-dom'

const AdminImportManagerRow: React.FC<any> = () => {
  const { isLoading, setIsLoading } = useContext(AdminImportManagerContext)
  const { id: adminImportManagerId }: any = useParams()
  const [ search, setSearch ] = useState<string>('')
  const [ importManagerRows, setImportManagerRows ] = useState<IAdminImportManagerRow[]>([])

  useEffect(() => {
    setIsLoading(true)
    $AdminImport.search(adminImportManagerId).then(({ data }: any) => setImportManagerRows(data)).finally(() => setIsLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminImportManagerId])

  const data = useMemo(() => importManagerRows.filter((importManager: IAdminImportManagerRow) => {
    let exists = true

    if (search.length > 0)
      exists = importManager.id.toString().includes(search) ||
      importManager.name.toLowerCase().includes(search.toLowerCase()) ||
      importManager.email.toLowerCase().includes(search.toLowerCase()) ||
      importManager.schoolId.toString().includes(search)

    return exists
  }), [importManagerRows, search])

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const exportStudents = () => {
    const excel = data.map((admin: any) => ([
      admin.id,
      admin.schoolId,
      admin.name,
      admin.nickname,
      admin.email,
      admin.password,
      admin.migratedDate?.toLocaleString() ?? 'Não migrado',
      admin.roleId,
      admin.identifier,
      admin.degreedId,
      admin.className,
      admin.platformId,
      admin.adminImportManagerId,
      admin.adminImportManagerName,
    ] as string[]))

    excel.unshift([
      'ID', 'ID escola', 'Nome', 'Apelido', 'E-mail', 'Senha', 'Data migração', 'Tipo de acesso',
      'RA', 'ID Série', 'Nome Turma', 'ID Plataforma', 'ID importação', 'Importação'
    ])

    Utils.exportXLSX(excel, 'Lista Importações', 'Importacao.xlsx')
  }

  return (
    <>
      <div className="row align-items-end mb-3">
        <div className="col-12 col-lg-10">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="search">Pesquisar:</label>
                <input type="text" id="search" name="search" className="form-control"
                  placeholder="Pesquise pelo ID, nome ou escola"
                  onChange={e => setSearch(e.target.value)}
                  disabled={!importManagerRows.length || isLoading}
                />
              </div>
            </div>
          </div>
        </div>

        <Buttons className="col-12 col-lg-2 d-flex justify-content-end">
          <Link to="/importar-usuarios" className="btn btn-outline-primary me-2">Voltar</Link>
          <button className="btn btn-outline-danger" disabled={!data.length || isLoading} onClick={exportStudents}>Exportar</button>
        </Buttons>
      </div>
      <div className="card">
        <div className="card-header">
          Importações
        </div>

        <div className="card-body p-0">
          {!isLoading ? (data.length > 0 ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row: any, rowIndex: number) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Nenhum registro encontrado.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {importManagerRows.length > 0 && pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end align-items-center">
            {pageCount > 1 && (
              <Pagination
                page={pageIndex}
                pageCount={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                onClick={(page: number) => gotoPage(page)}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default AdminImportManagerRow
