import React, { useContext } from 'react'
import * as $QuestionCategory from '@services/QuestionCategory'
import { usePagination, useSortBy, useTable } from 'react-table'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import QuestionCategoryTypeContext from '@contexts/QuestionCategoryType'
import QuestionCategoryType from './QuestionCategoryType/QuestionCategoryType'
import useQuestionCategoryTypeColumns from '@hooks/useQuestionCategoryTypeColumns'

const QuestionCategoryTypes: React.FC<any> = () => {
  const { questionCategoryType, questionCategoryTypes, setQuestionCategoryTypes, canEdit, isLoading, setIsLoading, setCanEdit, onClick } = useContext(QuestionCategoryTypeContext)

  const onDelete = (questionCategoryType: any) => {
    setIsLoading(true)
    setCanEdit(false)

    $QuestionCategory.destroy(questionCategoryType.id).then(({ data: categories }: any) => setQuestionCategoryTypes(categories)).finally(() => {
      setIsLoading(false)
      setCanEdit(true)
    })
  }

  const columns = useQuestionCategoryTypeColumns(onClick, onDelete, canEdit)

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: questionCategoryTypes,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Tipos de Categoria de Questão</h1>
        <button className="btn btn-primary pe-3 ps-3" onClick={() => onClick(null)} disabled={!canEdit}>Novo tipo</button>
      </div>

      <div className="row">
        <div className={'col-12 ' + (!questionCategoryType ? '' : 'col-md-9')}>
          <div className="card">
            <div className="card-header">Tipos</div>

            <div className="card-body p-0">
              <div className="table-responsive">
                <Table
                  getTableProps={getTableProps}
                  getTableHeadProps={getTableHeadProps}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  headerGroups={headerGroups}
                  prepareRow={prepareRow}
                  isLoading={isLoading}
                />
              </div>
            </div>

            {pageCount > 1 && (
              <div className="card-footer d-flex justify-content-end">
                <Pagination
                  page={pageIndex}
                  pageCount={pageCount}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  onClick={(page: number) => gotoPage(page)}
                />
              </div>
            )}
          </div>
        </div>

        <QuestionCategoryType />
      </div>
    </>
  )
}

export default QuestionCategoryTypes
