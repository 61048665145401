import React, { useMemo } from 'react'
import { CellProps, Column } from 'react-table'
import IUser from '@interfaces/IUser'

const useUserGroupUserColumns = (onClick: (user: IUser) => void, isLoading: boolean) : Column<IUser>[] => {
  return useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Cliente',
      accessor: 'client.name',
    },
    {
      Header: 'Escola',
      Cell: ({ row: { original: { school } } }: CellProps<IUser, IUser>) => {
        return (
          <>
            <div className="text-overflow">{school?.name}</div>
            <small className="text-muted">ID: {school?.id}</small>
          </>
        )
      },
    },
    {
      Header: 'RE',
      accessor: 'usercode',
    },
    {
      Header: 'Nome',
      accessor: 'name',
    },
    {
      Header: 'E-mail',
      accessor: 'email',
    },
    {
      Header: 'Ações',
      disableSortBy: true,
      Cell: ({ row: { original: user } }: CellProps<IUser, IUser>) => (
        <div className="d-flex justify-content-end gap-3">
          <button className="btn btn-sm ps-3 pe-3 btn-danger" onClick={() => onClick(user)} disabled={isLoading}>Excluir</button>
        </div>
      ),
      style: {
        width: 160,
        textAlign: 'right',
      },
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ] as Column<IUser>[], [isLoading])
}

export default useUserGroupUserColumns
