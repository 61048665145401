import styled from 'styled-components'

export const Section = styled.div``

export const DegreeGroup = {
  Header: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 10px 16px;
    cursor: pointer;

    > label {
      font-weight: 500;
      cursor: pointer;
    }

    > div {
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background-color: #fff;
      cursor: pointer;
      padding: 0;
      border: 0;
    }
  `,
  Items: styled.div`
    padding: 0 16px;
  `,
  Item: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    > .form-check {
      > input {
        margin-right: 8px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
}
