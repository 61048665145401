import styled from 'styled-components'

export const Buttons = styled.div`
  @media (max-width: 768px) {
    > .btn {
      width: 100%;
      display: block;
    }
  }
`
export const BorderBottom = styled.div`
  @media (max-width: 768px) {
      margin-bottom: 2%;
      :not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.175);
      }
  } 
`
