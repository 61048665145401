import IReaderTemplate from '@interfaces/IReaderTemplate'
import axios from 'axios'

export const all = (): Promise<any> => axios.get('/reader-templates')

export const find = (id: number): Promise<any> => axios.get(`/reader-templates/${id}`)

export const clients = (platformId: number): Promise<any> => axios.get('/reader-templates/clients', {
  params: {
    platformId,
  }
})

export const totalQuestions = (platformId: number, clientId: number): Promise<any> => axios.get('/reader-templates/total-questions', {
  params: {
    platformId,
    clientId,
  }
})

export const days = (platformId: number, clientId: number, totalQuestions: number): Promise<any> => axios.get('/reader-templates/days', {
  params: {
    platformId,
    clientId,
    totalQuestions,
  }
})

export const assessments = (platformId: number, clientId: number, totalQuestions: number, day: number): Promise<any> => axios.get('/reader-templates/assessments', {
  params: {
    platformId,
    clientId,
    totalQuestions,
    day,
  }
})

export const update = (readerTemplate: IReaderTemplate): Promise<any> => axios.put(`/reader-templates/${readerTemplate.id}`, readerTemplate)
