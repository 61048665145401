import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import AuthContext from '../../contexts/Auth'
import { ReactComponent as Logotipo } from './../../resources/svg/evolucional.svg'
import { ReactComponent as User } from './../../resources/svg/user.svg'
import PanelContext from '../../contexts/Panel'
import { Figure, HeaderComponent } from './Header.styles'
import IClient from '@interfaces/IClient'
import Select from '@components/Select/Select'

const Header: React.FC = () => {
  const { sidebarCollapsed } = useContext(PanelContext)

  const { admin, client, clients, setClient, logout } = useContext(AuthContext)

  const handleSetClient = ({ value: clientId }: any) => {
    const client = clients.find((client: IClient) => client.id == clientId)

    if (client) {
      setClient(client)
    }
  }

  const options = useMemo(() => clients.map((client: IClient) => ({
    value: client.id,
    label: client.name,
  })), [clients])

  return (
    <HeaderComponent>
      <div className="container-fluid">
        <div className="row">
          <Figure className={'logotipo col-3 col-sm-2 ' + (sidebarCollapsed ? 'col-md-1 collapsed' : 'col-md-2')}>
            <Link to="/">
              <Logotipo />
            </Link>
          </Figure>

          <div className={'header-bar col-9 col-sm-10 ' + (sidebarCollapsed ? 'col-md-11' : 'col-md-10')}>
            <div className="env-selector">
              {options.length > 0 && (
                <div className="select-field select-clients">
                  <Select
                    type="outline"
                    onChange={handleSetClient}
                    defaultValue={client?.id}
                    options={options}
                    isSearchable={true}
                    placeholder={client?.id ? client.name : 'Selecione um cliente'}
                  />
                </div>
              )}
            </div>

            <div className="menu">
              <div className="user">
                <Dropdown>
                  <Dropdown.Toggle variant="light">
                    <User />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className="text">
                      <small>ID Admin</small>
                      {admin.id}
                    </Dropdown.Item>

                    <Dropdown.Item className="text">
                      {admin.name}
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    <Dropdown.Item onClick={logout}>Sair</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="platform" style={{ cursor: 'default' }}>
                <Dropdown>
                  <Dropdown.Toggle variant="danger">
                    <span>Administração</span>
                  </Dropdown.Toggle>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderComponent>
  )
}

export default Header
