import axios from 'axios'

export const login = (email: string, password: string): Promise<any> => {
  return axios.post('auth/login', {
    email,
    password,
  })
}

export const me = (token: string): Promise<any> => {
  return axios.get('auth/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const platformRedirect = (platformId: number, managerId: number, schoolId: number): Promise<any> => {
  return axios.post('auth/platform-redirect', {
    platformId,
    managerId,
    schoolId,
  })
}
