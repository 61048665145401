import React, { createContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import * as $ReportmentCategory from '@services/ReportmentCategory'

type ReportmentCategoryContextProps = {
  category: any
  setCategory: React.Dispatch<React.SetStateAction<any>>
  categories: any[]
  setCategories: React.Dispatch<React.SetStateAction<any[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  canEdit: boolean
  setCanEdit: React.Dispatch<React.SetStateAction<boolean>>
  onClick: (category: any) => void
}

const ReportmentCategoryContext = createContext<ReportmentCategoryContextProps>({ } as ReportmentCategoryContextProps)

export const ReportmentCategoryProvider: React.FC<any> = () => {
  const [ category, setCategory ] = useState<any|null>(null)
  const [ categories, setCategories ] = useState<any>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ canEdit, setCanEdit ] = useState<boolean>(true)

  const onClick = (category: any|null) => {
    setCanEdit(false)

    if (category === null) {
      setCategory({
        id: 0,
      })
    } else {
      setCategory(category)
    }
  }

  useEffect(() => {
    $ReportmentCategory.all().then(({ data: categories }: any) => setCategories(categories)).finally(() => setIsLoading(false))
  }, [])

  return (
    <ReportmentCategoryContext.Provider
      value={{
        category,
        setCategory,
        categories,
        setCategories,
        isLoading,
        setIsLoading,
        canEdit,
        setCanEdit,
        onClick,
      }}
    >
      <Outlet />
    </ReportmentCategoryContext.Provider>
  )
}

export default ReportmentCategoryContext
