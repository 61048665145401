import React, { useMemo } from 'react'

const useQuestionCategoryQuestionColumns = (onClick: (item: any) => void, onDelete: (item: any) => void, canEdit: boolean): any => {
  return useMemo(() => [
    {
      Header: 'Questão ID',
      accessor: 'questionId',
    },
    {
      Header: 'Categoria de Questão ID',
      accessor: 'questionCategoryId',
    },
    {
      Header: 'Ações',
      disableSortBy: true,
      Cell: ({ row: { original: item } }: any) => (
        <>
          <button className="btn btn-sm ps-3 pe-3 btn-primary me-2" onClick={() => onClick(item)} disabled={!canEdit}>Editar</button>
          <button className="btn btn-sm ps-3 pe-3 btn-danger" onClick={() => onDelete(item)} disabled={!canEdit}>Excluir</button>
        </>
      ),
      style: {
        width: 160,
        textAlign: 'right',
      },
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [canEdit])
}

export default useQuestionCategoryQuestionColumns
