import React from 'react'

const PlatformTable: React.FC<any> = (props: any) => {

  return (
    <table className="table table-striped mb-0">
      <thead>
        <tr>
          <th>ID</th>
          <th>Nome</th>
        </tr>
      </thead>
      <tbody>
        { props.Platform.length > 0 ? props.Platform.map((platform: any) => {
          return (
            <tr key={platform.id}>
              <td>{platform.id}</td>
              <td>{platform.name}</td>
            </tr>
          )
        }) : (
          <tr>
            <td colSpan={2} className="text-center">
              <span className="text-muted">Nenhuma plataforma encontrada</span>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default PlatformTable
