import React, { useMemo } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

const useReaderTemplateColumns = (): any => {
  return useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Template',
      accessor: 'name',
    },
    {
      Header: 'Data',
      accessor: 'createdAt',
      Cell: ({ row: { original: readerTemplate } }: any) => moment(readerTemplate.createdAt).format('DD/MM/YYYY [ás] hh:mm'),
    },
    {
      Header: 'Ações',
      disableSortBy: false,
      Cell: ({ row: { original: readerTemplate } }: any) => (
        <Link to={`/templates-de-leitura/${readerTemplate.id}/editar`} className="btn btn-sm ps-4 pe-4 btn-primary">Editar</Link>
      ),
      style: {
        width: 100,
        textAlign: 'right',
      },
    },
  ], [])
}

export default useReaderTemplateColumns
