import styled from 'styled-components'

export const Card = styled.div`
  > .card-body {
    > .nav-tabs {
      display: flex;
      background-color: #fff;

      > .nav-item {
        position: relative;

        > .nav-link {
          border: 1px solid #dee2e6;
          padding-right: 2rem !important;
          border-bottom: 0;

          &:hover {
            background-color: transparent;
          }

          &.active {
            background-color: #fff;
          }
        }

        > svg {
          position: absolute;
          top: 50%;
          right: 0.5rem;
          transform: translateY(-50%);
          color: var(--primary-color);
          transition: opacity 0.2s ease-in-out;
          cursor: pointer;
          opacity: 0.4;
          z-index: 9;

          &:hover {
            opacity: 1;
          }
        }

        &:not(:last-child) > .nav-link {
          border-right: 0;
        }
      }
    }

    > .tab-content {
      padding: 1rem;
      border: 1px solid #dee2e6;
      border-top: 0;
    }
  }
`
