import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineWarning } from 'react-icons/ai'
import RegistrationContext from '@contexts/Registration/Registration'
import Validation from '../Validation/Validation'
import IRegistrationTypeColumn from '@interfaces/IRegistrationTypeColumn'
import Button from '@components/Button/Button'
import Delete from './Delete/Delete'
import ReactTooltip from 'react-tooltip'
import RoleEnum from '@enums/Role.enum'

type Props = {
  onFinish: () => void;
  isLoading: boolean;
}

const Finish: React.FC<Props> = ({ onFinish, isLoading }: Props) => {
  const { entity, setEntity, entityLabels, entities, validations, registrationTypeAction, usersUpdate, role } = useContext(RegistrationContext)

  // const [ isDestroyAllLoading, setIsDestroyAllLoading ] = useState<boolean>(false)

  const onHide = () => setEntity(null)

  // const destroyAll = useCallback(() => {
  //   setIsDestroyAllLoading(true)

  //   $EntityRegistration.registrationDestroyAll(token).then(() => {
  //     validations.forEach(validation => {
  //       const entity = entities.find(entity => entity.id === validation.rowId)

  //       if (entity) {
  //         removeEntity(entity)
  //       }
  //     })
  //   }).finally(() => setIsDestroyAllLoading(false))
  // }, [entities, removeEntity, token, validations])

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={!!entity}
        onHide={onHide}
      >
        <Validation hide={onHide} />
      </Modal>

      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table table-striped table-dashed mb-0">
              <thead>
                <tr>
                  <th></th>
                  {registrationTypeAction?.getLabels().map((label: string, index: number) => (
                    <th key={index}>{label}</th>
                  ))}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {entities.map((entity: any) => {
                  const validation = validations.find(validation => validation?.rowId === entity?.id)
                  const hasRowUpdates = usersUpdate.find(hasRowUpdate => hasRowUpdate?.rowId === entity?.id)

                  return entity?.id && (
                    <tr key={entity.id}>
                      <td>
                        {validation ?
                          <AiOutlineWarning className="text-danger" />
                          : (role === RoleEnum.Manager || role === RoleEnum.Teacher) && hasRowUpdates &&
                          <>
                            <AiOutlineWarning className="text-warning" data-tip data-for="infoUpdate" />
                            <ReactTooltip id="infoUpdate" place="top" effect="solid">
                              {entityLabels[1]} já existente. <br />Dados serão atualizados.
                            </ReactTooltip>
                          </>}
                      </td>

                      {registrationTypeAction?.getColumns().map((column: IRegistrationTypeColumn) => (
                        <td className={validation ? 'text-danger' : ''} key={column.key}>{entity[column.key]}</td>
                      ))}

                      <td>
                        <div className="d-flex justify-content-end">
                          {validation && (
                            <button className="btn btn-sm btn-success me-2 text-white" onClick={() => setEntity(entity)}>Corrigir</button>
                          )}

                          <Delete entity={entity} />
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <Button
            className="btn btn-primary"
            disabled={isLoading || !entities.length || validations.length > 0}
            onClick={onFinish}
            isLoading={isLoading}
          >
            Associar {entityLabels[0].toLowerCase()}
          </Button>
        </div>
      </div>
    </>
  )
}

export default Finish
