export default {
  translations: {
    userLogs: {
      name: 'Nome',
      email: 'E-mail',
      nickname: 'Apelido',
      schoolId: 'ID da Escola',
      classId: 'ID da Classe',
      updated: 'Atualização',
      created: 'Criação',
      deleted: 'Exclusão',
    },
  }
}
