import IAutomaticAssociation from '@interfaces/IAutomaticAssociation'
import axios from 'axios'

export const migrationForce = (): Promise<any> => {
  return axios.post('tools/migration-force')
}

export const envelope = (schoolId: number, params: any = {}): Promise<any> => {
  return axios.get(`tools/envelope/${schoolId}`, {
    params,
  })
}

export const envelopeStatus = (params: any = {}): Promise<any> => {
  return axios.get('tools/envelope-status', {
    params,
  })
}

export const statusReportment = (assessmentId: number, params: any = {}): Promise<any> => {
  return axios.get(`tools/status-reportment/${assessmentId}`, {
    params,
  })
}

export const envelopeCreate = (params: any = {}): Promise<any> => {
  return axios.post('/tools/envelope-create', params)
}

export const audit = (answerCardAuditId: number): Promise<any> => {
  return axios.get(`tools/answer-card-audit/${answerCardAuditId}`)
}

export const ranking = (schoolId: number, adminId: number): Promise<any> => {
  return axios.get(`tools/ranking/${schoolId}/${adminId}`)
}

export const machineLogs = (): Promise<any> => {
  return axios.get('tools/machine-logs')
}

export const resetMachine = (userSession: string): Promise<any> => {
  return axios.get('tools/machine-reset', { params: {
    userSession
  }})
}

export const sendSchool = (schoolId: number): Promise<any> => {
  return axios.get(`tools/send-disable-school/${schoolId}`)
}

export const allSchools = (): Promise<any> => {
  return axios.get('tools/disable-school/')
}

export const removeSchool = (ruleId: number): Promise<any> => {
  return axios.delete(`/tools/disable-school/${ruleId}`)
}

export const reportmentRedMil = (assessmentId: number): Promise<any> => {
  return axios.get(`tools/reportment-redmil/${assessmentId}`)
}

export const assessmentsRedMil = (): Promise<any> => {
  return axios.get('tools/assessments-redmil')
}

export const associationAutomatic = (adminId: number): Promise<any> => {
  return axios.get(`tools/automatic-association/${adminId}`)
}

export const automaticAssociationStore = (token: string, classes: any[]): Promise<any> => axios.post('/tools/automatic-association/store/', classes)

export const associationAutomaticAdmin = (): Promise<any> => {
  return axios.get('tools/automatic-association-admin')
}