import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import * as FlexmonsterReact from 'react-flexmonster'
import * as $Reportment from '@services/Reportment'
import ReportmentContext from '@contexts/Reportment'
import IChart from '@interfaces/IChart'
import { BiHelpCircle } from 'react-icons/bi'
import ReactTooltip from 'react-tooltip'
import IClient from '@interfaces/IClient'
import * as $School from '@services/School'
import Select from 'react-select'
import ISchool from '@interfaces/ISchool'
import { Spinner } from 'react-bootstrap'
import { Container } from './Pivot.styles'

interface Ref {
  refresh: () => void
  reload: () => void
}

type Props = {
  ref?: React.Ref<Ref>
  onUpdate: (slice: any, options: any, formats: any) => void
}

const Pivot: React.FC<any> = forwardRef<Ref, Props>(({ onUpdate }: Props, ref) => {
  const { clients, reportment, chart, setChart } = useContext(ReportmentContext)

  const [ clientId, setClientId ] = useState<number>(0)
  const [ schools, setSchools ] = useState<any[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ reload, setReload ] = useState<number>(0)
  const [ data, setData ] = useState<any[]>([])

  const pivotRef = useRef<FlexmonsterReact.Pivot>(null)
  const expandAllDataRef = useRef<HTMLSelectElement>(null)
  const expandLabelRef = useRef<HTMLInputElement>(null)
  const schoolRef = useRef<any>(null)

  const execute = () => {
    if (chart === null || !chart.clientId)
      return

    const formData = new FormData()

    formData.append('reportmentId', reportment.id.toString())
    formData.append('query', reportment.query)
    formData.append('clientId', chart.clientId?.toString() ?? '')
    formData.append('schoolId', chart.schoolId?.toString() ?? '')
    formData.append('userId', chart.userId?.toString() ?? '')
    formData.append('isFree', reportment.isFree ? 'true' : 'false')

    setIsLoading(true)

    $Reportment.execute(formData).then(({ data }: any) => {
      setData(data)

      if (pivotRef.current !== null) {
        pivotRef.current.flexmonster.setReport({
          dataSource: {
            data,
          },
          slice: chart.slice,
          options: {
            ...chart.options,
            showAggregationLabels: false,
          },
          formats: chart.formats,
        })
      }
    }).finally(() => setIsLoading(false))
  }

  useImperativeHandle(ref, () => ({
    refresh: execute,
    reload: () => {
      if (chart !== null) {
        setReload(reload + 1)
        setTimeout(() => {
          if (pivotRef.current !== null) {
            pivotRef.current.flexmonster.setReport({
              dataSource: {
                data,
              },
              slice: chart.slice,
              options: {
                ...chart.options,
                showAggregationLabels: false,
              },
              formats: chart.formats,
            })
          }
        }, 500)
      }
    },
  }))

  useLayoutEffect(() => {
    if (expandAllDataRef.current !== null && chart?.expandAllData) {
      expandAllDataRef.current.value = '1'
    }

    if (expandLabelRef.current !== null && chart?.expandLabel) {
      expandLabelRef.current.value = chart.expandLabel
    }
  }, [chart])

  useEffect(() => {
    execute()

    if (chart?.clientId && chart.clientId > 0) {
      setClientId(chart.clientId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (clientId > 0) {
      $School.findByClient(clientId).then(({ data: schools }: any) => setSchools(schools))
    }
  }, [clientId])

  useEffect(() => {
    if (schools.length > 1 && schoolRef.current !== null && chart?.schoolId) {
      schoolRef.current.select.setValue({
        value: chart.schoolId,
        label: schools.find((school: ISchool) => school.id === chart.schoolId)?.name,
      })
    }
  }, [schools, chart?.schoolId])

  const handleBooleanChange = ({ target: { name, value } }: React.ChangeEvent<HTMLSelectElement>) => setChart({
    ...chart,
    [name]: Number(value) === 1,
  } as IChart)

  const handleClientChange = (data: any) => {
    setClientId(data ? Number(data.value) : 0)
    setChart({
      ...chart,
      clientId: data ? Number(data.value) : null,
    } as IChart)

    if (data === null)
      setSchools([])
  }

  const handleSchoolChange = (data: any) => {
    if (data?.value !== chart?.schoolId) {
      setChart({
        ...chart,
        schoolId: data ? Number(data.value) : null,
      } as IChart)
    }
  }

  const handleUserChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setChart({
    ...chart,
    userId: Number(value),
  } as IChart)

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => setChart({
    ...chart,
    [name]: value,
  } as IChart)

  const handleUpdate = () => {
    if (pivotRef.current !== null) {
      const report = pivotRef.current.flexmonster.getReport()
      onUpdate(report.slice, report.options, report.formats)
    }
  }

  const Flexmonster = useCallback(() => (
    <FlexmonsterReact.Pivot
      ref={pivotRef}
      toolbar
      useOlapFormatting
      componentFolder="https://cdn.flexmonster.com/"
      beforetoolbarcreated={(toolbar: any) => {
        let tabs = toolbar.getTabs()

        toolbar.getTabs = () => {
          tabs = tabs.filter((tab: any) => {
            return ![
              'fm-tab-connect',
              'fm-tab-open',
              'fm-tab-share',
              'fm-tab-save',
            ].includes(tab.id)
          })

          return tabs
        }
      }}
      shareReportConnection={{
        url: 'https://olap.flexmonster.com:9500'
      }}
      width="100%"
      report={{
        localization: require('@resources/lang/flexmonster/pt.json'),
      }}
      licenseKey={process.env.REACT_APP_FLEXMONSTER_LICENSE_KEY}
      reportchange={handleUpdate}
      datachanged={handleUpdate}
      update={handleUpdate}
    />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [reload])

  if (!chart)
    return null

  return (
    <Container>
      <div
        className="row"
        style={{
          position: 'relative',
          zIndex: 2
        }}
      >
        <div className="col-12 col-lg-8 col-xl-4 mb-3">
          <div className="form-group">
            <label htmlFor="name">Nome do gráfico:</label>
            <input type="text" name="name" className="form-control" id="name" placeholder="Nome do gráfico" defaultValue={chart?.name} onChange={handleInputChange} />
          </div>
        </div>

        <div className="col-12 col-lg-4 col-xl-2 mb-3">
          <div className="form-check form-switch p-0">
            <label className="form-check-label d-block" htmlFor="isFree">
              <span className="me-1">Execução de query livre?</span>
              <BiHelpCircle data-tip data-for="isFreeTip" />
              <ReactTooltip id="isFreeTip" place="top" effect="solid">
                Permitir a execução da query livremente sem a concatenação de IDs.
              </ReactTooltip>
            </label>

            <select id="isFree" defaultValue={reportment.isFree ? 1 : 0} className="form-control" disabled>
              <option value="0">Não</option>
              <option value="1">Sim</option>
            </select>
          </div>
        </div>

        <div className="col-12 col-lg-3 col-xl-2 mb-3">
          <div className="form-check form-switch p-0">
            <label className="form-check-label d-block" htmlFor="expandAllData">
              <span className="me-1">Expandir linhas e colunas?</span>
              <BiHelpCircle data-tip data-for="expandAllDataTip" />
              <ReactTooltip id="expandAllDataTip" place="top" effect="solid">
                Expandir todas as linhas e colunas automaticamente ao carregar o relatório na visualização.
              </ReactTooltip>
            </label>

            <select ref={expandAllDataRef} name="expandAllData" defaultValue={chart.expandAllData ? 1 : 0} onChange={handleBooleanChange} className="form-control">
              <option value="0">Não</option>
              <option value="1">Sim</option>
            </select>
          </div>
        </div>

        <div className="col-12 col-lg-3 col-xl-2 mb-3">
          <div className="form-check form-switch p-0">
            <label className="form-check-label d-block" htmlFor="expandLabel">
              <span className="me-1">Expandir linha específica:</span>
              <BiHelpCircle data-tip data-for="expandLabelTip" />
              <ReactTooltip id="expandLabelTip" place="top" effect="solid">
                Se preenchido, expandirá a linha específica ao carregar o relatório na visualização.
              </ReactTooltip>
            </label>

            <input ref={expandLabelRef} type="text" name="expandLabel" className="form-control" onChange={handleInputChange} defaultValue={chart.expandLabel} disabled={chart?.expandAllData} />
          </div>
        </div>

        <div className="col-12 col-lg-3 col-xl-2 mb-3">
          <div className="form-group">
            <label htmlFor="clientId">Cliente:</label>
            {clients.length > 0 && (
              <Select
                name="clientId"
                onChange={handleClientChange}
                options={clients.map((client: IClient) => ({ value: client.id, label: client.name }))}
                defaultValue={chart ? {
                  value: chart.clientId,
                  label: clients.find((client: IClient) => client.id === chart.clientId)?.name,
                } : null}
                placeholder="Selecione um cliente"
                isSearchable
                isClearable
              />
            )}
          </div>
        </div>

        <div className="col-12 col-lg-3 col-xl-2 mb-3">
          <div className="form-group">
            <label htmlFor="clientId">Escola:</label>
            {schools.length > 0 ? (
              <Select
                ref={schoolRef}
                name="schoolId"
                onChange={handleSchoolChange}
                options={schools.map((school: ISchool) => ({
                  value: school.id,
                  label: school.name,
                }))}
                defaultValue={chart ? {
                  value: chart.schoolId,
                  label: schools.find((school: ISchool) => school.id === chart?.schoolId)?.name,
                } : null}
                placeholder="Selecione a escola"
                isSearchable
                isClearable
              />
            ) : (
              <Select name="schoolId" placeholder="Selecione a escola" isDisabled />
            )}
          </div>
        </div>

        <div className="col-12 col-lg-3 col-xl-2 mb-3">
          <div className="form-group">
            <label htmlFor="userId">ID usuário:</label>
            <input type="text" name="userId" className="form-control" onChange={handleUserChange} defaultValue={chart.userId || ''} disabled={!chart.clientId || !schools.length} />
          </div>
        </div>
      </div>

      <div className="flexmonster-container">
        {isLoading && (
          <div className="loading-container">
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        <Flexmonster />
      </div>
    </Container>
  )
})

Pivot.displayName = 'Pivot'

export default Pivot
