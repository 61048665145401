import IClass from '@interfaces/IClass'
import axios from 'axios'

export const all = (degreeId: number): Promise<any> => {
  return axios.get(`/degrees/${degreeId}/classes`)
}

export const create = (degreeId: number, name: string): Promise<any> => {
  return axios.post(`/degrees/${degreeId}/classes`, {
    name,
  })
}

export const update = (degreeId: number, classId: number, data: IClass): Promise<any> => {
  return axios.put(`/degrees/${degreeId}/classes/${classId}`, data)
}

export const remove = (degreeId: number, classId: number): Promise<any> => {
  return axios.delete(`/degrees/${degreeId}/classes/${classId}`)
}

export const fromSchoolId = (schoolId: number): Promise<any> => {
  return axios.get(`/classes/${schoolId}/list`)
}

export const allDegrees = (): Promise<any> => {
  return axios.get('/classes/degrees')
}

export const find = (classId: number): Promise<any> => {
  return axios.get(`/classes/${classId}`)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const store = (classroom: any): Promise<any> => {
  return axios.post('/classes/', classroom)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateAdmin = (class_id: number, classroom: any): Promise<any> => {
  return axios.put('classes/' + class_id, classroom)
}

export const bulkStore = (token: string, classes: IClass[]): Promise<any> => axios.post(`/classes/bulk/${token}`, classes)