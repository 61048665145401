import React, { createContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import * as $QuestionCategoryQuestion from '@services/QuestionCategoryQuestion'

type QuestionCategoryQuestionContextProps = {
  questionCategoryQuestion: any
  setQuestionCategoryQuestion: React.Dispatch<React.SetStateAction<any>>
  questionCategoryQuestions: any[]
  setQuestionCategoryQuestions: React.Dispatch<React.SetStateAction<any[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  canEdit: boolean
  setCanEdit: React.Dispatch<React.SetStateAction<boolean>>
  onClick: (category: any) => void
}

const QuestionCategoryQuestionContext = createContext<QuestionCategoryQuestionContextProps>({ } as QuestionCategoryQuestionContextProps)

export const QuestionCategoryQuestionProvider: React.FC<any> = () => {
  const [ questionCategoryQuestion, setQuestionCategoryQuestion ] = useState<any|null>(null)
  const [ questionCategoryQuestions, setQuestionCategoryQuestions ] = useState<any>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ canEdit, setCanEdit ] = useState<boolean>(true)

  const onClick = (questionCategoryQuestion: any|null) => {
    setCanEdit(false)

    if (questionCategoryQuestion === null) {
      setQuestionCategoryQuestion({
        id: 0,
      })
    } else {
      setQuestionCategoryQuestion(questionCategoryQuestion)
    }
  }

  useEffect(() => {
    $QuestionCategoryQuestion.all().then(({ data: questionCategoryQuestions }: any) => setQuestionCategoryQuestions(questionCategoryQuestions)).finally(() => setIsLoading(false))
  }, [])

  return (
    <QuestionCategoryQuestionContext.Provider
      value={{
        questionCategoryQuestion,
        setQuestionCategoryQuestion,
        questionCategoryQuestions,
        setQuestionCategoryQuestions,
        isLoading,
        setIsLoading,
        canEdit,
        setCanEdit,
        onClick,
      }}
    >
      <Outlet />
    </QuestionCategoryQuestionContext.Provider>
  )
}

export default QuestionCategoryQuestionContext
