import styled from 'styled-components'

export const Button = styled.button`
  width: 32px;
  height: 32px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 100%;
  position: relative;
  padding: 0;
  z-index: 2;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;

    &::after {
      display: none;
    }
  }
`
