const columns: any[] = [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Nome',
    accessor: 'name',
  },
  {
    Header: 'Ativo',
    accessor: 'active',
  },
  {
    Header: 'Editável',
    accessor: 'canEdit',
  },
]

export default columns
