import React, { createContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import * as $PossibleError from '@services/PossibleError'
import IPossibleError from '@interfaces/IPossibleError'

type PossibleErrorContextProps = {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  answers: IAnswersValidate[]
  setAnswers: React.Dispatch<React.SetStateAction<IAnswersValidate[]>>
  line: number
  setLine: React.Dispatch<React.SetStateAction<number>>
  possibleError: IPossibleError
  setPossibleError: React.Dispatch<React.SetStateAction<IPossibleError>>
  possibleErrors: IPossibleError[]
  setPossibleErrors: React.Dispatch<React.SetStateAction<IPossibleError[]>>
  schools: IPossibleError[]
  setSchools: React.Dispatch<React.SetStateAction<IPossibleError[]>>
  evaluations: IPossibleError[]
  setEvaluations: React.Dispatch<React.SetStateAction<IPossibleError[]>>
  position: number
  setPosition: React.Dispatch<React.SetStateAction<number>>
  amount: number
  setAmount: React.Dispatch<React.SetStateAction<number>>
  handleModal: (possibleError: any) => void
}

interface IAnswersValidate {
  position: number
  response: string
}

const PossibleErrorContext = createContext<PossibleErrorContextProps>({ } as PossibleErrorContextProps)

export const PossibleErrorProvider: React.FC<any> = () => {
  const [ possibleErrors, setPossibleErrors ] = useState<IPossibleError[]>([])
  const [ schools, setSchools ] = useState<IPossibleError[]>([])
  const [ evaluations, setEvaluations ] = useState<IPossibleError[]>([])

  const [ showModal, setShowModal ] = useState<boolean>(false)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ answers, setAnswers ] = useState<IAnswersValidate[]>([])
  const [ line, setLine ] = useState<number>(0)
  const [ possibleError, setPossibleError ] = useState<IPossibleError>({} as IPossibleError)
  const [ position, setPosition ] = useState<number>(0)
  const [ amount, setAmount ] = useState<number>(0)

  useEffect(() => {
    $PossibleError.all().then(({ data }: any) => {
      setSchools(data.schools)
      setEvaluations(data.evaluations)
      setPossibleErrors(data.possibleErros)
    }).finally(() => setIsLoading(false))
  }, [])

  const handleModal = (possibleError: any) => {
    setIsLoading(true)
    setAnswers([])
    setPossibleError(possibleError.original)
    setPosition(possibleError.index+1)

    const an: any = []
    Array.from(possibleError?.original.answers).map((answer: any, position: number) => {
      an.push({
        position: position+1,
        response: answer
      })
    })

    setAnswers(an)
    setLine(1)
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
    setShowModal(true)
  }

  return (
    <PossibleErrorContext.Provider value={{
      showModal,
      setShowModal,
      isLoading,
      setIsLoading,
      answers,
      setAnswers,
      line,
      setLine,
      possibleError,
      setPossibleError,
      possibleErrors,
      setPossibleErrors,
      schools,
      setSchools,
      evaluations,
      setEvaluations,
      position,
      setPosition,
      amount,
      setAmount,
      handleModal,
    }}
    >
      <Outlet />
    </PossibleErrorContext.Provider>
  )
}

export default PossibleErrorContext
