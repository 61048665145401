import React, { useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '@contexts/Auth'
import * as $Auth from '@services/Auth'
import Button from '@components/Button/Button'
import axios from 'axios'
import { Screen } from './Login.styles'

const Login: React.FC<any> = () => {
  const { setAdmin, setToken, setExpiresAt, setIsAuthenticated } = useContext(AuthContext)

  const [ login, setLogin ] = useState<string>('')
  const [ password, setPassword ] = useState<string>('')
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const attempt = useCallback((e: any) => {
    e.preventDefault()

    setIsLoading(true)

    $Auth.login(login, password).then(({ data: { admin, token, expiresAt } }: any) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      setTimeout(() => {
        setAdmin(admin)
        setToken(token)
        setExpiresAt(expiresAt)
        setIsAuthenticated(true)
      }, 500)
    }).finally(() => setIsLoading(false))
  }, [login, password, setAdmin, setExpiresAt, setIsAuthenticated, setToken])

  return (
    <Screen className="login-page panel">
      <div className="title">
        <h1 className="mb-0">Bem-vindo de volta :)</h1>
      </div>

      <form className="form">
        <div className="form-group mb-3 row">
          <label htmlFor="login-input" className="col-3 col-form-label">E-mail</label>
          <div className="col-9">
            <input type="text" name="login" id="login-input" className="form-control" onChange={(e) => setLogin(e.target.value)} disabled={isLoading} />
          </div>
        </div>

        <div className="form-group mb-3 row">
          <label htmlFor="password-input" className="col-3 col-form-label">Senha</label>
          <div className="col-9">
            <input type="password" name="password" id="password-input" className="form-control" onChange={(e) => setPassword(e.target.value)} disabled={isLoading} />
          </div>
        </div>

        <div className="options d-flex justify-content-between align-items-center mb-4">
          <div className="form-check">
            <input type="checkbox" name="remember" className="form-check-input" id="remember" />
            <label htmlFor="remember" className="form-check-label">Mantenha-me logado</label>
          </div>

          <Link to="#" className="btn btn-link">Esqueci minha senha &gt;</Link>
        </div>

        <div className="button mb-4">
          <Button type="submit" className="btn btn-primary" onClick={attempt} isLoading={isLoading} loadingText="Aguarde...">Entrar</Button>
        </div>
      </form>
    </Screen>
  )
}

export default Login
