import React, { useState, useEffect } from 'react'
import * as $Tools from '@services/Tools'
import { Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableDisableChangeSchoolColumns'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import Pagination from '@components/Pagination/Pagination'
import { Link } from 'react-router-dom'
import { Buttons } from '@screens/Permissions/Permissions.styles'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import ISchool from '@interfaces/ISchool'
import { getCssProperty } from '@helpers/Theme'

const DisablePassword: React.FC<any> = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ schoolId, setSchoolId ] = useState<number>(0)
  const [ schools, setSchools ] = useState<ISchool[]>([])

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    setIsLoading(true)
    getSchools()
  }, [])

  const getSchools = () => {
    $Tools.allSchools().then(({ data }: any) => setSchools(data)).finally(() => setIsLoading(false))
  }

  const sendSchool = () => {
    setIsLoading(true)
    $Tools.sendSchool(schoolId).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Escola cadastrada com sucesso!',
        icon: 'success'
      })
    }).catch((e) => {
      SweetAlert.fire({
        title: 'Erro!',
        text: e.response?.data?.message ?? 'Erro ao cadastrar escola!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
      getSchools()
    })
  }

  const onDelete = (ruleId: number) => {
    SweetAlert.fire({
      title: 'Deseja prosseguir?',
      text: 'Deseja prosseguir com a desassociação? Você não poderá voltar atrás dessa decisão.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--secondary-color'),
      cancelButtonColor: getCssProperty('--primary-color'),
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result: any) => {
      if (result.value && ruleId > 0) {
        setIsLoading(true)

        SweetAlert.showLoading()

        $Tools.removeSchool(ruleId as number).then(() => {
          setSchools(schools => schools.filter(school => school.ruleId !== ruleId))
        }).finally(() => {
          setIsLoading(false)
          SweetAlert.fire({
            title: 'Sucesso!',
            text: 'Escola desassociada com sucesso.',
            icon: 'success',
            didOpen: () => SweetAlert.hideLoading(),
          })
        })
      }
    })
  }

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: schools,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  return (
    <>
      <div className="row align-items-end mb-3">
        <Buttons className="col-12 d-flex justify-content-end">
          <Link to="/ferramentas" className="btn btn-outline-primary">Voltar</Link>
        </Buttons>
      </div>
      <div className="card mb-3">
        <div className="card-header">Desabilitar escola de alteração de senha</div>
        <div className="card-body p-3">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="schoolId">Id Escola:</label>
                <input type="number" id="schoolId" name="schoolId" className="form-control"
                  onChange={e => setSchoolId(Number(e.target.value))}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <button className="btn btn-primary mb-3 me-2 mt-4"
                disabled={isLoading || schoolId == 0 ? true : false}
                onClick={sendSchool}
              >Cadastrar</button>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body p-3">
          {!isLoading ? (schools.length > 0 ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                      <th style={{ width: 200 }}>Ações</th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row: any, rowIndex: number) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                        <td style={{ verticalAlign: 'middle' }}>
                          <div className="d-flex justify-content-start">
                            <button className="btn btn-sm ps-4 pe-4 btn-danger" onClick={() => onDelete(row.original.ruleId)}>Excluir</button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Nenhum registro encontrado.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {schools.length > 0 && pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end align-items-center">
            {pageCount > 1 && (
              <Pagination
                page={pageIndex}
                pageCount={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                onClick={(page: number) => gotoPage(page)}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default DisablePassword
