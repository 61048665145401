import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import * as $App from '@services/App'
import * as $Client from '@services/Client'
import { generateToken } from '@helpers/Utils'

type AppContextProps = {
  app: any
  setApp: React.Dispatch<React.SetStateAction<any>>
  apps: any[]
  setApps: React.Dispatch<React.SetStateAction<any[]>>
  clients: any[]
  setClients: React.Dispatch<React.SetStateAction<any[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  canEdit: boolean
  setCanEdit: React.Dispatch<React.SetStateAction<boolean>>
  onClick: (app: any) => void
}

const AppContext = createContext<AppContextProps>({ } as AppContextProps)

export const AppProvider: React.FC<any> = () => {
  const [ app, setApp ] = useState<any|null>(null)
  const [ apps, setApps ] = useState<any>([])
  const [ clients, setClients ] = useState<any[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ canEdit, setCanEdit ] = useState<boolean>(true)

  const onClick = (app: any|null) => {
    setCanEdit(false)

    if (app === null) {
      setApp({
        id: 0,
        appId: `APP_${generateToken(28)}`,
        appSecret: generateToken(60),
      })
    } else {
      setApp(app)
    }
  }

  useEffect(() => {
    axios.all([
      $App.all(),
      $Client.all(),
    ]).then(axios.spread(({ data: apps }: any, { data: clients }: any) => {
      setApps(apps)
      setClients(clients)
    })).finally(() => setIsLoading(false))
  }, [])

  return (
    <AppContext.Provider
      value={{
        app,
        setApp,
        apps,
        setApps,
        clients,
        setClients,
        isLoading,
        setIsLoading,
        canEdit,
        setCanEdit,
        onClick,
      }}
    >
      <Outlet />
    </AppContext.Provider>
  )
}

export default AppContext
