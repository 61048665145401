import React, { createContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import * as $Filter from '@services/Filter'

type FilterContextProps = {
  filter: any
  setFilter: React.Dispatch<React.SetStateAction<any>>
  filters: any[]
  setFilters: React.Dispatch<React.SetStateAction<any[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  canEdit: boolean
  setCanEdit: React.Dispatch<React.SetStateAction<boolean>>
  onClick: (filter: any) => void
}

const FilterContext = createContext<FilterContextProps>({ } as FilterContextProps)

export const FilterProvider: React.FC<any> = () => {
  const [ filter, setFilter ] = useState<any|null>(null)
  const [ filters, setFilters ] = useState<any>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ canEdit, setCanEdit ] = useState<boolean>(true)

  const onClick = (filter: any|null) => {
    setCanEdit(false)

    if (filter === null) {
      setFilter({
        id: 0,
      })
    } else {
      setFilter(filter)
    }
  }

  useEffect(() => {
    $Filter.all().then(({ data: filters }: any) => setFilters(filters)).finally(() => setIsLoading(false))
  }, [])

  return (
    <FilterContext.Provider
      value={{
        filter,
        setFilter,
        filters,
        setFilters,
        isLoading,
        setIsLoading,
        canEdit,
        setCanEdit,
        onClick,
      }}
    >
      <Outlet />
    </FilterContext.Provider>
  )
}

export default FilterContext
