enum AccessLevelPermission {
  VIEW_ADMINS = 'VIEW_ADMINS',
  VIEW_ACCESSLEVEL = 'VIEW_ACCESSLEVEL',
  VIEW_STUDENTS = 'VIEW_STUDENTS',
  VIEW_TEACHERS = 'VIEW_TEACHERS',
  VIEW_REVISORS = 'VIEW_REVISORS',
  VIEW_MANAGERS = 'VIEW_MANAGERS',
  VIEW_REPORTMENTS = 'VIEW_REPORTMENTS',
  VIEW_PERMISSIONS = 'VIEW_PERMISSIONS',
}

export default AccessLevelPermission
