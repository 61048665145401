import React, { useCallback, useContext, useEffect, useState } from 'react'
import AccessAssociationContext from '@contexts/AccessAssociation'
import Button from '@components/Button/Button'
import Download from './Download/Download'
import Upload from './Upload/Upload'
import * as $Utils from '@helpers/Utils'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as $AccessAssociation from '@services/AccessAssociation'
import IAccessAssociation from '@interfaces/IAccessAssociation'
import * as $Platform from '@services/Platform'
import PlatformTable from '@components/PlatformTable/PlatformTable'

const AccessAssociations: React.FC<any> = () => {
  const { token, refreshToken, associations, setAssociations, isLoading, setIsLoading, fileRef, setFile } = useContext(AccessAssociationContext)
  const [ platforms, setPlatforms ] = useState<any[]>([])

  const SweetAlert = withReactContent(Swal)

  const store = useCallback(() => {
    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde',
      text: 'Associando usuários...',
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading()
    })

    $AccessAssociation.store(token, associations).then(({ data: { errors } }: any) => {
      SweetAlert.fire({
        title: 'Associação efetuada!',
        text: errors?.length > 0 ? 'A associação foi parcialmente efetuada, verifique os erros que foram gerados no processo.' : 'Associação em massa efetuada com sucesso!',
        icon: errors?.length > 0 ? 'warning' : 'success',
      }).then(() => {
        setFile(null)
        setAssociations([])
        refreshToken()

        if (errors?.length > 0) {
          const list = [
            [ 'ID da Escola', 'RA', 'RE', 'ID da Plataforma', 'ID do Simulado', 'Excluir' ],
          ]

          errors.forEach((association: IAccessAssociation) => list.push([
            association.schoolId.toString(),
            association.identifier?.toString() ?? '',
            association.userCode?.toString() ?? '',
            association.platformId?.toString() ?? '',
            association.assessmentId?.toString() ?? '',
            association.toDelete ? '1' : '0',
          ]))

          $Utils.exportXLSX(list, 'Erros de Associação', `Erros de Associação - ${token}.xlsx`)
        }

        fileRef.current.value = null
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao efetuar a associação em massa, tente novamente!',
        icon: 'error',
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }, [SweetAlert, associations, fileRef, refreshToken, setAssociations, setFile, setIsLoading, token])

  const deleteAll = useCallback(() => {
    SweetAlert.fire({
      title: 'Tem certeza?',
      text: 'Prosseguir e limpar todos os registros?',
      icon: 'warning',
    }).then((result) => {
      if (result.value) {
        fileRef.current.value = null
        setFile(null)
        setAssociations([])
      }
    })
  }, [SweetAlert, fileRef, setAssociations, setFile])

  useEffect(() => {
    $Platform.all().then(({ data }: any) => {
      setPlatforms(data)
    })
  }, [])

  const showPlatforms = () => {
    SweetAlert.fire({
      title: 'Plataformas',
      html: <PlatformTable Platform={platforms} />
    })
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          Associação em massa
        </div>

        <div className="card-body">
          <div className="row pt-5 pb-5">
            <div className="col-12 col-md-8 offset-md-2 text-center">
              <div className="d-flex align-items-center">
                <Download />
                <Upload />
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary"
              onClick={showPlatforms}
            >Guia Plataformas</button>
          </div>
          <div>
            <button
              className="btn btn-danger me-2"
              onClick={deleteAll}
              disabled={!associations.length}
            >Descartar</button>

            <Button
              className="btn btn-primary"
              disabled={isLoading || !associations?.length}
              isLoading={isLoading && associations.length > 0}
              onClick={store}
            >
              Executar associação
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccessAssociations
