import IUser from '@interfaces/IUser'
import axios from 'axios'

export const all = (): Promise<any> => {
  return axios.get('/manager-mails')
}

export const store = (managerMail: IUser): Promise<any> => {
  return axios.post('/manager-mails', managerMail)
}

export const update = (manager: IUser): Promise<any> => {
  return axios.put('manager-mails/' + manager.id, manager)
}

export const updateSchoolManager = (): Promise<any> => {
  return axios.get('/manager-mails/all')
}

export const destroy = (managerId: number): Promise<any> => {
  return axios.delete(`manager-mails/${managerId}`)
}

export const updateGestao = (managerId: number, gestao: boolean): Promise<any> => {
  return axios.put(`manager-mails/${managerId}/gestao/${gestao}`)
}