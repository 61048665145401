import React, { useContext, useState } from 'react'
import * as $QuestionCategory from '@services/QuestionCategory'
import { usePagination, useSortBy, useTable } from 'react-table'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import QuestionCategoryContext from '@contexts/QuestionCategory'
import QuestionCategory from './QuestionCategory/QuestionCategory'
import useQuestionCategoryColumns from '@hooks/useQuestionCategoryColumns'
import { Modal, Spinner } from 'react-bootstrap'
import XLSX from 'xlsx'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { exportXLSX } from '@helpers/Utils'

const QuestionCategories: React.FC<any> = () => {
  const { questionCategory, questionCategories, setQuestionCategories, canEdit, isLoading, setIsLoading, setCanEdit, onClick } = useContext(QuestionCategoryContext)

  const SweetAlert = withReactContent(Swal)

  const [ showModal, setShowModal ] = useState(false)
  const [ isUploading, setIsUploading ] = useState(false)
  const [ file, setFile ] = useState<File|null>(null)

  const onDelete = (questionCategory: any) => {
    setIsLoading(true)
    setCanEdit(false)

    $QuestionCategory.destroy(questionCategory.id).then(({ data: categories }: any) => setQuestionCategories(categories)).finally(() => {
      setIsLoading(false)
      setCanEdit(true)
    })
  }

  const columns = useQuestionCategoryColumns(onClick, onDelete, canEdit)

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: questionCategories,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const handleFileChange = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (files) {
      setFile(files[0])
    } else {
      setFile(null)
    }
  }

  const submitFile = () => {
    if (!file)
      return

    setIsUploading(true)

    const reader = new FileReader()

    reader.onload = ({ target }: ProgressEvent<FileReader>) => {
      if (!target?.result)
        return

      const workbook = XLSX.read(target.result, {
        type: 'binary'
      })

      const worksheet = workbook.Sheets[workbook.SheetNames[0]]

      const rows = XLSX.utils.sheet_to_json(worksheet, {
        header: 1
      }) as string[][]

      rows.shift()

      console.log(rows)

      if (!rows.length) {
        setIsUploading(false)
        return
      }

      SweetAlert.fire({
        title: 'Aguarde...',
        text: 'Processando arquivo...',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => SweetAlert.showLoading(),
      })

      $QuestionCategory.upload(rows).then(({ data: categories }: any) => {
        setFile(null)
        setShowModal(false)
        setQuestionCategories(categories)

        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Arquivo processado com sucesso!',
          icon: 'success',
        })
      }).catch(() => {
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao processar o arquivo!',
          icon: 'error',
        })
      }).finally(() => setIsUploading(false))
    }

    reader.readAsBinaryString(file)
  }

  const download = () => exportXLSX([
    [
      'name',
      'questionCategoryTypeId',
      'description',
      'subGroup',
    ]
  ], 'Categorias de Questão', 'Categorias de Questão.xlsx')

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header>
          <Modal.Title className="mb-0">Cadastrar em massa</Modal.Title>
        </Modal.Header>

        <Modal.Body className="pb-0">
          <div className="form-group mb-3">
              Arquivo (<button className="btn btn-link p-0" onClick={download}>Baixar modelo</button>):
            <input
              type="file"
              className="form-control"
              onChange={handleFileChange}
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between">
          <button type="button" className="btn btn-outline-primary" onClick={() => setShowModal(false)}>Cancelar</button>
          <button type="submit" className="btn btn-primary" onClick={submitFile} disabled={!file || isUploading}>
            {isUploading && <Spinner animation="border" size="sm" className="me-2" />} Enviar
          </button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Categorias de Questão</h1>

        <div className="d-flex gap-1">
          <button
            className="btn btn-primary pe-3 ps-3"
            onClick={() => setShowModal(true)}
          >Cadastrar em massa</button>

          <button
            className="btn btn-primary pe-3 ps-3"
            onClick={() => onClick(null)}
            disabled={!canEdit}
          >Nova categoria</button>
        </div>
      </div>

      <div className="row">
        <div className={'col-12 ' + (!questionCategory ? '' : 'col-md-9')}>
          <div className="card">
            <div className="card-header">Categorias</div>

            <div className="card-body p-0">
              <div className="table-responsive">
                <Table
                  getTableProps={getTableProps}
                  getTableHeadProps={getTableHeadProps}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  headerGroups={headerGroups}
                  prepareRow={prepareRow}
                  isLoading={isLoading}
                />
              </div>
            </div>

            {pageCount > 1 && (
              <div className="card-footer d-flex justify-content-end">
                <Pagination
                  page={pageIndex}
                  pageCount={pageCount}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  onClick={(page: number) => gotoPage(page)}
                />
              </div>
            )}
          </div>
        </div>

        <QuestionCategory />
      </div>
    </>
  )
}

export default QuestionCategories
