import { createGlobalStyle } from 'styled-components'

type Props = {
  custom: string
}

export const GlobalStyle = createGlobalStyle<Props>`
  .modal-dialog.modal-lg {
    max-width: 84% !important;
  }

  ${props => props.custom}
`
