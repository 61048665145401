import React, { useState } from 'react'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'

type UseEntity = {
  entities: IRegistrationEntity[]
  setEntities: React.Dispatch<React.SetStateAction<IRegistrationEntity[]>>
  entity: IRegistrationEntity|null
  setEntity: React.Dispatch<React.SetStateAction<IRegistrationEntity|null>>
}

const useEntity = (): UseEntity => {
  const [ entities, setEntities ] = useState<IRegistrationEntity[]>([])
  const [ entity, setEntity ] = useState<IRegistrationEntity|null>(null)

  return {
    entities,
    setEntities,
    entity,
    setEntity
  }
}

export default useEntity
