import React, { createRef, useEffect } from 'react'
import { Card } from './ManagerMail.styles'
import IUser from '@interfaces/IUser'

type Props = {
  isLoading: boolean
  manager: IUser
  setManager: React.Dispatch<React.SetStateAction<IUser>>
  onSave: (manager: IUser) => void
  onCancel: () => void
}

const ManagerMailEdit: React.FC<any> = ({ isLoading, manager, setManager, onSave, onCancel }: Props) => {
  const inputRef = createRef<any>()

  useEffect(() => {
    inputRef.current.value = manager.email
  }, [inputRef, manager])

  const handleInputChange = ({ target: { name, value } }: any) => setManager({
    ...manager,
    [name]: value,
  })

  const handleOnSave = () => {
    if (!manager?.email?.length) {
      return
    }

    if (manager?.email?.includes(' ')) {
      return
    }

    onSave(manager)
  }

  return (
    <Card className="card mb-3">
      <div className="card-header">Editar Gestor</div>

      <div className="card-body">
        <div className="form-group">
          <label htmlFor="email">E-mail</label>
          <input
            id="email"
            type="text"
            name="email"
            ref={inputRef}
            className="form-control"
            disabled={isLoading}
            defaultValue={manager.email}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="card-footer">
        <div className="row">
          <div className="col-6">
            <button className="btn btn-outline-primary pe-2 pe-lg-4 ps-2 ps-lg-4" onClick={onCancel} disabled={isLoading}>Cancelar</button>
          </div>

          <div className="col-6 d-flex justify-content-end">
            <button className="btn btn-primary pe-2 pe-lg-4 ps-2 ps-lg-4" onClick={handleOnSave} disabled={isLoading}>Salvar</button>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ManagerMailEdit
