import React, { useContext } from 'react'
import QuestionCategoryQuestionContext from '@contexts/QuestionCategoryQuestion'
import * as $QuestionCategoryQuestion from '@services/QuestionCategoryQuestion'
import { Spinner } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const QuestionCategoryQuestion: React.FC<any> = () => {
  const { questionCategoryQuestion, setQuestionCategoryQuestion, setQuestionCategoryQuestions, isLoading, setIsLoading, setCanEdit } = useContext(QuestionCategoryQuestionContext)

  const SweetAlert = withReactContent(Swal)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde...',
      html: questionCategoryQuestion.id > 0 ? 'Atualizando categoria...' : 'Criando categoria...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    if (questionCategoryQuestion.id > 0) {
      $QuestionCategoryQuestion.update(questionCategoryQuestion.id, questionCategoryQuestion).then(({ data: categories }: any) => {
        setQuestionCategoryQuestions(categories)
        SweetAlert.close()
        onClose()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao atualizar a categoria!',
        })
      }).finally(() => setIsLoading(false))
    } else {
      $QuestionCategoryQuestion.create(questionCategoryQuestion).then(({ data: categories }: any) => {
        setQuestionCategoryQuestions(categories)
        SweetAlert.close()
        onClose()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao registrar categoria!',
        })
      }).finally(() => setIsLoading(false))
    }
  }

  const onClose = () => {
    setCanEdit(true)
    setIsLoading(false)
    setQuestionCategoryQuestion(null)
  }

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => setQuestionCategoryQuestion({
    ...questionCategoryQuestion,
    [name]: value.length > 0 ? value : null,
  })

  if (questionCategoryQuestion === null) {
    return null
  }

  return (
    <div className="col-12 col-md-3">
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="card-body">
            <div className="form-group mb-3">
              <label htmlFor="questionId" className="form-label mb-0">Questão ID</label>
              <input type="number" id="questionId" name="questionId" className="form-control" defaultValue={questionCategoryQuestion?.questionId} onChange={handleInputChange} required />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="questionCategoryId" className="form-label mb-0">Categoria de Questão ID</label>
              <input type="number" id="questionCategoryId" name="questionCategoryId" className="form-control" defaultValue={questionCategoryQuestion?.questionCategoryId} onChange={handleInputChange} />
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end">
            <button type="button" className="btn btn-outline-secondary pe-3 ps-3 me-2" onClick={onClose} disabled={isLoading}>Fechar</button>
            <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={isLoading}>
              <span>{questionCategoryQuestion?.id > 0 ? 'Atualizar' : 'Salvar'}</span> {isLoading && <Spinner animation="border" size="sm" variant="light" className="ms-2" />}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default QuestionCategoryQuestion
