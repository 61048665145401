import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as $Class from '@services/Class'
import * as $School from '@services/School'
import { Spinner } from 'react-bootstrap'
import AuthContext from '@contexts/Auth'
import Select from '@components/Select/Select'
import ISchool from '@interfaces/ISchool'
import IClass from '@interfaces/IClass'
import IDegree from '@interfaces/IDegree'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const Classroom: React.FC<any> = () => {
  const { client } = useContext(AuthContext)
  const { id: classId }: any = useParams()

  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ classroom, setClassroom ] = useState<IClass>({} as IClass)
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ schools, setSchools ] = useState<ISchool[]>([])

  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (classId)
      $Class.find(classId).then(({ data }: any) => {
        setClassroom(data)
      }).finally(() => setIsLoading(false))
  }, [classId])

  useEffect(() => {
    if (client?.id) {
      setIsLoading(true)

      axios.all([
        $School.all(),
        $Class.allDegrees(),
      ]).then(axios.spread(({ data: schools }: any, { data: degrees }: any) => {
        setSchools(schools),
        setDegrees(degrees)
      })).finally(() => setIsLoading(false))
    }
  }, [client])

  const handleSchoolIdChange = ({ value }: any) => setClassroom({
    ...classroom,
    schoolId: value,
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target

    setClassroom({
      ...classroom,
      [name]: value,
    })
  }

  const schoolOptions = useMemo(() => [
    ...schools.map((school: ISchool) => ({
      value: school.id,
      label: school.name,
    }))], [schools])

  const onSave = () => {
    SweetAlert.showLoading()

    $Class.store(classroom).then(({ data: classroom }: any) => SweetAlert.fire({
      title: 'Sucesso!',
      text: 'Turma cadastrada com sucesso!',
      icon: 'success'
    }).then(() => navigate(`/turmas/${classroom.id}/editar`))).catch((e: any) => SweetAlert.fire({
      title: 'Erro!',
      text: e.response?.data?.message ?? 'Ocorreu um erro ao salvar o turma!',
      icon: 'error'
    })).finally(() => SweetAlert.hideLoading())
  }

  const onUpdate = () => {
    SweetAlert.showLoading()

    $Class.updateAdmin(classroom.id, classroom).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Turma atualizada com sucesso!',
        icon: 'success'
      })
    }).catch(() => SweetAlert.fire({
      title: 'Erro!',
      text: 'Erro ao atualizar turma!',
      icon: 'error'
    })).finally(() => SweetAlert.hideLoading())
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/turmas" className="btn btn-outline-primary">Voltar</Link>
      </div>

      <div className="card">
        <div className="card-header">
          {!classroom?.id ? (isLoading ? 'Carregando...' : 'Criar Turma') : `Editar turma (${classroom?.name})`}
        </div>

        <div className="card-body pb-0">
          {classroom?.id || !isLoading ? (
            <>
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Escola</label>
                    {!classroom?.id ? schoolOptions.length > 0 && (
                      <Select
                        type="primary"
                        onChange={handleSchoolIdChange}
                        options={schoolOptions}
                        isSearchable={true}
                        disabled={isLoading}
                        placeholder="Selecione uma escola"
                      />
                    ) :
                      <input
                        type="text"
                        name="schoolId"
                        id="schoolId"
                        className="form-control"
                        placeholder="Escola"
                        disabled={classroom ? true : false}
                        defaultValue={schools.find((school: ISchool) => school.id == classroom.schoolId)?.fantasyName}
                      /> }
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group mb-3">
                    <label htmlFor="description">Série</label>
                    <select
                      id="degreeId"
                      name="degreeId"
                      className="form-control"
                      onChange={handleInputChange}
                    >
                      <option value="">Selecione uma série</option>
                      {degrees.map((degree: IDegree) => (
                        <option
                          value={degree.id}
                          key={degree.id}
                          selected={degree.id === classroom.degreeId}
                        >{degree.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group mb-3">
                    <label htmlFor="platform">Turma</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Nome"
                      onChange={handleInputChange}
                      defaultValue={classroom?.name}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        <div className="card-footer d-flex justify-content-end">
          {!classroom?.id ? (
            <button className="btn btn-primary" onClick={onSave} disabled={isLoading}>Salvar</button>
          ) : (
            <button className="btn btn-primary" onClick={onUpdate} disabled={isLoading}>Atualizar</button>
          )}
        </div>
      </div>
    </>
  )
}

export default Classroom
