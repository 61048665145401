import React, { createContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import * as $QuestionCategoryType from '@services/QuestionCategoryType'

type QuestionCategoryTypeContextProps = {
  questionCategoryType: any
  setQuestionCategoryType: React.Dispatch<React.SetStateAction<any>>
  questionCategoryTypes: any[]
  setQuestionCategoryTypes: React.Dispatch<React.SetStateAction<any[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  canEdit: boolean
  setCanEdit: React.Dispatch<React.SetStateAction<boolean>>
  onClick: (category: any) => void
}

const QuestionCategoryTypeContext = createContext<QuestionCategoryTypeContextProps>({ } as QuestionCategoryTypeContextProps)

export const QuestionCategoryTypeProvider: React.FC<any> = () => {
  const [ questionCategoryType, setQuestionCategoryType ] = useState<any|null>(null)
  const [ questionCategoryTypes, setQuestionCategoryTypes ] = useState<any>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ canEdit, setCanEdit ] = useState<boolean>(true)

  const onClick = (questionCategoryType: any|null) => {
    setCanEdit(false)

    if (questionCategoryType === null) {
      setQuestionCategoryType({
        id: 0,
      })
    } else {
      setQuestionCategoryType(questionCategoryType)
    }
  }

  useEffect(() => {
    $QuestionCategoryType.all().then(({ data: questionCategoryTypes }: any) => setQuestionCategoryTypes(questionCategoryTypes)).finally(() => setIsLoading(false))
  }, [])

  return (
    <QuestionCategoryTypeContext.Provider
      value={{
        questionCategoryType,
        setQuestionCategoryType,
        questionCategoryTypes,
        setQuestionCategoryTypes,
        isLoading,
        setIsLoading,
        canEdit,
        setCanEdit,
        onClick,
      }}
    >
      <Outlet />
    </QuestionCategoryTypeContext.Provider>
  )
}

export default QuestionCategoryTypeContext
