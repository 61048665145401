import axios from 'axios'

export const allPlatforms = (clientId: number): Promise<any> => {
  return axios.get(`/association-school-platforms/${clientId}`)
}

export const store = (PlatformId: number, DegreeId: number, SchoolId: number): Promise<any> =>
  axios.post('/association-school-platforms',
    {
      PlatformId,
      DegreeId,
      SchoolId,
    } as any)