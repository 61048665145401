import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Client from '@services/Client'
import IClient from '@interfaces/IClient'
import * as $ManagerMail from '@services/ManagerMail'
import IUser from '@interfaces/IUser'
import { getCssProperty } from '@helpers/Theme'

const ManagerMail: React.FC<any> = () => {

  const [ clients, setClients ] = useState<IClient[]>([] as IClient[])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ manager, setManager ] = useState<IUser>({} as IUser)

  const SweetAlert = withReactContent(Swal)
  const navigate = useNavigate()

  useEffect(() => {
    $Client.all().then(({ data }) => setClients(data)).finally(() => setIsLoading(false))
  }, [])

  const handleInputChange = (e: any) => {
    const { name, value } = e.target

    setManager({
      ...manager,
      [name]: value,
    })
  }

  const handleCreate = () => {
    setIsLoading(true)

    SweetAlert.showLoading()

    $ManagerMail.store({ clientId: manager.clientId, email: manager.email, gestaoUsuarios: manager.gestaoUsuarios ? (Number(manager.gestaoUsuarios) === 1 ? true : false) : false } as IUser)
      .then(() => {
        // SweetAlert.fire({
        //   title: 'Sucesso!',
        //   text: 'E-mail de Gestor cadastrado com sucesso!',
        //   icon: 'success',
        //   didOpen: () => SweetAlert.hideLoading(),
        // })

        SweetAlert.fire({
          title: 'E-mail do gestor cadastrado com sucesso!',
          text: 'Deseja prosseguir com a associação para todas as escolas do cliente?',
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: getCssProperty('--secondary-color'),
          cancelButtonColor: getCssProperty('--primary-color'),
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result: any) => {
          if (result.value) {
            setIsLoading(true)

            SweetAlert.showLoading()

            $ManagerMail.updateSchoolManager().then(() => {
              SweetAlert.fire({
                title: 'Sucesso!',
                text: 'E-mails associados com sucesso!',
                icon: 'success'
              })
            }).catch(() => {
              SweetAlert.fire({
                title: 'Erro!',
                text: 'Erro ao associar e-mails!',
                icon: 'error'
              })
            }).finally(() => {
              setIsLoading(false)
              SweetAlert.hideLoading()
            })
          } else {
            navigate('/email-gestores')
          }
        })
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro!',
          text: e.response?.data?.message ?? 'Erro ao cadastrar e-mail de Gestor!',
          icon: 'error'
        })
      }).finally(() => {
        setIsLoading(false)
        SweetAlert.hideLoading()
      })
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/email-gestores" className="btn btn-outline-primary">Voltar</Link>
      </div>

      <div className="card">
        <div className="card-header">
          Cadastrar e-mail de gestores
        </div>

        <div className="card-body pb-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="search">Cliente</label>
                {clients.length ? (
                  <select name="clientId" className="form-control" id="clientId" onChange={handleInputChange}>
                    <option value="">Selecione um Cliente</option>
                    {clients.map((client: IClient) => (
                      <option value={client.id} key={client.id}>{client.name}</option>
                    ))}
                  </select>
                ) : (
                  <input type="number" name="clientId" className="form-control" id="clientId" placeholder="Id do cliente" onChange={handleInputChange} disabled={isLoading} required />
                )}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="email">E-mail</label>
                <input type="email" name="email" className="form-control" id="email" placeholder="E-mail" onChange={handleInputChange} disabled={isLoading} required />
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="gestaoUsuarios">Gestão de usuários</label>
                <select
                  name="gestaoUsuarios"
                  id="gestaoUsuarios"
                  className="form-control"
                  onChange={handleInputChange}
                  disabled={isLoading} required
                >
                  <option value={0}>Não</option>
                  <option value={1}>Sim</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <button className="btn btn-primary" onClick={handleCreate} disabled={isLoading}>Cadastrar</button>
        </div>
      </div>
    </>
  )
}

export default ManagerMail
