import React from 'react'
import * as $Utils from '@helpers/Utils'

const download = () => $Utils.exportXLSX([
  [
    'ID da Escola',
    'Série',
    'Nome turma',
  ],
], 'Cadastro de turmas', 'Planilha cadastro de turmas.xlsx')

const Download: React.FC<any> = () => {
  return (
    <button className="btn btn-primary text-nowrap me-3 pe-3 ps-3" onClick={download}>Baixar modelo</button>
  )
}

export default Download
