import axios from 'axios'

export const all = (): Promise<any> => {
  return axios.get('/clients/')
}

export const find = (clientId: number): Promise<any> => {
  return axios.get(`clients/${clientId}`)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const update = (client_id: number, client: any): Promise<any> => {
  return axios.put('clients/' + client_id, client)
}
