import React, { createContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import * as $QuestionCategory from '@services/QuestionCategory'

type QuestionCategoryContextProps = {
  questionCategory: any
  setQuestionCategory: React.Dispatch<React.SetStateAction<any>>
  questionCategories: any[]
  setQuestionCategories: React.Dispatch<React.SetStateAction<any[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  canEdit: boolean
  setCanEdit: React.Dispatch<React.SetStateAction<boolean>>
  onClick: (category: any) => void
}

const QuestionCategoryContext = createContext<QuestionCategoryContextProps>({ } as QuestionCategoryContextProps)

export const QuestionCategoryProvider: React.FC<any> = () => {
  const [ questionCategory, setQuestionCategory ] = useState<any|null>(null)
  const [ questionCategories, setQuestionCategories ] = useState<any>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ canEdit, setCanEdit ] = useState<boolean>(true)

  const onClick = (questionCategory: any|null) => {
    setCanEdit(false)

    if (questionCategory === null) {
      setQuestionCategory({
        id: 0,
      })
    } else {
      setQuestionCategory(questionCategory)
    }
  }

  useEffect(() => {
    $QuestionCategory.all().then(({ data: questionCategories }: any) => setQuestionCategories(questionCategories)).finally(() => setIsLoading(false))
  }, [])

  return (
    <QuestionCategoryContext.Provider
      value={{
        questionCategory,
        setQuestionCategory,
        questionCategories,
        setQuestionCategories,
        isLoading,
        setIsLoading,
        canEdit,
        setCanEdit,
        onClick,
      }}
    >
      <Outlet />
    </QuestionCategoryContext.Provider>
  )
}

export default QuestionCategoryContext
