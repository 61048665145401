import React, { useMemo } from 'react'
import moment from 'moment'

const useAppColumns = (onClick: (app: any) => void, onDelete: (app: any) => void, canEdit: boolean): any => {
  return useMemo(() => [
    {
      Header: 'App ID',
      accessor: 'appId',
    },
    {
      Header: 'Nome',
      accessor: 'name',
    },
    {
      Header: 'Cliente',
      accessor: 'clientId',
      Cell: ({ row: { original: app } }: any) => !app.clientId ? <span className="text-muted">N/A</span> : app.client?.name ?? app.clientId,
    },
    {
      Header: 'Escola',
      accessor: 'schoolId',
      Cell: ({ row: { original: app } }: any) => !app.schoolId ? <span className="text-muted">N/A</span> : app.school?.name ?? app.schoolId,
    },
    {
      Header: 'Usuário',
      accessor: 'userId',
      Cell: ({ row: { original: app } }: any) => !app.userId ? <span className="text-muted">N/A</span> : app.userId,
    },
    {
      Header: 'Data',
      accessor: 'createdAt',
      Cell: ({ row: { original: app } }: any) => moment(app.createdAt).format('DD/MM/YYYY [ás] hh:mm'),
    },
    {
      Header: 'Ações',
      disableSortBy: false,
      Cell: ({ row: { original: app } }: any) => (
        <>
          <button className="btn btn-sm ps-3 pe-3 btn-primary me-2" onClick={() => onClick(app)} disabled={!canEdit}>Editar</button>
          <button className="btn btn-sm ps-3 pe-3 btn-danger" onClick={() => onDelete(app)}>Excluir</button>
        </>
      ),
      style: {
        width: 160,
        textAlign: 'right',
      },
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [canEdit])
}

export default useAppColumns
