import React, { useContext } from 'react'
import ReportmentCategoryContext from '@contexts/ReportmentCategory'
import * as $ReportmentCategory from '@services/ReportmentCategory'
import { Spinner } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import ImageUpload from '@components/ImageUpload/ImageUpload'
import Icon, { icons } from '@components/Icon/Icon'
import Select from 'react-select'

const ReportmentCategory: React.FC<any> = () => {
  const { category, setCategory, setCategories, isLoading, setIsLoading, setCanEdit } = useContext(ReportmentCategoryContext)

  const SweetAlert = withReactContent(Swal)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsLoading(true)

    const formData = new FormData(event.currentTarget)

    SweetAlert.fire({
      title: 'Aguarde...',
      html: category.id > 0 ? 'Atualizando categoria...' : 'Criando categoria...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    if (category.id > 0) {
      $ReportmentCategory.update(category.id, formData).then(({ data: categories }: any) => {
        setCategories(categories)
        SweetAlert.close()
        onClose()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao atualizar a categoria!',
        })
      }).finally(() => setIsLoading(false))
    } else {
      $ReportmentCategory.create(formData).then(({ data: categories }: any) => {
        setCategories(categories)
        SweetAlert.close()
        onClose()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao registrar categoria!',
        })
      }).finally(() => setIsLoading(false))
    }
  }

  const onClose = () => {
    setCanEdit(true)
    setIsLoading(false)
    setCategory(null)
  }

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => setCategory({
    ...category,
    [name]: value.length > 0 ? value : null,
  })

  if (category === null) {
    return null
  }

  return (
    <div className="col-12 col-md-3">
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="card-body">
            <ImageUpload
              name="file"
              label="Imagem"
              accept="image/*"
              className="mb-3"
              cover={category?.images?.medium}
              required={category?.images?.thumbnail == null}
            />

            <div className="form-group mb-3">
              <label htmlFor="name" className="form-label mb-0">Nome</label>
              <input type="text" id="name" name="name" className="form-control" defaultValue={category?.name} onChange={handleInputChange} required />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="icon" className="form-label mb-0">Ícone</label>
              <Select
                name="icon"
                placeholder="Selecione um ícone"
                defaultValue={{ value: category?.icon, label: category?.icon }}
                options={icons.map((icon: string) => ({
                  value: icon,
                  label: icon,
                }))}
                formatOptionLabel={({ label}) => (
                  <Icon
                    width={24}
                    height={24}
                    name={label}
                  />
                )}
              />
            </div>

            <div className="form-group">
              <label htmlFor="description" className="form-label mb-0">Descrição</label>
              <textarea name="description" className="form-control p-3" rows={5} id="description" defaultValue={category?.description} onChange={handleInputChange} maxLength={255}></textarea>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end">
            <button type="button" className="btn btn-outline-secondary pe-3 ps-3 me-2" onClick={onClose} disabled={isLoading}>Fechar</button>
            <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={isLoading}>
              <span>{category?.id > 0 ? 'Atualizar' : 'Salvar'}</span> {isLoading && <Spinner animation="border" size="sm" variant="light" className="ms-2" />}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ReportmentCategory
