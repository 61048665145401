import React, { useContext } from 'react'
import * as $Filter from '@services/Filter'
import { usePagination, useSortBy, useTable } from 'react-table'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import useFilterColumns from '@hooks/useFilterColumns'
import Filter from './Filter/Filter'
import FilterContext from '@contexts/Filter'
import { List } from './Filters.styles'

const Filters: React.FC<any> = () => {
  const { filter, filters, setFilters, canEdit, isLoading, setIsLoading, setCanEdit, onClick } = useContext(FilterContext)

  const onDelete = (filter: any) => {
    setIsLoading(true)
    setCanEdit(false)

    $Filter.destroy(filter.id).then(({ data: filters }: any) => setFilters(filters)).finally(() => {
      setIsLoading(false)
      setCanEdit(true)
    })
  }

  const columns = useFilterColumns(onClick, onDelete, canEdit)

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: filters,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Filtros de relatórios</h1>
        <button className="btn btn-primary pe-3 ps-3" onClick={() => onClick(null)} disabled={!canEdit}>Novo filtro</button>
      </div>

      <div className="row">
        <div className={'col-12 ' + (!filter ? '' : 'col-md-9')}>
          <div className="card">
            <div className="card-header">Filtros</div>

            <div className="card-body p-0">
              <List className="table-responsive">
                <Table
                  getTableProps={getTableProps}
                  getTableHeadProps={getTableHeadProps}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  headerGroups={headerGroups}
                  prepareRow={prepareRow}
                  isLoading={isLoading}
                />
              </List>
            </div>

            {pageCount > 1 && (
              <div className="card-footer d-flex justify-content-end">
                <Pagination
                  page={pageIndex}
                  pageCount={pageCount}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  onClick={(page: number) => gotoPage(page)}
                />
              </div>
            )}
          </div>
        </div>

        {filter && (
          <Filter />
        )}
      </div>
    </>
  )
}

export default Filters
