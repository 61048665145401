import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import moment from 'moment'
import IReportmentAuthorization from '@interfaces/IReportmentAuthorization'
import * as $ReportmentAuthorization from '@services/ReportmentAuthorization'

const ReportmentAuthorizations: React.FC<any> = () => {
  const [ authorizations, setAuthorizations ] = useState<IReportmentAuthorization[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  useEffect(() => {
    $ReportmentAuthorization.all().then(({ data }: any) => setAuthorizations(data)).finally(() => setIsLoading(false))
  }, [])

  const handleApprovement = (reportmentAuthorizationId: number, isApproved: boolean) => {
    setIsLoading(true)
    $ReportmentAuthorization.update(reportmentAuthorizationId, {
      isApproved,
    }).then(({ data }: any) => {
      const index = authorizations.findIndex((authorization) => authorization.id === reportmentAuthorizationId)
      authorizations[index] = data
      setAuthorizations([ ...authorizations ])
    }).finally(() => setIsLoading(false))
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title mb-0">Autorizações de Relatório</div>
      </div>

      <div className="card-body p-0">
        {authorizations.length ? (
          <div className="table-responsive">
            <table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Relatório</th>
                  <th>Usuário</th>
                  <th>Escola</th>
                  <th>Data da solicitação</th>
                  <th>Data da aprovação/recusa</th>
                  <th style={{ width: 170 }}>Ações</th>
                </tr>
              </thead>

              <tbody>
                {authorizations.map((authorization: IReportmentAuthorization) => (
                  <tr key={authorization.id}>
                    <td>{authorization.id}</td>
                    <td>{authorization.reportmentName}</td>
                    <td>{authorization.userName}</td>
                    <td>{authorization.schoolName}</td>
                    <td>{moment(authorization.createdAt).format('D/MM/YYYY [ás] H:mm:ss')}</td>
                    <td>
                      {authorization.approvedAt ? moment(authorization.approvedAt).format('D/MM/YYYY [ás] H:mm:ss') : (
                        <span className="text-muted">N/A</span>
                      )}
                    </td>
                    <td>
                      {authorization.isApproved === true ? (
                        <button className="btn btn-primary btn-sm me-2 ps-2 pe-2">Aprovado</button>
                      ) : (
                        <button
                          className="btn btn-outline-primary btn-sm me-2 ps-2 pe-2"
                          onClick={() => handleApprovement(authorization.id, true)}
                          disabled={isLoading}
                        >Aprovar</button>
                      )}

                      {authorization.isApproved === false ? (
                        <button className="btn btn-danger btn-sm ps-2 pe-2">Recusado</button>
                      ) : (
                        <button
                          className="btn btn-outline-danger btn-sm ps-2 pe-2"
                          onClick={() => handleApprovement(authorization.id, false)}
                          disabled={isLoading}
                        >Recusar</button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : isLoading ? (
          <div className="d-flex justify-content-center p-3">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="d-flex justify-content-center text-center p-3">Nenhum resultado encontrado.</div>
        )}
      </div>
    </div>
  )
}

export default ReportmentAuthorizations
