/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'

export const all = (): Promise<any> => axios.get('/questions/question-category-questions')

export const find = (id: number): Promise<any> => axios.get(`/questions/question-category-questions/${id}`)

export const create = (questionCategoryQuestion: any): Promise<any> => axios.post('/questions/question-category-questions', questionCategoryQuestion)

export const upload = (questionCategoryQuestions: any): Promise<any> => axios.post('/questions/question-category-questions/upload', questionCategoryQuestions)

export const update = (id: number, questionCategoryQuestion: any): Promise<any> => axios.put(`/questions/question-category-questions/${id}`, questionCategoryQuestion)

export const destroy = (id: number): Promise<any> => axios.delete(`/questions/question-category-questions/${id}`)
