import React, { useCallback, useContext, useState } from 'react'
import StudentAndProductsContext from '@contexts/StudentAndProducts'
import IDegreeGroup from '@interfaces/IDegreeGroup'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as $Student from '@services/Users/Student'
import IPlatformAssociationProduct from '@interfaces/PlatformAssociation/IPlatformAssociationProduct'
import IPlatformAssociationSelection from '@interfaces/PlatformAssociation/IPlatformAssociationSelection'
import { DegreeGroup, Section } from '../Association/Association.styles'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { getCssProperty } from '@helpers/Theme'

const Disassociation: React.FC<any> = () => {
  const { student, isLoading, setIsLoading, degreeGroups, selectedDisassociation, setSelectedDisassociation, refresh } = useContext(StudentAndProductsContext)

  const [ activeDegreeGroupIds, setActiveDegreeGroupIds ] = useState<number[]>([])

  const SweetAlert = withReactContent(Swal)

  const addPlatform = useCallback((platformId: number, productId?: number) => {
    const platformIndex = selectedDisassociation.findIndex((platform: IPlatformAssociationProduct) => platform.id === platformId)

    if (platformIndex === -1) {
      selectedDisassociation.push({
        id: platformId,
        productIds: productId ? [ productId ] : [],
      })
    } else if (platformIndex !== -1 && productId) {
      const productIndex = selectedDisassociation[platformIndex].productIds.findIndex((id: number) => id === productId)

      if (productIndex === -1) {
        selectedDisassociation[platformIndex].productIds.push(productId)
      }
    }

    setSelectedDisassociation([ ...selectedDisassociation ])
  }, [selectedDisassociation, setSelectedDisassociation])

  const removePlatform = useCallback((platformId: number, productId?: number) => {
    const platformIndex = selectedDisassociation.findIndex((platform: IPlatformAssociationProduct) => platform.id === platformId)

    if (platformIndex !== -1) {
      if (productId) {
        const productIndex = selectedDisassociation[platformIndex].productIds.findIndex((id: number) => id === productId)

        if (productIndex !== -1) {
          selectedDisassociation[platformIndex].productIds.splice(productIndex, 1)

          if (selectedDisassociation[platformIndex].productIds.length === 0) {
            selectedDisassociation.splice(platformIndex, 1)
          }
        }
      } else {
        selectedDisassociation.splice(platformIndex, 1)
      }
    }

    setSelectedDisassociation([ ...selectedDisassociation ])
  }, [selectedDisassociation, setSelectedDisassociation])

  const handleActiveDegreeGroupId = (degreeGroupId: number) => {
    if (activeDegreeGroupIds.includes(degreeGroupId)) {
      setActiveDegreeGroupIds(activeDegreeGroupIds.filter((id: number) => id !== degreeGroupId))
    } else {
      setActiveDegreeGroupIds([...activeDegreeGroupIds, degreeGroupId])
    }
  }

  const isChecked = useCallback((platformId: number, productId?: number) => {
    if (selectedDisassociation?.length > 0) {
      const platform: IPlatformAssociationProduct = selectedDisassociation.find((platform: IPlatformAssociationProduct) => platform.id === platformId) as IPlatformAssociationProduct

      if (platform) {
        if (productId) {
          return platform.productIds.includes(productId)
        } else {
          return true
        }
      }
    }

    return false
  }, [selectedDisassociation])

  const onChange = (platformId: number, productId?: number) => {
    const checked = isChecked(platformId, productId)

    if (!checked) {
      addPlatform(platformId, productId)
    } else {
      removePlatform(platformId, productId)
    }
  }

  const confirm = () => {
    setIsLoading(true)
    SweetAlert.showLoading()

    $Student.disassociate(student.schoolId as number, student.id, {
      degreeId: student.degreeId,
      classId: student.classId,
      platforms: selectedDisassociation,
    } as IPlatformAssociationSelection).then(() => SweetAlert.fire({
      icon: 'success',
      title: 'Alunos associados com sucesso!',
      text: 'Os alunos foram associados com sucesso as plataformas selecionadas.',
    })).then(() => refresh()).catch(() => SweetAlert.fire({
      icon: 'error',
      title: 'Erro ao associar alunos!',
      text: 'Ocorreu um erro ao associar os alunos a plataformas selecionadas.',
    })).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <div className="card mb-4">
      <div className="card-header">Desassociação</div>

      <div className="card-body pb-0">
        {degreeGroups.map((items: IDegreeGroup[]) => {
          const degreeGroup = items[0]
          const isActive: boolean = activeDegreeGroupIds.includes(degreeGroup.degreeGroupId)

          return (
            <Section
              key={degreeGroup.degreeGroupId}
              className="mb-3"
            >
              <DegreeGroup.Header onClick={() => handleActiveDegreeGroupId(degreeGroup.degreeGroupId)}>
                <label>{items[0]?.degreeGroupName}</label>
                <div>
                  {isActive ? (
                    <FiChevronUp
                      color={getCssProperty('--secondary-color')}
                      size={20}
                    />
                  ) : (
                    <FiChevronDown
                      color={getCssProperty('--secondary-color')}
                      size={20}
                    />
                  )}
                </div>
              </DegreeGroup.Header>

              {isActive && items.length > 0 && (
                <DegreeGroup.Items className="pt-3">
                  {items.map((item: IDegreeGroup) => (
                    <DegreeGroup.Item key={degreeGroup.degreeGroupId + '-' + item.productId}>
                      <div className="form-check d-flex">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ minWidth: 14 }}
                          checked={isChecked(item.platformId, item.productId)}
                          onChange={() => onChange(item.platformId, item.productId)}
                        />
                        <label className="form-check-label">{item.productName ?? item.platformName}</label>
                      </div>
                    </DegreeGroup.Item>
                  ))}
                </DegreeGroup.Items>
              )}
            </Section>
          )
        })}
      </div>

      <div className="card-footer d-flex justify-content-end">
        <button
          className="btn btn-danger"
          onClick={confirm}
          disabled={isLoading || !selectedDisassociation?.length}
        >Confirmar</button>
      </div>
    </div>
  )
}

export default Disassociation
