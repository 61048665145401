import React, { useContext, useEffect, useState } from 'react'
import QuestionCategoryTypeContext from '@contexts/QuestionCategoryType'
import * as $QuestionCategoryType from '@services/QuestionCategoryType'
import { Spinner } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Select from 'react-select'
import IClient from '@interfaces/IClient'
import * as $Client from '@services/Client'

const QuestionCategoryType: React.FC<any> = () => {
  const { questionCategoryType, setQuestionCategoryType, setQuestionCategoryTypes, isLoading, setIsLoading, setCanEdit } = useContext(QuestionCategoryTypeContext)

  const [ clients, setClients ] = useState<any[]>([])

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    $Client.all().then(({ data: clients }: any) => setClients(clients))
  }, [])

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde...',
      html: questionCategoryType.id > 0 ? 'Atualizando categoria...' : 'Criando categoria...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    if (questionCategoryType.id > 0) {
      $QuestionCategoryType.update(questionCategoryType.id, questionCategoryType).then(({ data: questionCategoryTypes }: any) => {
        setQuestionCategoryTypes(questionCategoryTypes)
        SweetAlert.close()
        onClose()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao atualizar a categoria!',
        })
      }).finally(() => setIsLoading(false))
    } else {
      $QuestionCategoryType.create(questionCategoryType).then(({ data: questionCategoryTypes }: any) => {
        setQuestionCategoryTypes(questionCategoryTypes)
        SweetAlert.close()
        onClose()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao registrar categoria!',
        })
      }).finally(() => setIsLoading(false))
    }
  }

  const onClose = () => {
    setCanEdit(true)
    setIsLoading(false)
    setQuestionCategoryType(null)
  }

  const handleClientChange = ({ value: clientId }: any) => setQuestionCategoryType({
    ...questionCategoryType,
    clientId,
  })

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => setQuestionCategoryType({
    ...questionCategoryType,
    [name]: value.length > 0 ? value : null,
  })

  if (questionCategoryType === null) {
    return null
  }

  return (
    <div className="col-12 col-md-3">
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="card-body">
            <div className="form-group mb-3">
              <label htmlFor="name" className="form-label mb-0">Nome</label>
              <input type="text" id="name" name="name" className="form-control" defaultValue={questionCategoryType?.name} onChange={handleInputChange} required />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="clientId" className="form-label mb-0">Cliente</label>
              {clients.length > 0 && (
                <Select
                  name="clientId"
                  placeholder="Selecione um cliente"
                  options={clients.map((client: IClient) => ({ value: client.id, label: client.name }))}
                  onChange={handleClientChange}
                  defaultValue={{ value: questionCategoryType?.client?.id, label: questionCategoryType?.client?.name }}
                  isSearchable
                  required
                />
              )}
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end">
            <button type="button" className="btn btn-outline-secondary pe-3 ps-3 me-2" onClick={onClose} disabled={isLoading}>Fechar</button>
            <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={isLoading}>
              <span>{questionCategoryType?.id > 0 ? 'Atualizar' : 'Salvar'}</span> {isLoading && <Spinner animation="border" size="sm" variant="light" className="ms-2" />}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default QuestionCategoryType
