import axios, { AxiosResponse } from 'axios'
import IPermissionGroup from '@interfaces/IPermissionGroup'

const all = () : Promise<AxiosResponse<IPermissionGroup[]>> => {
  return axios.get<IPermissionGroup[]>('/permission-groups')
}

const $PermissionGroup = {
  all,
}

export default $PermissionGroup
