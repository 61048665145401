import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Creatable from 'react-select/creatable'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $ChartStyle from '@services/ChartStyle'
import IChartStyle from '@interfaces/IChartStyle'

type Props = {
  show: boolean
  onHide: () => void
  onApply: (chartStyle: IChartStyle) => void
}

const CustomStyle: React.FC<any> = ({ show, onHide, onApply }: Props) => {
  const [ chartStyle, setChartStyle ] = useState<IChartStyle|null>(null)
  const [ chartStyles, setChartStyles ] = useState<IChartStyle[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const SweetAlert = withReactContent(Swal)
  const textareaRef = React.createRef<HTMLTextAreaElement>()

  useEffect(() => {
    $ChartStyle.all().then(({ data }: any) => setChartStyles(data)).finally(() => setIsLoading(false))
  }, [])

  const handleChartStyleChange = (option: any) => {
    if (option === null)
      return

    const { value } = option

    const chartStyle = chartStyles.find(({ id }) => id === value)

    if (chartStyle !== undefined) {
      setChartStyle(chartStyle)

      if (textareaRef.current !== null)
        textareaRef.current.value = chartStyle.style
    } else {
      setIsLoading(true)

      SweetAlert.fire({
        title: 'Aguarde...',
        text: 'Criando estilo...',
        icon: 'info',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => SweetAlert.showLoading(),
      })

      $ChartStyle.store({
        name: value,
        style: '',
      } as IChartStyle).then(({ data }: any) => {
        setChartStyle(data)
        setChartStyles([ data, ...chartStyles ])
      }).finally(() => {
        SweetAlert.close()
        setIsLoading(false)
      })
    }
  }

  const handleStyleChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (chartStyle === null)
      return

    setChartStyle({
      ...chartStyle,
      style: value,
    })

    const index = chartStyles.findIndex(({ id }) => id === chartStyle.id)

    chartStyles[index] = {
      ...chartStyles[index],
      style: value,
    }

    setChartStyles([ ...chartStyles ])
  }

  const handleApply = () => {
    if (chartStyle === null)
      return

    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Salvando alterações...',
      icon: 'info',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $ChartStyle.update(chartStyle.id, chartStyle).then(() => {
      onApply(chartStyle)
      onHide()
      setChartStyle(null)
    }).finally(() => {
      SweetAlert.close()
      setIsLoading(false)
    })
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Customizar Estilo</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Creatable
          placeholder="Selecione um estilo ou digite para criar um novo"
          options={chartStyles.map(({ id, name }) => ({ value: id, label: name }))}
          defaultValue={{ value: chartStyle?.id, label: chartStyle?.name }}
          onChange={handleChartStyleChange}
        />

        {chartStyle && (
          <textarea ref={textareaRef} className="form-control mt-3" defaultValue={chartStyle.style} onChange={handleStyleChange} rows={10} />
        )}
      </Modal.Body>

      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={handleApply} disabled={isLoading}>Aplicar</button>
      </Modal.Footer>
    </Modal>
  )
}

export default CustomStyle
