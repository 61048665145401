import React, { useContext, useState } from 'react'
import * as $QuestionCategoryQuestion from '@services/QuestionCategoryQuestion'
import { usePagination, useSortBy, useTable } from 'react-table'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import QuestionCategoryQuestionContext from '@contexts/QuestionCategoryQuestion'
import QuestionCategoryQuestion from './QuestionCategoryQuestion/QuestionCategoryQuestion'
import useQuestionCategoryQuestionColumns from '@hooks/useQuestionCategoryQuestionColumns'
import { Modal, Spinner } from 'react-bootstrap'
import XLSX from 'xlsx'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { exportXLSX } from '@helpers/Utils'

const QuestionCategoryQuestions: React.FC<any> = () => {
  const { questionCategoryQuestion, questionCategoryQuestions, setQuestionCategoryQuestions, canEdit, isLoading, setIsLoading, setCanEdit, onClick } = useContext(QuestionCategoryQuestionContext)

  const SweetAlert = withReactContent(Swal)

  const [ showModal, setShowModal ] = useState(false)
  const [ isUploading, setIsUploading ] = useState(false)
  const [ file, setFile ] = useState<File|null>(null)

  const onDelete = (questionCategoryQuestion: any) => {
    setIsLoading(true)
    setCanEdit(false)

    $QuestionCategoryQuestion.destroy(questionCategoryQuestion.id).then(() => {
      setQuestionCategoryQuestions(questionCategoryQuestions.filter((item: any) => item.id !== questionCategoryQuestion.id))
    }).finally(() => {
      setIsLoading(false)
      setCanEdit(true)
    })
  }

  const columns = useQuestionCategoryQuestionColumns(onClick, onDelete, canEdit)

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: questionCategoryQuestions,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const handleFileChange = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (files) {
      setFile(files[0])
    } else {
      setFile(null)
    }
  }

  const submitFile = () => {
    if (!file)
      return

    setIsUploading(true)

    const reader = new FileReader()

    reader.onload = ({ target }: ProgressEvent<FileReader>) => {
      if (!target?.result)
        return

      const workbook = XLSX.read(target.result, {
        type: 'binary'
      })

      const worksheet = workbook.Sheets[workbook.SheetNames[0]]

      const rows = XLSX.utils.sheet_to_json(worksheet, {
        header: 1
      }) as string[][]

      rows.shift()

      console.log(rows)

      if (!rows.length) {
        setIsUploading(false)
        return
      }

      SweetAlert.fire({
        title: 'Aguarde...',
        text: 'Processando arquivo...',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => SweetAlert.showLoading(),
      })

      $QuestionCategoryQuestion.upload(rows).then(({ data }: any) => {
        setFile(null)
        setShowModal(false)
        setQuestionCategoryQuestions(data)

        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Arquivo processado com sucesso!',
          icon: 'success',
        })
      }).catch(() => {
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao processar o arquivo!',
          icon: 'error',
        })
      }).finally(() => setIsUploading(false))
    }

    reader.readAsBinaryString(file)
  }

  const download = () => exportXLSX([
    [
      'questionId',
      'questionCategoryId'
    ]
  ], 'Questões de Cat. de Questão', 'Questões de Categoria de Questão.xlsx')

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header>
          <Modal.Title className="mb-0">Cadastrar em massa</Modal.Title>
        </Modal.Header>

        <Modal.Body className="pb-0">
          <div className="form-group mb-3">
            <label htmlFor="file">
              Arquivo (<button className="btn btn-link p-0" onClick={download}>Baixar modelo</button>):
            </label>
            <input
              type="file"
              className="form-control"
              onChange={handleFileChange}
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between">
          <button type="button" className="btn btn-outline-primary" onClick={() => setShowModal(false)}>Cancelar</button>
          <button type="submit" className="btn btn-primary" onClick={submitFile} disabled={!file || isUploading}>
            {isUploading && <Spinner animation="border" size="sm" className="me-2" />} Enviar
          </button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Questões de Categoria de Questão</h1>

        <div className="d-flex gap-1">
          <button
            className="btn btn-primary pe-3 ps-3"
            onClick={() => setShowModal(true)}
          >Cadastrar em massa</button>

          <button
            className="btn btn-primary pe-3 ps-3"
            onClick={() => onClick(null)}
            disabled={!canEdit}
          >Nova relação</button>
        </div>
      </div>

      <div className="row">
        <div className={'col-12 ' + (!questionCategoryQuestion ? '' : 'col-md-9')}>
          <div className="card">
            <div className="card-header">Relações</div>

            <div className="card-body p-0">
              <div className="table-responsive">
                <Table
                  getTableProps={getTableProps}
                  getTableHeadProps={getTableHeadProps}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  headerGroups={headerGroups}
                  prepareRow={prepareRow}
                  isLoading={isLoading}
                />
              </div>
            </div>

            {pageCount > 1 && (
              <div className="card-footer d-flex justify-content-end">
                <Pagination
                  page={pageIndex}
                  pageCount={pageCount}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  onClick={(page: number) => gotoPage(page)}
                />
              </div>
            )}
          </div>
        </div>

        <QuestionCategoryQuestion />
      </div>
    </>
  )
}

export default QuestionCategoryQuestions
