import React, { useEffect, useState } from 'react'
import { usePagination, useSortBy, useTable } from 'react-table'
import { Spinner } from 'react-bootstrap'
import $Schedule from '@services/Schedule'
import Pagination from '@components/Pagination/Pagination'
import Table from '@components/Table/Table'
import ISchedule from '@interfaces/ISchedule'
import useScheduleColumns from '@hooks/useScheduleColumns'
import { Link } from 'react-router-dom'

const Schedules: React.FC = () => {
  const [ search, setSearch ] = useState<string | null>(null)
  const [ schedules, setSchedules ] = useState<ISchedule[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  useEffect(() => {
    $Schedule.all().then(({ data }) => setSchedules(data)).finally(() => setIsLoading(false))
  }, [])

  const columns = useScheduleColumns()

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable<ISchedule>({
    columns,
    data: schedules,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  return (
    <>
      <div className="row align-items-end mb-3">
        <div className="col-8 col-md-8">
          <div className="form-group">
            <label htmlFor="search">Pesquisar:</label>
            <input type="text" name="search" className="form-control" onChange={e => setSearch(e.target.value)} />
          </div>
        </div>

        <div className="col-4 col-md-4 d-flex justify-content-end align-items-end">
          <Link to="/agendamentos/cadastrar" className="btn btn-primary pe-3 ps-3">Cadastrar agendamento</Link>
        </div>
      </div>

      <div className="card">
        <div className="card-header">Agendamentos</div>

        <div className="card-body p-0">
          {!isLoading ? (
            <div className="table-responsive">
              <Table
                getTableProps={getTableProps}
                getTableHeadProps={getTableHeadProps}
                getTableBodyProps={getTableBodyProps}
                page={page}
                headerGroups={headerGroups}
                prepareRow={prepareRow}
                isLoading={isLoading}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Schedules
