import React from 'react'
import { ReactComponent as ToManage } from '@resources/svg/toManage.svg'
import { ReactComponent as Results } from '@resources/svg/results.svg'
import { ReactComponent as Panel } from '@resources/svg/panel.svg'
import { ReactComponent as StudentGrades } from '@resources/svg/studentGrades.svg'
import { ReactComponent as EvaluationsAndResolutions } from '@resources/svg/evaluationsAndResolutions.svg'
import { ReactComponent as StudentAnalysis } from '@resources/svg/studentAnalysis.svg'
import { ReactComponent as ComparativeAnalysis } from '@resources/svg/comparativeAnalysis.svg'
import { ReactComponent as EnemSimulator } from '@resources/svg/enemSimulator.svg'
import { ReactComponent as SimulatedSimulator } from '@resources/svg/simulatedSimulator.svg'
import { ReactComponent as StrengthsAndImprove } from '@resources/svg/strengthsAndImprove.svg'
import { ReactComponent as Redaction } from '@resources/svg/redaction.svg'
import { ReactComponent as Engagement } from '@resources/svg/engagement.svg'
import { ReactComponent as Templates } from '@resources/svg/templates.svg'
import { ReactComponent as CH } from '@resources/svg/ch.svg'
import { ReactComponent as CN } from '@resources/svg/cn.svg'
import { ReactComponent as LC } from '@resources/svg/lc.svg'
import { ReactComponent as MT } from '@resources/svg/mt.svg'
import { ReactComponent as RE } from '@resources/svg/re.svg'
import { ReactComponent as Student } from '@resources/svg/student.svg'
import { ReactComponent as Teacher } from '@resources/svg/teacher.svg'
import { ReactComponent as Manager } from '@resources/svg/manager.svg'
import { ReactComponent as Revisor } from '@resources/svg/revisor.svg'
import { ReactComponent as Inscription } from '@resources/svg/inscription.svg'
import { ReactComponent as Product } from '@resources/svg/publishTemplate.svg'
import { ReactComponent as User } from '@resources/svg/user.svg'
import { ReactComponent as Classroom } from '@resources/svg/classroom.svg'
import { ReactComponent as UserAdmin } from '@resources/svg/userAdmin.svg'

export const icons: string[] = [
  'toManage',
  'results',
  'panel',
  'studentGrades',
  'evaluationsAndResolutions',
  'studentAnalysis',
  'comparativeAnalysis',
  'simulatedSimulator',
  'enemSimulator',
  'strengthsAndImprove',
  'inscription',
  'redaction',
  'engagement',
  'templates',
  'CH',
  'CN',
  'LC',
  'MT',
  'RE',
  'user',
  'student',
  'teacher',
  'manager',
  'revisor',
  'product',
  'classroom',
]

const Icon: React.FC<any> = (props: any) => {
  switch (props.name) {
  case 'toManage':
    return <ToManage {...props} />
  case 'results':
    return <Results {...props} />
  case 101:
  case 'panel':
    return <Panel {...props} />
  case 'studentGrades':
    return <StudentGrades {...props} />
  case 102:
  case 'evaluationsAndResolutions':
    return <EvaluationsAndResolutions {...props} />
  case 'studentAnalysis':
    return <StudentAnalysis {...props} />
  case 'comparativeAnalysis':
    return <ComparativeAnalysis {...props} />
  case 'simulatedSimulator':
    return <SimulatedSimulator {...props} />
  case 'enemSimulator':
    return <EnemSimulator {...props} />
  case 104:
  case 'strengthsAndImprove':
    return <StrengthsAndImprove {...props} />
  case 105:
  case 'inscription':
    return <Inscription {...props} />
  case 'redaction':
    return <Redaction {...props} />
  case 9:
  case 10:
  case 'engagement':
    return <Engagement {...props} />
  case 'templates':
    return <Templates {...props} />
  case 'CH':
    return <CH {...props} />
  case 'CN':
    return <CN {...props} />
  case 'LC':
    return <LC {...props} />
  case 'MT':
    return <MT {...props} />
  case 'RE':
    return <RE {...props} />
  case 'user':
    return <User />
  case 'student':
    return <Student />
  case 'teacher':
    return <Teacher />
  case 'manager':
    return <Manager />
  case 'revisor':
    return <Revisor />
  case 'product':
    return <Product />
  case 'classroom':
    return <Classroom />
  case 'userAdmin':
    return <UserAdmin />
  default:
    return null
  }
}

export default Icon
