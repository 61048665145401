import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import * as $ReaderTemplate from '@services/ReaderTemplate'
import * as $Platform from '@services/Platform'
import IReaderTemplate from '@interfaces/IReaderTemplate'
import IPlatform from '@interfaces/IPlatform'
import { Spinner } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import IReaderTemplateAssociation from '@interfaces/IReaderTemplateAssociation'
import Association from './Association/Association'

const ReaderTemplate: React.FC<any> = () => {
  const [ readerTemplate, setReaderTemplate ] = useState<IReaderTemplate|null>(null)
  const [ platforms, setPlatforms ] = useState<IPlatform[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ showAssociation, setShowAssociation ] = useState<boolean>(false)

  const { id: readerTemplateId }: any = useParams()
  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (readerTemplateId) {
      axios.all([
        $ReaderTemplate.find(readerTemplateId),
        $Platform.all(),
      ]).then(axios.spread(({ data: readerTemplate }: any, { data: platforms }: any) => {
        setReaderTemplate(readerTemplate)
        setPlatforms(platforms.filter((p: IPlatform) => p.id !== 8))
      })).finally(() => setIsLoading(false))
    }
  }, [readerTemplateId])

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setReaderTemplate({
      ...readerTemplate,
      [name]: value,
    } as IReaderTemplate)
  }

  const handleOnSave = (association: IReaderTemplateAssociation) => {
    setShowAssociation(false)
    setReaderTemplate({
      ...readerTemplate,
      associations: [
        ...readerTemplate?.associations || [],
        association,
      ],
    } as IReaderTemplate)
  }

  const onDelete = (association: IReaderTemplateAssociation) => {
    SweetAlert.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter essa ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        setReaderTemplate({
          ...readerTemplate,
          associations: readerTemplate?.associations?.filter((a: IReaderTemplateAssociation) => a.id !== association.id) || [],
        } as IReaderTemplate)
      }
    })
  }

  const onUpdate = () => {
    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Atualizando template de leitura...',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $ReaderTemplate.update(readerTemplate!).then(({ data: readerTemplate }: any) => {
      setReaderTemplate(readerTemplate)

      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Template de leitura atualizado com sucesso!',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Não foi possível atualizar o template de leitura!',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      })
    }).finally(() => setIsLoading(false))
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/templates-de-leitura" className="btn btn-outline-primary me-3">Voltar</Link>
        <button className="btn btn-primary" disabled={isLoading} onClick={onUpdate}>Atualizar</button>
      </div>

      {readerTemplate?.id ? (
        <>
          <div className="card mb-3">
            <div className="card-header">
              Template de leitura
            </div>

            <div className="card-body pb-0">
              <div className="row">
                <div className="col-12">
                  <div className="form-group mb-3">
                    <label htmlFor="name" className="form-label">Template</label>
                    <input type="text" className="form-control" id="name" defaultValue={readerTemplate?.name} onChange={handleInputChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>Associações</div>
                <button className="btn btn-primary" onClick={() => setShowAssociation(true)}>Nova associação</button>
              </div>
            </div>

            {showAssociation && (
              <Association platforms={platforms} onSave={handleOnSave} onCancel={() => setShowAssociation(false)} />
            )}

            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-default mb-0">
                  <thead>
                    <tr>
                      <th>ID Plataforma</th>
                      <th>Plataforma</th>
                      <th>ID Cliente</th>
                      <th>Cliente</th>
                      <th>Questões</th>
                      <th>Dia</th>
                      <th>ID Simulado</th>
                      <th>Simulado</th>
                      <th style={{ width: 80 }}>Ações</th>
                    </tr>
                  </thead>

                  <tbody>
                    {readerTemplate.associations ? readerTemplate.associations.map((association: IReaderTemplateAssociation) => (
                      <tr key={association.id}>
                        <td>{association.platformId}</td>
                        <td>{association.platform.name}</td>
                        <td>{association.clientId || 'N/A'}</td>
                        <td>{association.client?.name || 'N/A'}</td>
                        <td>{association.totalQuestions || 'N/A'}</td>
                        <td>{association.day || 'N/A'}</td>
                        <td>{association.assessmentId || 'N/A'}</td>
                        <td className="text-nowrap">{association.assessment?.name || 'N/A'}</td>
                        <td>
                          <button className="btn btn-outline-danger btn-sm" onClick={() => onDelete(association)}>Excluir</button>
                        </td>
                      </tr>
                    )) : (
                      <tr>
                        <td colSpan={9}>
                          <div className="text-center">
                            Nenhuma associação.
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center mb-3">
          <Spinner animation="border" />
        </div>
      )}
    </>
  )
}

export default ReaderTemplate
