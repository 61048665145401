import React, { useContext, useEffect, useMemo, useState } from 'react'
import Select from '@components/Select/Select'
import * as $School from '@services/School'
import * as $Classroom from '@services/Class'
import AuthContext from '@contexts/Auth'
import ISchool from '@interfaces/ISchool'
import IDegree from '@interfaces/IDegree'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import * as $AssociationSchoolPlatform from '@services/AssociationSchoolPlatform'
import axios from 'axios'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { Buttons } from './AssociationSchoolPlatforms.styles'
import IPlatform from '@interfaces/IPlatform'

const AssociationSchoolPlatforms: React.FC<any> = () => {
  const { client } = useContext(AuthContext)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ schools, setSchools ] = useState<ISchool[]>([])
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ platforms, setPlatforms ] = useState<IPlatform[]>([])
  const [ isSearching, setIsSearching ] = useState<boolean>(false)

  const SweetAlert = withReactContent(Swal)

  const [schoolId, setSchoolId] = useState<number>(0)
  const handleSelectSchoolChange = ({ value: schoolId }: any) => setSchoolId(schoolId)

  const [degreeId, setDegreeId] = useState<number>(0)
  const handleSelectDegreeChange = ({ value: degreeId }: any) => setDegreeId(degreeId)

  const [platformId, setPlatformId] = useState<number>(0)
  const handleSelectPlatformChange = ({ value: platformId }: any) => setPlatformId(platformId)

  useEffect(() => {
    setIsLoading(true)
    axios.all([
      $School.findByClient(client.id),
      $Classroom.allDegrees(),
      $AssociationSchoolPlatform.allPlatforms(client.id),
    ]).then(axios.spread(({ data: schools }: any, { data: degrees }: any, { data: platforms }: any) => {
      setSchools(schools)
      setDegrees(degrees)
      setPlatforms(platforms)
    })).finally(() => setIsLoading(false))
  }, [client])

  const schoolOptions = useMemo(() => {
    if (!client.id)
      return []

    const schoolOptions: any[] = schools.map((school: ISchool) => ({
      value: school.id,
      label: school.id + ' - ' + school.fantasyName,
    }))

    return schoolOptions
  }, [client.id, schools])

  const degreeOptions: any[] = degrees.map((degree: IDegree) => ({
    value: degree.id,
    label: degree.name,
  }))

  const platformOptions = useMemo(() => {
    if (!client.id)
      return []

    const platformOptions: any[] = platforms.map((platform: IPlatform) => ({
      value: platform.companyId,
      label: platform.id + ' - ' + platform.name,
    }))

    return platformOptions
  }, [client.id, platforms])

  const onSearch = () => {
    if (platformId > 0 && degreeId > 0 && schoolId > 0) {
      setIsLoading(true)
      setIsSearching(true)
      $AssociationSchoolPlatform.store(platformId, degreeId, schoolId).then(() => SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Associação realizada com sucesso!',
        icon: 'success'
      })).catch((e) => SweetAlert.fire({
        title: 'Erro!',
        text: e.response.data.message ?? 'Erro ao realizar as associações!',
        icon: 'error'
      })).finally(() => {
        OnCancel()
        setIsLoading(false)
        SweetAlert.hideLoading()
      })
    } else
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Todos os campos devem ser preenchidos!',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
  }

  const OnCancel = () => {
    setPlatformId(0)
    setDegreeId(0)
    setSchoolId(0)
    setIsSearching(false)
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          Associação de escola e plataforma
        </div>
        <div className="card-body">
          <div className="row pt-5 pb-5">
            <div className="col-12 col-md-3 mb-3">
              <div className="form-group">
                {!isLoading ? (
                  <Select
                    type="primary"
                    onChange={handleSelectPlatformChange}
                    options={platformOptions}
                    placeholder="Selecione a plataforma"
                    isDisabled={!client.id || !platforms.length || isSearching}
                    isSearchable
                    required
                  />
                ) : (
                  <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                    <Skeleton count={1} height={36} />
                  </SkeletonTheme>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3 mb-3">
              <div className="form-group">
                {!isLoading ? (
                  <Select
                    type="primary"
                    onChange={handleSelectDegreeChange}
                    options={degreeOptions}
                    placeholder="Selecione a série"
                    isDisabled={!client.id || !degrees.length || isSearching}
                    isSearchable
                    required
                  />
                ) : (
                  <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                    <Skeleton count={1} height={36} />
                  </SkeletonTheme>
                )}
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-3 mb-3">
              <div className="form-group">
                {!isLoading ? (
                  <Select
                    type="primary"
                    onChange={handleSelectSchoolChange}
                    options={schoolOptions}
                    placeholder="Selecione a escola"
                    isDisabled={!client.id || !schools.length || isSearching}
                    isSearchable
                    required
                  />
                ) : (
                  <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                    <Skeleton count={1} height={36} />
                  </SkeletonTheme>
                )}
              </div>
            </div>
            <div className="col-12 col-md-1 mt-1 mb-3">
              <Buttons className="form-group">
                <button className="btn btn-primary"
                  disabled={!client.id || !isSearching && (platformId > 0 && degreeId > 0 && schoolId > 0) ? false : true}
                  onClick={onSearch}
                >Associar</button>
              </Buttons>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssociationSchoolPlatforms
