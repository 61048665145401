/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'

export const all = (): Promise<any> => axios.get('/questions/question-categories')

export const find = (id: number): Promise<any> => axios.get(`/questions/question-categories/${id}`)

export const create = (questionCategory: any): Promise<any> => axios.post('/questions/question-categories', questionCategory)

export const upload = (questionCategories: any): Promise<any> => axios.post('/questions/question-categories/upload', questionCategories)

export const update = (id: number, questionCategory: any): Promise<any> => axios.put(`/questions/question-categories/${id}`, questionCategory)

export const destroy = (id: number): Promise<any> => axios.delete(`/questions/question-categories/${id}`)
