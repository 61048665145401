import React, { useContext } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { Answers } from '@screens/PossibleErrors/PossibleErrors.styles'
import { getCssProperty } from '@helpers/Theme'
import PossibleErrorContext from '@contexts/PossibleError'
import * as $PossibleError from '@services/PossibleError'
import IPossibleError from '@interfaces/IPossibleError'

const ValidatePossibleError: React.FC<any> = () => {
  const { showModal, setShowModal, setIsLoading,
    answers, setAnswers, line, setLine, possibleErrors,
    isLoading, possibleError, position, amount, setPossibleError, setPosition } = useContext(PossibleErrorContext)

  const SweetAlert = withReactContent(Swal)

  const discard = (possibleErrorId: number) => SweetAlert.fire({
    title: 'Deseja prosseguir?',
    text: 'Deseja prosseguir com o descarte deste cartão? Você não poderá voltar atrás dessa decisão.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: getCssProperty('--secondary-color'),
    cancelButtonColor: getCssProperty('--primary-color'),
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((result: any) => {
    if (result.value) {
      setIsLoading(true)

      $PossibleError.remove(possibleErrorId).then(() => {
        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Descartado com sucesso!',
          icon: 'success'
        })
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro!',
          text: e.message ?? 'Erro ao excluir possível erro!',
          icon: 'error'
        })
      }).finally(() => {
        setIsLoading(false)
        SweetAlert.hideLoading()
      })
    }
  })

  const sendAnswers = (possibleErrorId: number) => SweetAlert.fire({
    title: 'Deseja prosseguir?',
    text: 'Deseja prosseguir com a validação deste cartão? Você não poderá voltar atrás dessa decisão.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: getCssProperty('--secondary-color'),
    cancelButtonColor: getCssProperty('--primary-color'),
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((result: any) => {
    if (result.value) {
      setIsLoading(true)

      $PossibleError.update(possibleErrorId, answers.map((a: any) => { return a.response }).toString().replaceAll(',', '')).then(() => {
        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Atualizado com sucesso!',
          icon: 'success'
        })
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro!',
          text: e.message ?? 'Erro ao atualizar possível erro!',
          icon: 'error'
        })
      }).finally(() => {
        setIsLoading(false)
        SweetAlert.hideLoading()
      })
    }
  })

  const inputAnswer = (index: number, answer: string) => {
    if (line < answers.length)
      setLine(index+1)

    setAnswers([
      ...answers.slice(0, (index-1)),
      {
        position: index,
        response: answer
      },
      ...answers.slice(index),
    ])

    const element = window.document.getElementById('answers')
    const scrollPercentage = (index-1) * ((element?.scrollHeight ?? 0)/answers.length)
    element?.scrollTo({top: scrollPercentage, behavior: 'smooth'})
  }

  const handleKeyPress = (e: any, position: number) => {
    if (e.key == 'ArrowUp' && line > 1)
      setLine(position-1)

    if (e.key == 'ArrowDown' && line < answers.length)
      setLine(position+1)

    if (e.key.toUpperCase() == 'A' || e.key.toUpperCase() == 'B' ||
          e.key.toUpperCase() == 'C' || e.key.toUpperCase() == 'D' ||
          e.key.toUpperCase() == 'E' || e.key.toUpperCase() == '_' || e.key.toUpperCase() == '*')
      inputAnswer(position, e.key.toUpperCase().toString())
  }

  const alterModal = (possibleErrors: IPossibleError[], position: number) => {
    setIsLoading(true)
    setPossibleError(possibleErrors[position-1])
    setAnswers([])
    setPosition(position+1)

    const an: any = []
    Array.from(possibleError.answers).map((answer: any, position: number) => {
      an.push({
        position: position+1,
        response: answer,
      })
    })

    setAnswers(an)
    setLine(1)
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
    setShowModal(true)
  }

  return (
    <>
      { possibleError?.id && (
        <Modal
          backdrop="static"
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Visualizar possível erro</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center">
            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <img className="img-fluid" src={possibleError?.imagePath} />
              </div>
              { !isLoading ? (
                <div className="col-12 col-md-5">
                  <div className="d-flex justify-content-between">
                    <button className="btn btn-outline-danger pe-3 ps-3 me-3" onClick={() => discard(possibleError.id)}>Descartar</button>
                    <button className="btn btn-primary pe-3 ps-3" onClick={() => sendAnswers(possibleError.id)}>Validar</button>
                  </div>
                  <Answers className="mt-3" id="answers">
                    {answers.map(((answer: any, indexAnswer: number) => (
                      <>
                        <div className="row mb-3"
                          key={possibleError.id+'-'+indexAnswer}
                          onKeyUp={(e: any) => handleKeyPress(e, line)}
                        >
                          <div className="col-2 col-md-2"
                            onClick={() => setLine(answer.position)}
                          >
                            <button
                              className={'btn btn-sm btn pe-2 ps-2 me-1 ' +
                              (answer.position === line ? 'btn-danger' : 'btn-outline-primary')}
                            >
                              {answer.position + possibleError.offset}
                            </button>
                          </div>
                          <div className="col-10 col-md-9"
                            onClick={(e: any) => inputAnswer(answer.position, e.target.value)}
                          >
                            <button value="A" className={'btn btn-sm pe-2 ps-2 me-1 btn' + (answer.response == 'A' ? '' : '-outline') + '-primary'}>A</button>
                            <button value="B" className={'btn btn-sm pe-2 ps-2 me-1 btn' + (answer.response == 'B' ? '' : '-outline') + '-primary'}>B</button>
                            <button value="C" className={'btn btn-sm pe-2 ps-2 me-1 btn' + (answer.response == 'C' ? '' : '-outline') + '-primary'}>C</button>
                            <button value="D" className={'btn btn-sm pe-2 ps-2 me-1 btn' + (answer.response == 'D' ? '' : '-outline') + '-primary'}>D</button>
                            { possibleError.numberAlternatives === 5 ?
                              <button value="E" className={'btn btn-sm pe-2 ps-2 me-1 btn' + (answer.response == 'E' ? '' : '-outline') + '-primary'}>E</button>
                              : null }
                            <button value="_" className={'btn btn-sm pe-2 ps-2 me-1 btn' + (answer.response == '_' ? '' : '-outline') + '-primary'} title="Em Branco">_</button>
                            <button value="*" className={'btn btn-sm pe-2 ps-2 me-1 btn' + (answer.response == '*' ? '' : '-outline') + '-primary'} title="Múltiplas marcações">*</button>
                          </div>
                        </div>
                      </>
                    )))}
                  </Answers>
                  <div className="d-flex justify-content-start mt-4">
                    <button className="btn btn-primary pe-3 ps-3" onClick={() => sendAnswers(possibleError.id)}>Corrigir</button>
                  </div>
                </div>
              ) : (
                <div className="col">
                  <div className="d-flex justify-content-center p-3">
                    <Spinner animation="border" variant="primary" />
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <button
              className="btn btn-sm btn-primary pe-3 ps-3"
              disabled={position <= 1 ? true : false || isLoading}
              onClick={() => alterModal(possibleErrors, position-3)}
            ><AiOutlineLeft /></button>
            <div>{position}/{amount}</div>
            <button
              className="btn btn-sm btn-primary pe-3 ps-3"
              disabled={position === amount ? true : false || isLoading}
              onClick={() => alterModal(possibleErrors, position)}
            ><AiOutlineRight /></button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default ValidatePossibleError
