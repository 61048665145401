import IChartStyle from '@interfaces/IChartStyle'
import axios from 'axios'

export const all = (): Promise<any> => axios.get('/reportments/chart-styles')

export const store = (data: IChartStyle): Promise<any> => axios.post('/reportments/chart-styles', data)

export const destroy = (chartId: number): Promise<any> => axios.delete(`/reportments/chart-styles/${chartId}`)

export const update = (chartId: number, data: IChartStyle): Promise<any> => axios.put(`/reportments/chart-styles/${chartId}`, data)
