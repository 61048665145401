import React, { useContext, useEffect, useState } from 'react'
import RegistrationContext from '@contexts/Registration/Registration'
import IEntityValidationError from '@interfaces/IEntityValidationError'
import { Alert, Modal, Spinner } from 'react-bootstrap'
import Errors from './Errors/Errors'
import Resolve from './Resolve/Resolve'
import * as $EntityRegistration from '@services/Users/Registration'

type IValidationProps = {
  hide: () => void;
}

const Validation: React.FC<any> = ({ hide }: IValidationProps) => {
  const { entity, token, entityLabels } = useContext(RegistrationContext)

  const [ errors, setErrors ] = useState<IEntityValidationError[]>([])
  const [ error, setError ] = useState<IEntityValidationError|null>()
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  useEffect(() => {
    if (entity?.id) {
      $EntityRegistration.errors(token, entity.id).then(({ data }: any) => {
        setErrors(data)
      }).finally(() => setIsLoading(false))
    }
  }, [token, entity])

  const onSaved = (errors: IEntityValidationError[]) => {
    setErrors(errors)
    setError(null)

    if (!errors.length) {
      hide()
    }
  }

  const onCanceled = () => setError(null)

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Verificação de erros</Modal.Title>
      </Modal.Header>

      {!isLoading ? (
        <>
          {error == null ? (
            <>
              <Modal.Body className="pb-0">
                {errors.length ? (errors.length > 1 ? (
                  <Alert variant="danger" className="text-center mb-0">Você possui mais de um erro associado a esse {entityLabels[1].toLowerCase()}. Verifique os itens na lista de correções.</Alert>
                ) : (
                  <Alert variant="warning" className="text-center mb-0">Verifique o erro associado a esse {entityLabels[1].toLowerCase()}.</Alert>
                )) : (
                  <Alert variant="success" className="text-center mb-0">Nenhum erro associado a esse {entityLabels[1].toLowerCase()}.</Alert>
                )}
              </Modal.Body>

              <Errors
                errors={errors}
                onClick={(error: IEntityValidationError) => setError(error)}
              />
            </>
          ) : (
            <Resolve
              error={error}
              onSaved={onSaved}
              onCanceled={onCanceled}
            />
          )}
        </>
      ) : (
        <Modal.Body>
          <div className="d-flex justify-content-center p-3 bg-light">
            <Spinner animation="border" variant="primary" />
          </div>
        </Modal.Body>
      )}
    </>
  )
}

export default Validation
