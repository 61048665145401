import moment from 'moment'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const columns: any[] = [
  {
    Header: 'Simulado',
    accessor: 'assessmentId',
    Cell: ({ row: { original } }: any) => {
      return original.assessmentId != null && original.assessmentId != 0 ?
        (original.assessmentId + ' - ' + original.assessmentName) : 'Não informado'
    },
  },
  {
    Header: 'Escola',
    accessor: 'schoolId',
    Cell: ({ row: { original } }: any) => {
      return original.schoolId != null && original.schoolId != 0 ?
        (original.schoolId + ' - ' + original.schoolName) : 'Não informado'
    },
  },
  {
    Header: 'Descrição',
    accessor: 'description',
  },
  {
    Header: 'Série',
    accessor: 'degreeId',
    Cell: ({ row: { original } }: any) => {
      return original.degreeId != null && original.degreeId != 0 ?
        (original.degreeName) : 'Não informado'
    },
  },
  {
    Header: 'Cliente',
    accessor: 'clientId',
  },
  {
    Header: 'Network',
    accessor: 'networkId',
    Cell: ({ row: { original } }: any) => {
      return original.networkId != null && original.networkId != 0 ?
        (original.networkId) : 'Não informado'
    }
  },
  {
    Header: 'Início',
    accessor: 'start',
    Cell: (props: { value: Date }) => {
      return props.value ? moment(props.value).format('DD/MM/YYYY HH:mm') : 'Não informado'
    },
  },
  {
    Header: 'Fim',
    accessor: 'end',
    Cell: (props: { value: Date }) => {
      return props.value ? moment(props.value).format('DD/MM/YYYY HH:mm') : 'Não informado'
    },
  },
  {
    Header: 'Incluir',
    accessor: 'include',
    Cell: ({ row: { original } }: any) => {
      return original.include ? 'Sim' : 'Não'
    }
  },
]

export default columns
