import React, { useEffect, useState } from 'react'
import IUser from '@interfaces/IUser'
import * as $Teacher from '@services/Users/Teacher'
import { Link, useParams } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const TeachersEdit: React.FC<any> = () => {
  const { id: teacherId, schoolId }: any = useParams()

  const [ teacher, setTeacher ] = useState<IUser>({} as IUser)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    $Teacher.find(schoolId, teacherId).then(({ data }) => setTeacher(data)).finally(() => setIsLoading(false))
  }, [schoolId, teacherId])

  const handleUserChange = (e: any) => {
    const { name, value } = e.target

    setTeacher({
      ...teacher,
      [name]: value,
    })
  }

  const update = () => {
    setIsLoading(true)

    SweetAlert.showLoading()

    $Teacher.update(schoolId, teacher.id as number, {
      name: teacher.name,
      email: teacher.email,
      nickname: teacher?.nickname,
    } as IUser).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Professor atualizado com sucesso!',
        icon: 'success'
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao atualizar o professor!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/professores" className="btn btn-outline-primary">Voltar</Link>
      </div>

      <div className="card">
        <div className="card-header">
          Editar professor ({teacher?.nickname})
        </div>

        <div className="card-body pb-0">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="nickname">Apelido</label>
                <input type="text" name="nickname" className="form-control" id="nickname" placeholder="Apelido" defaultValue={teacher?.nickname} onChange={handleUserChange} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="name">Nome</label>
                <input type="text" name="name" className="form-control" id="name" placeholder="Nome" defaultValue={teacher?.name} onChange={handleUserChange} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="email">E-mail</label>
                <input type="email" name="email" className="form-control" id="email" placeholder="E-mail" defaultValue={teacher?.email} onChange={handleUserChange} />
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <button className="btn btn-primary" onClick={update} disabled={isLoading}>Atualizar</button>
        </div>
      </div>
    </>
  )
}

export default TeachersEdit
