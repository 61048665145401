import styled from 'styled-components'

export const CollapseButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  box-shadow: var(--box-shadow);
  background-color: var(--primary-color);
  transition: background-color 0.2s ease;
  position: absolute;
  z-index: 9;
  right: -16px;
  border: 0;
  top: 18px;

  &:hover {
    background-color: var(--secondary-color);
  }

  > svg {
    height: 8px;
  }
`

export const SidebarContainer = styled.div`
  padding: 0;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  position: relative;
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;

  @media (min-width: 768px) {
    position: relative;

    &.collapsed > .sidebar-sticky > .menu-item > button {
      > .btn-inner {
        justify-content: center;

        > .icon {
          margin-right: 0;
        }

        > .title {
          width: 0;
          opacity: 0;
        }
      }

      &::after {
        height: 3px;
        bottom: 0;
      }

      &:hover::after,
      &.is-active::after {
        opacity: 1;
      }
    }

    &:not(.collapsed) > .sidebar-sticky > .menu-item > button {
      > .btn-inner {
        padding: 0 calc(var(--spacing-padding) / 2);
      }

      &:hover::after,
      &.is-active::after {
        opacity: 0.06;
      }
    }
  }
`

export const Aside = styled.aside`
  display: flex;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: column;
    position: sticky;
    top: 100px;
  }

  @media (max-width: 767px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  &::-webkit-scrollbar {
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 3px;
    border: 0px solid #ffffff;
  }
`
