import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import ReportmentContext from '@contexts/Reportment'
import * as $Client from '@services/Client'
import * as $School from '@services/School'
import * as $Reportment from '@services/Reportment'
import { Modal } from 'react-bootstrap'
import IClient from '@interfaces/IClient'
import ISchool from '@interfaces/ISchool'
import Select from '@components/Select/Select'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import { usePagination, useSortBy, useTable } from 'react-table'
import Pagination from '@components/Pagination/Pagination'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { exportXLSX } from '@helpers/Utils'
import { BiHelpCircle } from 'react-icons/bi'
import ReactTooltip from 'react-tooltip'

const Cell = (props: any) => {
  if (Array.isArray(props.value)) {
    return (props.value as any[]).length
  }

  return props.value
}

const ReportmentPreview: React.FC<any> = () => {
  const { data, setData, clients, reportment, setReportment, showPreviewModal, setShowPreviewModal } = useContext(ReportmentContext)

  const [ showPreview, setShowPreview ] = useState<boolean>(false)
  const [ , setIsLoading ] = useState<boolean>(true)
  const [ columns, setColumns ] = useState<any[]>([] as any)
  const [ schools, setSchools ] = useState<any[]>([])
  const [ client, setClient ] = useState<any>({
    id: 0,
    name: '',
  } as any)
  const [ school, setSchool ] = useState<any>({
    id: null,
    name: null,
  } as any)
  const [ userId, setUserId ] = useState<number>(0)

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (client.id > 0) {
      setIsLoading(true)
      setShowPreview(false)
      setColumns([])
      setData([])
      setSchools([])
      setSchool({
        id: null,
        name: null,
      })
      $School.findByClient(client.id).then(({ data }: any) => setSchools(data)).finally(() => setIsLoading(false))
    }
  }, [client, setData])

  const executeQuery = () => new Promise<any[]>((resolve, reject) => {
    setIsLoading(true)

    const formData = new FormData()

    formData.append('reportmentId', reportment.id.toString())
    formData.append('query', reportment.query ?? '')
    formData.append('clientId', client.id?.toString() ?? '')
    formData.append('schoolId', school.id?.toString() ?? '')
    formData.append('userId', userId?.toString() ?? '')
    formData.append('isFree', reportment.isFree ? 'true' : 'false')

    $Reportment.preview(formData).then(({ data }: any) => resolve(data))
      .catch(reject)
      .finally(() => setIsLoading(false))
  })

  const doPreview = () => {
    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Carregando pré-visualização...',
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    executeQuery().then((data: any[]) => {
      if (data.length > 0) {
        const columns = data[0].map((value: string, index: number) => ({
          Header: value,
          accessor: index.toString(),
          Cell,
        }))

        data.shift()

        setData(data)
        setColumns(columns)
        setShowPreview(true)

        SweetAlert.close()
      } else {
        SweetAlert.fire({
          title: 'Ops...',
          text: 'Nenhum registro encontrado.',
          icon: 'info',
          confirmButtonText: 'OK',
        })
      }
    }).catch(() => {
      setShowPreview(false)
      SweetAlert.fire({
        title: 'Erro',
        text: 'Ocorreu um erro ao executar a consulta. Verifique a query e tente novamente.',
        icon: 'error',
      })
    })
  }

  const doExport = () => {
    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Gerando exportação do relatório...',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    executeQuery().then((data: any[]) => {
      exportXLSX(data, 'Relatório', `${reportment.name}.xlsx`)
      SweetAlert.close()
    }).catch(() => {
      setShowPreview(false)
      SweetAlert.fire({
        title: 'Erro',
        text: 'Ocorreu um erro ao executar a consulta. Verifique a query e tente novamente.',
        icon: 'error',
      })
    })
  }

  const clientOptions = useMemo(() => clients.map((client: IClient) => ({
    value: client.id,
    label: client.name,
  })), [clients])

  const schoolOptions = useMemo(() => schools.map((school: ISchool) => ({
    value: school.id,
    label: school.fantasyName,
  })), [schools])

  const handleClientIdChange = (data: any) => setClient({
    id: data?.value ?? 0,
    name: data?.label ?? '',
  })

  const handleSchoolIdChange = (data: any) => setSchool({
    id: data?.value ?? 0,
    name: data?.label ?? '',
  })

  const isEnabled = useMemo(() => {
    return reportment.isFree || (!reportment.isFree && (client.id > 0 || school.id > 0))
  }, [client.id, reportment.isFree, school.id])

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  return (
    <Modal
      show={showPreviewModal}
      onHide={() => setShowPreviewModal(false)}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Pré-visualização de relatório</Modal.Title>
      </Modal.Header>

      {showPreviewModal && (
        <Modal.Body className="border-bottom pb-0">
          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="clientId">Cliente:</label>
                {clients.length ? (
                  <Select
                    type="primary"
                    onChange={handleClientIdChange}
                    options={clientOptions}
                    placeholder="Selecione um cliente"
                    isDisabled={!clients.length}
                    isClearable
                    isSearchable
                    required
                  />
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="clientId">Escola:</label>
                <Select
                  type="primary"
                  options={schoolOptions}
                  onChange={handleSchoolIdChange}
                  placeholder="Selecione a escola"
                  isDisabled={!client.id || !schools.length}
                  isClearable
                  isSearchable
                  required
                />
              </div>
            </div>

            <div className="col-12 col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="userId">ID usuário:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setUserId(Number(e.target.value))}
                  disabled={!client.id && !schools.length}
                />
              </div>
            </div>

            <div className="col-12 col-md-6 mb-3">
              <div className="form-check form-switch p-0">
                <label className="form-check-label d-block" htmlFor="isFree">
                  <span className="me-1">Execução de query livre?</span>
                  <BiHelpCircle data-tip data-for="helpCircle" />
                  <ReactTooltip id="helpCircle" place="top" effect="solid">
                    Permitir a execução da query livremente sem a concatenação de IDs.
                  </ReactTooltip>
                </label>

                <select
                  id="isFree"
                  name="isFree"
                  className="form-control"
                  onChange={e => setReportment({
                    ...reportment,
                    isFree: Number(e.target.value) === 1,
                  })}
                  defaultValue={reportment.isFree ? 1 : 0}
                >
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
      )}

      {showPreview && (
        <Modal.Body className="p-0">
          <div className="table-responsive">
            <table {...getTableProps()} className="table table-default mb-0">
              <thead>
                {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                    {headerGroup.headers.map((column: any, columnIndex: number) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIndex}>
                        {column.render('Header')}
                        <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row: any, rowIndex: number) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} key={rowIndex}>
                      {row.cells.map((cell: any, index: number) => {
                        return (
                          <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      )}

      {pageCount > 1 && (
        <Modal.Body className="border-bottom">
          <Pagination
            page={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            previousPage={previousPage}
            onClick={(page: number) => gotoPage(page)}
          />
        </Modal.Body>
      )}

      <Modal.Body className="d-flex justify-content-end">
        <button className="btn btn-primary pe-3 ps-3 me-2" disabled={!isEnabled} onClick={doPreview}>Executar pré-visualização</button>
        <button className="btn btn-outline-primary pe-3 ps-3" disabled={!isEnabled} onClick={doExport}>Exportar XLSX</button>
      </Modal.Body>
    </Modal>
  )
}

export default ReportmentPreview
