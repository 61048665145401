import axios from 'axios'

export const all = (): Promise<any> => {
  return axios.get('/reportments/filters')
}

export const create = (filter: FormData): Promise<any> => {
  return axios.post('/reportments/filters', filter, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const update = (filterId: number, filter: FormData): Promise<any> => {
  return axios.put(`/reportments/filters/${filterId}`, filter, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const destroy = (id: number): Promise<any> => {
  return axios.delete(`/reportments/filters/${id}`)
}
