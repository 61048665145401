import React, { ReactNode } from 'react'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import { HeaderGroup, TableInstance } from 'react-table'
import { CSSProperties } from 'styled-components'

type CustomHeader<D extends object> = HeaderGroup<D> & {
  style: CSSProperties | undefined
}

type CustomCell<D extends object> = TableInstance<D>['rows'][0]['cells'][0] & {
  style: CSSProperties | undefined
}

type Props<D extends object> = {
  isLoading: boolean
  placeholder?: ReactNode | string
} & {
  getTableProps: TableInstance<D>['getTableProps']
  getTableBodyProps: TableInstance<D>['getTableBodyProps']
  getTableHeadProps: TableInstance<D>['getTableHeadProps']
  headerGroups: TableInstance<D>['headerGroups']
  prepareRow: TableInstance<D>['prepareRow']
  page?: TableInstance<D>['page']
  rows?: TableInstance<D>['rows']
}

const Table = <D extends object>({ page, rows, isLoading, placeholder, ...table }: Props<D>) : JSX.Element => {
  const [ { headers } ] = table.headerGroups

  const data = page || rows || []

  return (
    <table {...table.getTableProps()} className="table table-default mb-0">
      <thead>
        {table.headerGroups.map(headerGroup => {
          const { key, ...props } = headerGroup.getHeaderGroupProps()

          return (
            <tr {...props} key={key}>
              {headerGroup.headers.map(header => {
                const { style } = header as CustomHeader<D>

                return (
                  <th {...header.getHeaderProps(header.getSortByToggleProps())} style={{ ...header.getHeaderProps().style, ...style }} key={`th-${header.id}`}>
                    {header.render('Header')}

                    {header.canSort && (
                      header.isSortedDesc ? (
                        <FaSortDown className="ms-1" size={9} color="#00b7ff" />
                      ) : header.isSorted ? (
                        <FaSortUp className="ms-1" size={9} color="#00b7ff" />
                      ) : (
                        <FaSort className="ms-1" size={9} color="#707ca1" />
                      )
                    )}
                  </th>
                )
              })}
            </tr>
          )
        })}
      </thead>

      <tbody {...table.getTableBodyProps()}>
        {data.length > 0 ? (
          data.map(row => {
            table.prepareRow(row)

            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell, index) => {
                  const { style } = cell as CustomCell<D>

                  return (
                    <td {...cell.getCellProps()} style={{ ...cell.getCellProps().style, ...style }} align="left" valign="middle" key={`tr-${row.id}-td-${index}`}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan={headers.length} className="text-center">
              {isLoading ? (
                'Carregando...'
              ) : (
                placeholder || 'Nada a ser exibido.'
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default Table
