/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const columns: any[] = [
  {
    Header: 'ID',
    accessor: 'IdRedacao',
  },
  {
    Header: 'ID RedMil',
    accessor: 'IdRedMil',
  },
  {
    Header: 'Barcode',
    accessor: 'barcode',
  },
  {
    Header: 'Tema RD',
    accessor: 'compostionThemaName',
    Cell: ({ row: { original } }: any) => {
      return original.compositionThemeID != null ?
        (original.compositionThemeID + ' - ' + original.compostionThemaName) : 'Não informado'
    },
  },
  {
    Header: 'Aluno',
    accessor: 'IdAluno',
    Cell: ({ row: { original } }: any) => {
      return original.IdAluno != null ?
        (original.IdAluno + ' - ' + original.NomeAluno) : 'Não informado'
    },
  },
  {
    Header: 'Escola',
    accessor: 'SchoolIdBarcode',
    Cell: ({ row: { original } }: any) => {
      return original.SchoolIdBarcode != null ?
        (original.SchoolIdBarcode + ' - ' + original.fantasyName) : 'Não informado'
    },
  },
  {
    Header: 'Entregue',
    accessor: 'Entregue',
    Cell: (props: { value: boolean }) => {
      return props.value ? 'Sim' : 'Não'
    },
  },
  {
    Header: 'Nota liberada',
    accessor: 'NotaLiberada',
    Cell: (props: { value: boolean }) => {
      return props.value ? 'Sim' : 'Não'
    },
  },
  {
    Header: 'Data Criada',
    accessor: 'DataCriada',
    Cell: (props: { value: Date }) => {
      return props.value ? new Date(props.value).toLocaleString() : 'Não possui'
    },
  },
  {
    Header: 'Data Envio',
    accessor: 'DataEnvio',
    Cell: (props: { value: Date }) => {
      return props.value ? new Date(props.value).toLocaleString() : 'Não possui'
    },
  },
  {
    Header: 'Data Chegada',
    accessor: 'DataChegada',
    Cell: (props: { value: Date }) => {
      return props.value ? new Date(props.value).toLocaleString() : 'Não possui'
    },
  },
]

export default columns
