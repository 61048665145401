import StudentRegistrationTypeColumns from '@data/StudentRegistrationTypeColumns'
import TeacherRegistrationTypeColumns from '@data/TeacherRegistrationTypeColumns'
import RevisorRegistrationTypeColumns from '@data/RevisorRegistrationTypeColumns'
import ManagerRegistrationTypeColumns from '@data/ManagerRegistrationTypeColumns'
import RegistrationTypeColumn from '@interfaces/IRegistrationTypeColumn'
import RoleEnum from '@enums/Role.enum'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'

class RegistrationTypeAction {
  private role: RoleEnum

  public constructor(role: RoleEnum) {
    this.role = role
  }

  public getColumns() : RegistrationTypeColumn[] {
    switch (this.role) {
    case RoleEnum.Student:
      return StudentRegistrationTypeColumns

    case RoleEnum.Teacher:
      return TeacherRegistrationTypeColumns

    case RoleEnum.Revisor:
      return RevisorRegistrationTypeColumns

    case RoleEnum.Manager:
      return ManagerRegistrationTypeColumns

    default:
      return [] as RegistrationTypeColumn[]
    }
  }

  public getLabels() : string[] {
    return this.getColumns().map(column => column.label)
  }

  public getEntities(rows: string[][]) : IRegistrationEntity[] {
    const entities: IRegistrationEntity[] = []

    const columns = this.getColumns()

    rows.forEach((row: string[], index: number) => {
      if (!this.isRowEmpty(row)) {
        const entity: any = {
          index,
        }

        columns.forEach((column: RegistrationTypeColumn, index: number) => {
          const value = row[index] ?? null
          entity[column.key] = column.worker?.(value) ?? value
        })

        entities.push(entity as IRegistrationEntity)
      }
    })

    return entities
  }

  public isValid(header: string[]) : boolean {
    const labels = this.getLabels()

    return header.every((label: string, index: number) => label === labels[index])
  }

  public isRowEmpty(row: string[]) : boolean {
    return row.every((value: string) => !value)
  }
}

export default RegistrationTypeAction
