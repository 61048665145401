import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translations from './locales'

const i18nConfig = {
  resources: translations,
  defaultNS: 'translations',
  fallbackLng: 'pt-BR',
}

i18n
  .use(initReactI18next)
  .init(i18nConfig)

export default i18n
