import IUser from '@interfaces/IUser'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

const useUserColumns = (redirectTo: (user: IUser) => void): any => {
  return useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
      style: {
        width: 60,
      },
    },
    {
      Header: 'Gestão ID',
      accessor: 'gestaoId',
    },
    {
      Header: 'Cliente',
      accessor: 'clientName',
    },
    {
      Header: 'RE',
      accessor: 'usercode',
      style: {
        width: 100,
      },
    },
    {
      Header: 'Nome',
      accessor: 'name',
    },
    {
      Header: 'E-mail',
      accessor: 'email',
    },
    {
      Header: 'Qtd. Escolas',
      accessor: 'schoolsCount',
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: ({ row: { original: user } }: any) => user.active ? 'Ativo' : 'Inativo',
    },
    {
      Header: 'Ações',
      disableSortBy: true,
      Cell: ({ row: { original: user } }: any) => (
        <div className="d-flex justify-content-end gap-3">
          {user.clientName == 'Ânima Educação' && (
            <button className="btn btn-sm ps-3 pe-3 btn-primary" onClick={() => redirectTo(user)}>
              Simular Ânima
            </button>
          )}

          <Link to={`/usuarios/${user.id}/editar`} className="btn btn-sm ps-3 pe-3 btn-primary">Editar</Link>
        </div>
      ),
      style: {
        width: 160,
        textAlign: 'right',
      },
    },
  ], [redirectTo])
}

export default useUserColumns
