import EntityValidationErrorEnum from '@enums/EntityValidationError.enum'
import RegistrationTypeColumn from '@interfaces/IRegistrationTypeColumn'

const columns: RegistrationTypeColumn[] = [
  {
    label: 'ID Escola',
    key: 'schoolId',
    required: true,
    type: 'schoolId',
    errors: [
      EntityValidationErrorEnum.INVALID_SCHOOL_ID,
    ],
    worker: (value: string) : string|number|null => {
      return value ? parseInt(value) : value
    },
  },
  {
    label: 'Nome',
    key: 'name',
    required: true,
    type: 'string',
    errors: [
      EntityValidationErrorEnum.NAME_REQUIRED,
    ],
  },
  {
    label: 'Apelido',
    key: 'nickname',
    required: true,
    type: 'string',
    errors: [
      EntityValidationErrorEnum.NICKNAME_REQUIRED,
      EntityValidationErrorEnum.DUPLICATED_NICKNAME_FOUND,
      EntityValidationErrorEnum.NICKNAME_DUPLICATED_FIELD,
    ],
  },
  {
    label: 'E-mail',
    key: 'email',
    required: true,
    type: 'email',
    errors: [
      EntityValidationErrorEnum.EMAIL_INVALID,
      EntityValidationErrorEnum.DUPLICATED_EMAIL_FOUND,
      EntityValidationErrorEnum.EMAIL_EXISTS,
      EntityValidationErrorEnum.EMAIL_DUPLICATED_FIELD,
    ],
    worker: (value: string) : string|null => {
      return value ? String(value).trim() : value
    },
  },
  {
    label: 'Senha (Não obrigatório)',
    key: 'password',
    required: false,
    type: 'password',
    worker: (value: string) : string|null => {
      return value ? String(value) : value
    },
  },
]

export default columns
