import React, { useContext } from 'react'
import ReportmentContext from '@contexts/Reportment'
import IChart from '@interfaces/IChart'
import { random, tryParseJSONObject } from '@helpers/Utils'
import { Card } from './Charts.styles'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Chart from '@services/Chart'

const Charts: React.FC<any> = () => {
  const { reportment, charts, setCharts, setChart } = useContext(ReportmentContext)

  const SweetAlert = withReactContent(Swal)

  const onSelect = (chart: IChart) => setChart({
    ...chart,
    slice: tryParseJSONObject(chart.slice) ?? {},
    options: tryParseJSONObject(chart.options) ?? {},
    formats: tryParseJSONObject(chart.formats) ?? [
      {
        name: '',
        decimalSeparator: ',',
        decimalPlaces: 2,
      },
    ],
  })

  const register = () => setChart({
    id: -random(),
    name: '',
    slice: {
      rows: [],
      columns: []
    },
    options: {
      chart: {
        type: 'column_line',
      },
    },
    formats: [
      {
        name: '',
        decimalSeparator: ',',
        decimalPlaces: 2,
      },
    ],
    clientId: null,
    schoolId: null,
    userId: null,
  } as IChart)

  const onDelete = (chart: IChart) => {
    SweetAlert.fire({
      title: 'Atenção!',
      text: 'Deseja realmente excluir este gráfico?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(({ isConfirmed }) => {
      if (!isConfirmed)
        return

      $Chart.destroy(reportment.id, chart.id).then(() => {
        setCharts(charts.filter(({ id }) => id !== chart.id))

        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Gráfico excluído com sucesso.',
          icon: 'success',
        })
      }).catch(() => {
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao excluir o gráfico.',
          icon: 'error',
        })
      })
    })
  }

  return (
    <Card className="card mb-3">
      <div className="card-header">Gráficos</div>

      <div className="card-body p-0">
        {charts.length > 0 ? (
          <table className="table table-default mb-0">
            <thead>
              <tr>
                <td width="60">ID</td>
                <td>Gráfico</td>
                <td width="300">Ações</td>
              </tr>
            </thead>

            <tbody>
              {charts.map(chart => (
                <tr key={chart.id}>
                  <td>{chart.id}</td>
                  <td>{chart.name}</td>
                  <td align="right">
                    <button type="button" className="btn btn-sm btn-primary btn-sm me-3" onClick={() => onSelect(chart)}>
                      Visualizar
                    </button>
                    <button type="button" className="btn btn-sm btn-outline-danger btn-sm" onClick={() => onDelete(chart)}>
                      Excluir
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center text-muted p-3">Nenhum relatório dinâmico adicionado ainda.</div>
        )}
      </div>

      <div className="card-footer d-flex justify-content-end">
        <button type="button" className="btn btn-primary" onClick={register}>Adicionar relatório dinâmico</button>
      </div>
    </Card>
  )
}

export default Charts
