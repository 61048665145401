import React, { useContext, useState } from 'react'
import IPlatform from '@interfaces/IPlatform'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import StudentAndProductsContext from '@contexts/StudentAndProducts'
import Disassociation from './Disassociation/Disassociation'
import IPlatformAssociation from '@interfaces/PlatformAssociation/IPlatformAssociation'
import { getCssProperty } from '@helpers/Theme'
import Association from './Association/Association'

const StudentAndProducts: React.FC<any> = () => {
  const { student, isLoading, platforms, associations, setSelectedAssociation, setSelectedDisassociation } = useContext(StudentAndProductsContext)

  const [ showAssociation, setShowAssociation ] = useState<boolean>(false)
  const [ showDisassociation, setShowDisassociation ] = useState<boolean>(false)

  const handleShowAssociation = () => {
    setShowAssociation(!showAssociation)
    setShowDisassociation(false)
    setSelectedDisassociation([])
  }

  const handleShowDisassociation = () => {
    setShowDisassociation(!showDisassociation)
    setShowAssociation(false)
    setSelectedAssociation([])
  }

  return (
    <div className="row">
      <div className={!showAssociation && !showDisassociation ? 'col-12' : 'col-12 col-md-9'}>
        <div
          className="card mb-4"
          style={{
            position: 'sticky',
            top: 0
          }}
        >
          <div className="card-header d-flex">
            <button
              className={'btn me-2 btn' + (showAssociation ? '-primary' : '-outline-primary')}
              disabled={isLoading}
              onClick={handleShowAssociation}
            >Associar</button>

            <button
              className={'btn me-2 btn' + (showDisassociation ? '-danger' : '-outline-danger')}
              disabled={isLoading}
              onClick={handleShowDisassociation}
            >Desassociar</button>
          </div>

          <div className="card-body p-0">
            <table className="table table-striped table-dashed mb-0">
              <thead>
                <tr>
                  {platforms?.map((platform: IPlatform) => <th key={platform.id}>{platform.name}</th>)}
                </tr>
              </thead>

              <tbody>
                <tr>
                  {platforms?.map((platform: IPlatform) => {
                    const association = associations?.find((item: IPlatformAssociation) => item.platformId === platform.id && item.classId === student.classId)

                    return (
                      <td key={platform.id}>
                        <span>{association?.platformCount}</span>
                        {association?.platformCount != null && association.platformCount > 0 ? (
                          <AiFillCheckCircle size={18} color={getCssProperty('--primary-color')} />
                        ) : (
                          <AiFillCloseCircle size={18} color="#999" />
                        )}
                      </td>
                    )
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={showAssociation ? 'col-12 col-md-3' : 'd-none'}>
        <Association />
      </div>

      <div className={showDisassociation ? 'col-12 col-md-3' : 'd-none'}>
        <Disassociation />
      </div>
    </div>
  )
}

export default StudentAndProducts
