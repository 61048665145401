import axios from 'axios'

export const all = (params: any = {}): Promise<any> => {
  return axios.get('/networks', {
    params,
  })
}

export const findByClient = (clientId: number): Promise<any> => {
  return axios.get(`networks/${clientId}`)
}
