import React, { useContext, useEffect, useState, useMemo } from 'react'
import IUser from '@interfaces/IUser'
import * as $Student from '@services/Users/Student'
import * as $Degree from '@services/Degree'
import { Link, useNavigate, useParams } from 'react-router-dom'
import IDegree from '@interfaces/IDegree'
import IClass from '@interfaces/IClass'
import axios from 'axios'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import IUserLog from '@interfaces/IUserLog'
import UserLogs from '@components/UserLogs/UserLogs'
import moment, { now } from 'moment'
import StudentAndProducts from '@components/StudentAndProducts/StudentAndProducts'
import { StudentAndProductsProvider } from '@contexts/StudentAndProducts'
import useUpdateEffect from '@hooks/useUpdateEffect'
import AuthContext from '@contexts/Auth'
import Select from '@components/Select/Select'
import ISchool from '@interfaces/ISchool'
import * as $School from '@services/School'

const StudentsEdit: React.FC<any> = () => {
  const { client } = useContext(AuthContext)

  const [ student, setStudent ] = useState<IUser>({} as IUser)
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ classes, setClasses ] = useState<IClass[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ userLogs, setUserLogs ] = useState<IUserLog[]>([])
  const [ schools, setSchools ] = useState<ISchool[]>([])
  const [ currentYear, ] = useState<number>(new Date(now()).getFullYear())

  const { id: studentId, schoolId, year }: any = useParams()

  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)

  useUpdateEffect(() => {
    if (client.id !== student.clientId) {
      navigate('/alunos')
    }
  }, [client])

  useEffect(() => {
    setIsLoading(true)
    axios.all([
      $Student.find(schoolId, studentId, year),
      $Degree.all(schoolId, year),
      $Student.logs(schoolId, studentId),
      $School.all(),
    ]).then(axios.spread(({ data: student }: any, { data: degrees }: any, {data: userLogs }: any, {data: schools }: any) => {
      setStudent(student)
      setDegrees(degrees)
      setUserLogs(userLogs)
      setSchools(schools)
      degrees.map((degree: IDegree) => setClasses(classes => [...classes, ...degree.classes]))
    })).finally(() => setIsLoading(false))
  }, [schoolId, studentId, year])

  const handleInputChange = (e: any) => {
    const { name, value } = e.target

    setStudent({
      ...student,
      [name]: value,
    })
  }

  const options = useMemo(() => schools.map((school: ISchool) => ({
    value: school.id,
    label: school.name,
  })), [schools])

  const handleDegreeChange = (e: any, student: IUser) => {
    const { value: degreeId } = e.target

    setStudent({
      ...student,
      degreeId: parseInt(degreeId),
      classId: classes.find((classItem: IClass) => classItem.degreeId == degreeId)?.id,
    })
  }

  const handleClassChange = (e: any, student: IUser) => {
    const { value: classId } = e.target

    setStudent({
      ...student,
      classId: parseInt(classId),
    })
  }

  const handleSchoolsChange = ({ value: schoolId }: any) => {
    setStudent({
      ...student,
      schoolId: parseInt(schoolId),
    })
  }

  const handleUpdate = () => {
    if (student.year != null && student.year < 2022) {
      SweetAlert.fire({
        title: 'Deseja atualizar o aluno?',
        text: 'Os dados do aluno serão atualizados para o ano de 2022.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, atualizar!',
        cancelButtonText: 'Cancelar',
      }).then((result: any) => {
        if (result.value) {
          update()
        }
      })
    } else {
      update()
    }
  }

  const update = () => {
    setIsLoading(true)

    SweetAlert.showLoading()

    $Student.update(schoolId, student.id as number, student as IUser).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Aluno atualizado com sucesso!',
        icon: 'success'
      })

      $Student.logs(schoolId, studentId).then(({ data }: any) => setUserLogs(data))
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao atualizar o aluno!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 col-md-8">
          <h1>{student?.name ?? student.nickname}</h1>
          Cadastrado em: {moment(student.createdAt).format('D/MM/YYYY [ás] H:mm:ss')}
        </div>

        <div className="col-12 col-md-4 d-flex justify-content-end align-items-end">
          <Link to="/alunos" className="btn btn-outline-primary">Voltar</Link>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-header">
          Editar aluno ({student?.name})
        </div>

        <div className="card-body pb-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="name">Nome</label>
                <input type="text" name="name" className="form-control" id="name" placeholder="Nome" defaultValue={student?.name} onChange={handleInputChange} disabled={isLoading} />
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="nickname">Apelido</label>
                <input type="text" name="nickname" className="form-control" id="nickname" placeholder="Nome" defaultValue={student?.nickname} onChange={handleInputChange} disabled={isLoading} />
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="email">E-mail</label>
                <input type="email" name="email" className="form-control" id="email" placeholder="E-mail" defaultValue={student?.email} onChange={handleInputChange} disabled={isLoading} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="school">Escola</label>
                {schools.length && student?.id ? (
                  <Select
                    type="primary"
                    onChange={handleSchoolsChange}
                    defaultValue={student?.schoolId}
                    options={options}
                    isSearchable={true}
                    placeholder={student?.id ? student?.schoolName : 'Selecione um cliente'}
                  />
                ) : (
                  <input type="text" className="form-control" id="school" defaultValue={student?.schoolName} disabled={isLoading} readOnly />
                )}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="degreeId">Série</label>
                {degrees.length && student?.id ? (
                  <select name="degreeId" value={student.degreeId as number} className="form-control" onChange={(e: any) => handleDegreeChange(e, student)}
                    disabled={year >= currentYear ? false : true}
                  >
                    {degrees.map((degree: any) => (
                      <option value={degree.id} key={degree.id}>{degree.name}</option>
                    ))}
                  </select>
                ) : (
                  <select className="form-control" disabled></select>
                )}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="classId">Turma</label>
                {classes.length && student?.id ? (
                  <select name="classId" value={student.classId} className="form-control" onChange={(e: any) => handleClassChange(e, student)}
                    disabled={year >= currentYear ? false : true}
                  >
                    {degrees.length > 0 && degrees.find((degree: IDegree) => degree.id == student.degreeId)?.classes.map((item: any) => (
                      <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                  </select>
                ) : (
                  <select className="form-control" disabled></select>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <button className="btn btn-primary" onClick={handleUpdate} disabled={isLoading}>Atualizar</button>
        </div>
      </div>

      <StudentAndProductsProvider student={student}>
        <StudentAndProducts />
      </StudentAndProductsProvider>

      <div className="card">
        <div className="card-header">Histórico de alterações</div>
        <div className="card-body p-0">
          <UserLogs data={userLogs} />
        </div>
      </div>
    </>
  )
}

export default StudentsEdit
