import axios from 'axios'

export const all = (): Promise<any> => {
  return axios.get('/admins/')
}

export const find = (adminId: number): Promise<any> => {
  return axios.get(`admins/${adminId}`)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const update = (admin_id: number, admin: any): Promise<any> => {
  return axios.put('admins/' + admin_id, admin)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const create = (admin: any): Promise<any> => {
  return axios.post('admins/', admin)
}

export const remove = (adminId: number): Promise<any> => {
  return axios.delete(`admins/${adminId}`)
}
