import React, { createContext, useCallback, useEffect, useState } from 'react'
import useStickyState from '@hooks/useStickyState'
import IAdmin from '@interfaces/IAdmin'
import * as $Auth from '@services/Auth'
import * as $Client from '@services/Client'
import * as $Dashboard from '@services/Dashboard'
import IClient from '@interfaces/IClient'
import axios, { AxiosResponse } from 'axios'

interface IAuthContextProps {
  admin: IAdmin
  setAdmin: React.Dispatch<React.SetStateAction<IAdmin>>
  client: IClient
  setClient: (client: IClient) => void
  clients: IClient[]
  setClients: React.Dispatch<React.SetStateAction<IClient[]>>
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  expiresAt: string
  setExpiresAt: React.Dispatch<React.SetStateAction<string>>
  isAuthenticated: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  dashboard: any
  logout: () => void
}

const AuthContext = createContext<IAuthContextProps>({ } as IAuthContextProps)

export const AuthProvider: React.FC<any> = ({ children }: any) => {
  const [ admin, setAdmin ] = useStickyState<IAdmin>({} as IAdmin, 'admin')
  const [ client, setClient ] = useStickyState<IClient>({} as IClient, 'client')
  const [ token, setToken ] = useStickyState<string>('', 'token')
  const [ expiresAt, setExpiresAt ] = useStickyState<string>('', 'expiresAt')
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ isAuthenticated, setIsAuthenticated ] = useState<boolean>(false)
  const [ clients, setClients ] = useState<IClient[]>([])
  const [ dashboard, setDashboard ] = useState<any|null>(null)

  const logout = () => {
    localStorage.removeItem('admin')
    localStorage.removeItem('token')
    localStorage.removeItem('expiresAt')

    setIsAuthenticated(false)
  }

  useEffect(() => {
    axios.defaults.headers.common['ADMIN_ID'] = admin?.id
  }, [admin?.id])

  useEffect(() => {
    if (token?.length > 0) {
      $Auth.me(token).then(({ data: admin }: AxiosResponse<IAdmin>) => {
        setAdmin(admin)
        setIsAuthenticated(true)
      }).finally(() => setIsLoading(false))
    } else {
      setIsLoading(false)
    }
  }, [token, setAdmin])

  useEffect(() => {
    if (!isAuthenticated)
      return

    if (!clients.length) {
      $Client.all().then(({ data }: any) => {
        if (data.length > 0) {
          setClients(data)

          if (!client?.id) {
            const client = data.find((client: IClient) => client.id === 4)

            if (client) {
              axios.defaults.headers.common['ClientId'] = client.id
              setTimeout(() => setClient(client), 200)
            }
          }
        }
      })
    }

    if (!dashboard) {
      $Dashboard.all().then(({ data }: any) => setDashboard(data))
    }
  }, [client?.id, clients.length, dashboard, isAuthenticated, setClient])

  const handleSetClient = useCallback((client: IClient) => {
    axios.defaults.headers.common['ClientId'] = client?.id
    setTimeout(() => setClient(client), 200)
  }, [setClient])

  return (
    <AuthContext.Provider
      value={{
        client,
        setClient: handleSetClient,
        admin,
        setAdmin,
        clients,
        setClients,
        token,
        setToken,
        expiresAt,
        setExpiresAt,
        isLoading,
        setIsLoading,
        isAuthenticated,
        setIsAuthenticated,
        dashboard,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
