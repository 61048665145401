import IUserGroup from '@interfaces/IUserGroup'
import axios, { AxiosResponse } from 'axios'

const all = () : Promise<AxiosResponse<IUserGroup[]>> => {
  return axios.get('user-groups')
}

const find = (id: number) : Promise<AxiosResponse<IUserGroup>> => {
  return axios.get(`user-groups/${id}`)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const userGroup = (id: number) => {
  const add = (userId: number) : Promise<AxiosResponse<IUserGroup>> => {
    return axios.post(`user-groups/${id}/users/${userId}`)
  }

  const remove = (userId: number) : Promise<AxiosResponse<IUserGroup>> => {
    return axios.delete(`user-groups/${id}/users/${userId}`)
  }

  return {
    add,
    remove,
  }
}

const $UserGroup = {
  all,
  find,
  userGroup,
}

export default $UserGroup
