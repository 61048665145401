import axios from 'axios'

export const all = (): Promise<any> => {
  return axios.get('/apps')
}

export const create = (app: any): Promise<any> => {
  return axios.post('/apps', app)
}

export const update = (app: any): Promise<any> => {
  return axios.put(`/apps/${app.id}`, app)
}

export const destroy = (id: number): Promise<any> => {
  return axios.delete(`/apps/${id}`)
}
