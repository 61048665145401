import IPermission from '@interfaces/IPermission'
import React, { useCallback, useContext } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as $PermissionProfile from '@services/PermissionProfile'
import { Link, useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import PermissionProfileAssigns from '@components/PermissionProfileAssigns/PermissionProfileAssigns'
import PermissionProfileContext from '@contexts/PermissionProfile'
import IPermissionProfilePermission from '@interfaces/IPermissionProfilePermission'
import * as Utils from '@helpers/Utils'

const PermissionProfile: React.FC<any> = () => {
  const { permissionProfileId, permissions, permissionProfile, setPermissionProfile, isLoading, setIsLoading } = useContext(PermissionProfileContext)

  const SweetAlert = withReactContent(Swal)
  const navigate = useNavigate()

  const save = useCallback(() => {
    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Salvando perfil de permissões',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $PermissionProfile.store(permissionProfile).then(() => SweetAlert.fire({
      title: 'Sucesso!',
      text: 'Perfil salvo com sucesso!',
      icon: 'success'
    }).then(() => navigate(`/perfil-de-permissoes/${permissionProfileId}`))).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao salvar o perfil!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }, [setIsLoading, permissionProfile, SweetAlert, navigate, permissionProfileId])

  const update = useCallback(() => {
    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Atualização perfil de permissões',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $PermissionProfile.update(permissionProfile).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Perfil atualizado com sucesso!',
        icon: 'success'
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao atualizar o perfil!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }, [setIsLoading, permissionProfile, SweetAlert])

  const handleInputChange = useCallback(({ target: { name, value } }: any) => setPermissionProfile({
    ...permissionProfile,
    [name]: value,
  }), [permissionProfile, setPermissionProfile])

  const handlePermissionChange = useCallback(({ target: { checked } }: any, permission: IPermission) => {
    if (!Array.isArray(permissionProfile.permissionProfilePermissions)) {
      permissionProfile.permissionProfilePermissions = []
    }

    const index = permissionProfile.permissionProfilePermissions.findIndex(p => p.permissionId === permission.id)

    if (checked && index === -1) {
      permissionProfile.permissionProfilePermissions.push({
        id: -Utils.random(),
        permissionProfileId,
        permissionId: permission.id,
      } as IPermissionProfilePermission)
    } else if (!checked && index > -1) {
      permissionProfile.permissionProfilePermissions.splice(index, 1)
    }

    setPermissionProfile({
      ...permissionProfile,
    })
  }, [permissionProfile, permissionProfileId, setPermissionProfile])

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/perfil-de-permissoes" className="btn btn-outline-primary me-3">Voltar</Link>
        {permissionProfileId ? (
          <button className="btn btn-primary" onClick={update} disabled={isLoading}>Atualizar</button>
        ) : (
          <button className="btn btn-primary" onClick={save} disabled={isLoading}>Salvar</button>
        )}
      </div>

      <div className="card mb-3">
        <div className="card-header">
          {permissionProfileId ? `Editar perfil de permissão ${permissionProfile?.name}` : 'Novo perfil de permissão'}
        </div>

        <div className="card-body pb-0">
          {(permissionProfileId && permissionProfile.id) || !permissionProfileId ? (
            <>
              <div className="form-group mb-3">
                <label htmlFor="name">Título</label>
                <input type="text" name="name" className="form-control" id="name" defaultValue={permissionProfile?.name} onChange={handleInputChange} />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="description">Descrição</label>
                <input type="text" name="description" className="form-control" id="description" defaultValue={permissionProfile?.description} onChange={handleInputChange} />
              </div>

              <div className="card mb-3">
                <div className="card-header bg-light">Permissões</div>

                <div className="card-body bg-light pb-0">
                  <div className="row">
                    {permissions?.map((permission: IPermission) => (
                      <div className="col-12 col-md-3 mb-3" key={permission.id}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name={permission.name}
                            className="form-check-input"
                            defaultChecked={permissionProfile.permissionProfilePermissions.some(p => p.permissionId === permission.id)}
                            onChange={(e: any) => handlePermissionChange(e, permission)}
                          />
                          <label htmlFor={permission.name}>{permission.name}</label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>
      </div>

      <PermissionProfileAssigns />

      <div className="d-flex justify-content-end mt-3">
        <Link to="/perfil-de-permissoes" className="btn btn-outline-primary me-3">Voltar</Link>
        {permissionProfileId ? (
          <button className="btn btn-primary" onClick={update} disabled={isLoading}>Atualizar</button>
        ) : (
          <button className="btn btn-primary" onClick={save} disabled={isLoading}>Salvar</button>
        )}
      </div>
    </>
  )
}

export default PermissionProfile
