export default {
  translations: {
    userLogs: {
      name: 'Name',
      email: 'E-mail',
      nickname: 'Nickname',
      schoolId: 'School ID',
      classId: 'Class ID',
      updated: 'Updated',
      created: 'Created',
      deleted: 'Deleted',
    },
  }
}
