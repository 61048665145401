import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'
import Pagination from '@components/Pagination/Pagination'
import * as $Reportment from '@services/Reportment'
import * as $Platform from '@services/Platform'
import * as $ReportmentCategory from '@services/ReportmentCategory'
import IReportment from '@interfaces/IReportment'
import { getCssProperty } from '@helpers/Theme'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import IPlatform from '@interfaces/IPlatform'
import Select from '@components/Select/Select'
import axios from 'axios'
import Table from '@components/Table/Table'
import useReportmentColumns from '@hooks/useReportmentColumns'

const Reportments: React.FC<any> = () => {
  const [ reportments, setReportments ] = useState<IReportment[]>([])
  const [ platforms, setPlatforms ] = useState<IPlatform[]>([])
  const [ categories, setCategories ] = useState<any[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ search, setSearch ] = useState<string>('')
  const [ platformId, setPlatformId ] = useState<number>(0)
  const [ reportmentCategoryId, setReportmentCategoryId ] = useState<number>(0)

  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)

  const getReportments = () => {
    $Reportment.all().then(({ data }: any) => setReportments(data)).finally(() => setIsLoading(false))
  }

  const onDelete = (reportment: IReportment) => SweetAlert.fire({
    title: 'Deseja prosseguir?',
    text: `Deseja prosseguir com a desativação do relatório "${reportment.name}"?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: getCssProperty('--secondary-color'),
    cancelButtonColor: getCssProperty('--primary-color'),
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((result: any) => {
    if (result.value && reportment?.id) {
      setIsLoading(true)

      $Reportment.remove(reportment.id).then(() => {
        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Relatório desativado com sucesso!',
          icon: 'success'
        })
      }).catch(() => {
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Erro ao desativar o relatório!',
          icon: 'error'
        })
      }).finally(() => {
        setIsLoading(false)
        SweetAlert.hideLoading()
        getReportments()
      })
    }
  })

  const onActive = (reportment: IReportment) => SweetAlert.fire({
    title: 'Deseja prosseguir?',
    text: `Deseja prosseguir com a ativação do relatório "${reportment.name}"?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: getCssProperty('--secondary-color'),
    cancelButtonColor: getCssProperty('--primary-color'),
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((result: any) => {
    if (result.value && reportment?.id) {
      setIsLoading(true)

      $Reportment.undelete(reportment.id).then(() => {
        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Relatório ativado com sucesso!',
          icon: 'success'
        })
      }).catch(() => {
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Erro ao ativar o relatório!',
          icon: 'error'
        })
      }).finally(() => {
        setIsLoading(false)
        SweetAlert.hideLoading()
        getReportments()
      })
    }
  })

  const columns = useReportmentColumns(onActive, onDelete)

  useEffect(() => {
    axios.all([
      $Platform.all(),
      $ReportmentCategory.all(),
      $Reportment.all()
    ]).then(axios.spread(({ data: platforms }: any, { data: categories }: any, { data: reportments }: any) => {
      setPlatforms(platforms)
      setCategories(categories)
      setReportments(reportments)
    })).finally(() => setIsLoading(false))
  }, [])

  const data = useMemo(() => reportments.filter((reportment: IReportment) => {
    let exists = true

    if (search.length > 0)
      exists = reportment.id.toString().includes(search) ||
      reportment.name.toLowerCase().includes(search.toLowerCase())

    if (exists && platformId > 0)
      exists = reportment.platformId === platformId

    if (exists && reportmentCategoryId > 0)
      exists = reportment.reportmentCategoryId === reportmentCategoryId

    return exists
  }), [reportments, search, platformId, reportmentCategoryId])

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const handlePlatformChange = (data: any|null) => {
    if (data !== null) {
      setPlatformId(Number(data.value))
    } else {
      setPlatformId(0)
    }
  }

  const handleCategoryChange = (data: any|null) => {
    if (data !== null) {
      setReportmentCategoryId(Number(data.value))
    } else {
      setReportmentCategoryId(0)
    }
  }

  return (
    <>
      <div className="row align-items-end mb-3">
        <div className="col-8 col-md-8">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="search">Plataforma:</label>
                {platforms.length > 0 && (
                  <Select
                    type="primary"
                    onChange={handlePlatformChange}
                    options={platforms.map((platform: IPlatform) => ({
                      value: platform.id,
                      label: platform.name,
                    }))}
                    isSearchable
                    isClearable
                    placeholder="Selecione uma plataforma"
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="search">Categoria:</label>
                <Select
                  type="primary"
                  onChange={handleCategoryChange}
                  options={categories.map((category: any) => ({
                    value: category.id,
                    label: category.name,
                  }))}
                  isSearchable
                  isClearable
                  placeholder="Selecione uma categoria"
                />
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="search">Pesquisar:</label>
                <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo ID ou título" onChange={e => setSearch(e.target.value)} disabled={isLoading} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-4 col-md-4 d-flex justify-content-end align-items-end">
          <button className="btn btn-primary" onClick={() => navigate('/relatorios/cadastrar')} disabled={isLoading}>Criar relatório</button>
        </div>
      </div>

      <div className="card">
        <div className="card-header">Relatórios</div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <Table
              getTableProps={getTableProps}
              getTableHeadProps={getTableHeadProps}
              getTableBodyProps={getTableBodyProps}
              page={page}
              headerGroups={headerGroups}
              prepareRow={prepareRow}
              isLoading={isLoading}
            />
          </div>
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Reportments
