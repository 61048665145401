import axios from 'axios'

export const all = (): Promise<any> => {
  return axios.get('/study-plans')
}

export const findEvaluations = (clientId: number): Promise<any> => {
  return axios.get(`/study-plans/${clientId}/evaluations`)
}

export const store = (study: FormData): Promise<any> => {
  return axios.post('/study-plans', study, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const update = (studyPlanId: number, study: FormData): Promise<any> => {
  return axios.put(`/study-plans/${studyPlanId}`, study, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const destroy = (studyPlanId: number): Promise<any> => {
  return axios.delete(`/study-plans/${studyPlanId}`)
}
