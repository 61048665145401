import React, { useContext, useState } from 'react'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import RegistrationContext from '@contexts/Registration/Registration'
import { Textarea } from './CopyPaste.styles'
import RegistrationStepEnum from '@enums/RegistrationStep.enum'

const CopyPaste: React.FC<any> = ({ instruction }: any) => {
  const { addEntities, setStep, registrationTypeAction } = useContext(RegistrationContext)

  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ copyPasteValue, setCopyPasteValue ] = useState<string>('')

  const SweetAlert = withReactContent(Swal)

  const handleInputChange = (e: any) => setCopyPasteValue(e.target.value)

  const setStudentsData = () => {
    setIsLoading(true)

    const lines = copyPasteValue.split('\n')

    if (!lines.length) {
      SweetAlert.fire({
        title: 'Erro',
        text: 'Dados inválidos',
        icon: 'error',
      }).then(() => setIsLoading(false))
      return
    }

    const rows: string[][] = []

    for (let i = 0; i < lines.length; i++) {
      rows.push(lines[i].split('\t'))
    }

    const entities: IRegistrationEntity[] = registrationTypeAction?.getEntities(rows) as IRegistrationEntity[]

    addEntities(entities).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Arquivo processado com sucesso!',
        icon: 'success'
      })

      setStep(RegistrationStepEnum.Finish)
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Ocorreu um erro ao processar o arquivo!',
        icon: 'error'
      })
    }).finally(() => setIsLoading(false))
  }

  const placeholder = registrationTypeAction?.getLabels().join('\t')

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-8">
            <Textarea
              name="data"
              className="form-control lined-textarea"
              placeholder={placeholder}
              onChange={handleInputChange}
            ></Textarea>
          </div>

          <div className="col-12 col-md-4">
            <h5 className="mb-0">Instruções de preenchimento</h5>
            <hr/>
            {instruction}
          </div>
        </div>
      </div>

      <div className="card-footer d-flex justify-content-end">
        <button className="btn btn-primary" onClick={setStudentsData} disabled={!copyPasteValue.length || isLoading}>Continuar</button>
      </div>
    </>
  )
}

export default CopyPaste
