import React from 'react'
import { Button } from './Steps.styles'

interface ISteps {
  current: number;
  range: Array<number>;
  onClick?: (step: number) => void;
  disabled?: boolean;
}

const Steps: React.FC<ISteps> = ({ current, range, onClick, disabled }: ISteps) => {
  const [ from, to ] = range

  const list = []

  for (let i = from; i <= to; i++) {
    list.push(i)
  }

  return (
    <div className="steps d-flex justify-content-between align-items-center">
      {list.map((step: number, index: number) => (
        <Button
          className={current === step ? 'active' : ''}
          onClick={() => onClick ? onClick(step) : null}
          disabled={disabled}
          key={index}
        >{step}</Button>
      ))}
    </div>
  )
}

export default Steps
