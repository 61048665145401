import IAdminImportManagerRow from '@interfaces/IAdminImportManagerRow'
import IAdminImportManager from '@interfaces/IAdminImportManager'
import axios from 'axios'

export const store = (admin: IAdminImportManager): Promise<any> => axios.post('/admin-imports/excel/', admin)

export const bulkStore = (token: string, imports: IAdminImportManagerRow[]): Promise<any> => axios.post(`/admin-imports/${token}`, imports)

export const all = (): Promise<any> => axios.get('/admin-imports/')

export const search = (id: number): Promise<any> => axios.get(`/admin-imports/${id}`)
