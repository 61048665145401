import styled from 'styled-components'

export const List = styled.div`
  .thumbnail {
    border: 0;
    padding: 0;
    width: 60px;
    height: 60px;
    background-size: cover;
    background-color: #eee;
    border-radius: 6px;
    background-position: center;
  }
`
