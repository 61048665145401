import React, { useContext, useEffect, useMemo, useState } from 'react'
import Select from '@components/Select/Select'
import * as $School from '@services/School'
import * as $Classroom from '@services/Class'
import AuthContext from '@contexts/Auth'
import ISchool from '@interfaces/ISchool'
import IDegree from '@interfaces/IDegree'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import * as $AssociationSchoolAssessment from '@services/AssociationSchoolAssessment'
import IProduct from '@interfaces/IProduct'
import axios from 'axios'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { getCssProperty } from '@helpers/Theme'
import IAssociationSchoolAssessment from '@interfaces/IAssociationSchoolAssessment'
import { BorderBottom, Buttons } from './AssociationSchoolAssessments.styles'
import * as $Network from '@services/Network'
import INetwork from '@interfaces/INetwork'

const AssociationSchoolAssessments: React.FC<any> = () => {
  const { client } = useContext(AuthContext)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ schools, setSchools ] = useState<ISchool[]>([])
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ assessments, setAssessments ] = useState<IProduct[]>([])
  const [ isSearching, setIsSearching ] = useState<boolean>(false)
  const [ networks, setNetworks ] = useState<INetwork[]>([])

  const SweetAlert = withReactContent(Swal)

  const [selectedSchools, setSelectedSchools] = useState<number[]>([])
  const handleSelectSchoolsChange = (schools: any) => setSelectedSchools(schools.map((option : any) => option.value))

  const [selectedDegrees, setSelectedDegrees] = useState<number[]>([])
  const handleSelectDegreesChange = (degrees: any) => setSelectedDegrees(degrees.map((option : any) => option.value))

  const [selectedAssessments, setSelectedAssessments] = useState<number[]>([])
  const handleSelectAssessmentsChange = (assessments: any) => setSelectedAssessments(assessments.map((option : any) => option.value))

  const [associationsSchoolsAssessments, setAssociationsSchoolsAssessments] = useState<IAssociationSchoolAssessment[]>([])
  const [associations, setAssociations] = useState<IAssociationSchoolAssessment>()

  const [ networkId, setNetworkId ] = useState<number>(0)
  const handleNetworkIdChange = ({ value: networkId }: any) => setNetworkId(networkId)
  const [ isLoadingSchool, setIsLoadingSchool ] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    axios.all([
      $School.findByClient(client.id),
      $Classroom.allDegrees(),
      $AssociationSchoolAssessment.findAssessments(client.id),
      $Network.findByClient(client.id)
    ]).then(axios.spread(({ data: schools }: any, { data: degrees }: any, { data: assessments }: any, { data: networks }: any) => {
      setSchools(schools)
      setDegrees(degrees)
      setAssessments(assessments)
      setNetworks(networks)
    })).finally(() => setIsLoading(false))
  }, [client])

  useEffect(() => {
    setIsLoadingSchool(true)
    $School.findByClientAndNetwork(client.id, networkId).then(({ data }: any) => {
      setSchools(data)
    }).finally(() => setIsLoadingSchool(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkId])

  const networkOptions = useMemo(() => {
    if (!client.id)
      return []

    const networkOptions: any[] = [{
      value: 0,
      label: 'Todas'
    }, ...networks.map((network: INetwork) => ({
      value: network.id,
      label: network.id + ' - ' + network.name,
    }))]

    return networkOptions
  }, [client.id, networks])

  const schoolOptions = useMemo(() => {
    if (!client.id)
      return []

    const schoolOptions: any[] = [{
      value: 0,
      label: 'Todas'
    }, ...schools.map((school: ISchool) => ({
      value: school.id,
      label: school.id + ' - ' + school.fantasyName,
    }))]

    return schoolOptions
  }, [client.id, schools])

  const degreeOptions: any[] = degrees.map((degree: IDegree) => ({
    value: degree.id,
    label: degree.name,
  }))

  const assessmentOptions = useMemo(() => {
    if (!client.id)
      return []

    const assessmentOptions: any[] = assessments.map((assessment: IProduct) => ({
      value: assessment.id,
      label: assessment.name,
    }))

    return assessmentOptions
  }, [client.id, assessments])

  const joinItem = (selected: number[]) => {
    return selected.join(', ')
  }

  const onSearch = () => {
    if (selectedSchools.length > 0 && selectedDegrees.length > 0 && selectedAssessments.length > 0) {
      setIsLoading(true)
      setIsSearching(true)
      $AssociationSchoolAssessment.search({
        schoolIds: joinItem(selectedSchools),
        degreeIds: joinItem(selectedDegrees),
        assessmentIds: joinItem(selectedAssessments),
        networkId: networkId
      } as IAssociationSchoolAssessment).then(({ data }: any) => {
        if (data.studentsAmountByAssessmentAndSchool.length > 0) {
          setAssociationsSchoolsAssessments(data.studentsAmountByAssessmentAndSchool)
          setAssociations(data.studentAmount)
        } else {
          OnCancel()
          SweetAlert.fire({
            title: 'Erro!',
            text: 'Busca não retorno resultados!',
            icon: 'warning'
          })
        }
      }).catch(() => {
        OnCancel()
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Erro ao realizar as associações!',
          icon: 'error'
        })
      }).finally(() => {
        setIsLoading(false)
        SweetAlert.hideLoading()
      })
    } else
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Todos os campos devem ser preenchidos!',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
  }

  const OnCancel = () => {
    setSelectedSchools([])
    setSelectedDegrees([])
    setSelectedAssessments([])
    setAssociationsSchoolsAssessments([])
    setIsSearching(false)
  }

  const onSubmit = (data: any) => SweetAlert.fire({
    title: 'Deseja prosseguir?',
    html: `<p>Já inscrito(s): <b>${data.inscriptionsdone}</b></p>
    <p>Novo(s) inscrito(s): <b>${data.inscriptionsToBeDone}</b></p>`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: getCssProperty('--primary-color'),
    cancelButtonColor: getCssProperty('--secondary-color'),
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    allowOutsideClick: false,
  }).then((result: any) => {
    if (result.value) {
      setIsLoading(true)
      $AssociationSchoolAssessment.store({
        schoolIds: joinItem(selectedSchools),
        degreeIds: joinItem(selectedDegrees),
        assessmentIds: joinItem(selectedAssessments),
        networkId: networkId
      } as IAssociationSchoolAssessment).then(() => {
        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Associações realizadas com sucesso!',
          icon: 'success'
        })
      }).catch(() => {
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Erro ao realizar as associações!',
          icon: 'error'
        })
      }).finally(() => {
        OnCancel()
        setIsLoading(false)
        SweetAlert.hideLoading()
      })
    } else {
      OnCancel()
    }
  })

  return (
    <>
      <div className="card">
        <div className="card-header">
          Associação de escola e produto
        </div>
        <div className="card-body">
          <div className="row pt-2 pb-2">
            <div className="col-12 col-lg-4 col-md-3 mb-3">
              <div className="form-group">
                {!isLoading ? (
                  <Select
                    type="primary"
                    onChange={handleNetworkIdChange}
                    options={networkOptions}
                    placeholder="Selecione a rede"
                    isDisabled={!client.id || !networks.length || isSearching}
                    isSearchable
                    required
                  />
                ) : (
                  <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                    <Skeleton count={1} height={36} />
                  </SkeletonTheme>
                )}
              </div>
            </div>
          </div>
          <div className="row pb-2">
            <div className="col-12 col-lg-4 col-md-3 mb-3">
              <div className="form-group">
                {!isLoading ? (
                  <Select
                    isMulti
                    type="primary"
                    onChange={handleSelectSchoolsChange}
                    options={schoolOptions}
                    placeholder="Selecione a(s) escola(s)"
                    isDisabled={!client.id || !schools.length || isSearching || isLoadingSchool}
                    isSearchable
                    required
                  />
                ) : (
                  <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                    <Skeleton count={1} height={36} />
                  </SkeletonTheme>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3 mb-3">
              <div className="form-group">
                {!isLoading ? (
                  <Select
                    isMulti
                    type="primary"
                    onChange={handleSelectDegreesChange}
                    options={degreeOptions}
                    placeholder="Selecione a(s) série(s)"
                    isDisabled={!client.id || !degrees.length || isSearching}
                    isSearchable
                    required
                  />
                ) : (
                  <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                    <Skeleton count={1} height={36} />
                  </SkeletonTheme>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3 mb-3">
              <div className="form-group">
                {!isLoading ? (
                  <Select
                    isMulti
                    type="primary"
                    onChange={handleSelectAssessmentsChange}
                    options={assessmentOptions}
                    placeholder="Selecione o(s) simulado(s)"
                    isDisabled={!client.id || !assessments.length || isSearching}
                    isSearchable
                    required
                  />
                ) : (
                  <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                    <Skeleton count={1} height={36} />
                  </SkeletonTheme>
                )}
              </div>
            </div>
            <div className="col-12 col-md-1 mt-1 mb-3">
              <Buttons className="form-group">
                <button className="btn btn-primary"
                  disabled={!client.id || !isSearching && (selectedSchools.length > 0 && selectedDegrees.length > 0 && selectedAssessments.length > 0) ? false : true}
                  onClick={onSearch}
                >Associar</button>
              </Buttons>
            </div>
          </div>
          {associationsSchoolsAssessments.length > 0 && (
            <>
              <div className="row pt-5 pb-5">
                {associationsSchoolsAssessments.map((association: IAssociationSchoolAssessment, index: number) => {
                  return (<BorderBottom className="col-12 col-md-4" key={index}>
                    <p><b>Escola</b><br/> {association.schoolId} - {association.schoolName}</p>
                    <p><b>Simulado</b><br/> {association.assessmentId} - {association.assessmentName}</p>
                    <p><b>Já inscritos</b><br/> {association.inscriptionsdone}</p>
                    <p><b>Novos inscritos</b><br/> {association.inscriptionsToBeDone}</p>
                  </BorderBottom>)
                })}
              </div>
              <div className="row pb-5">
                <Buttons className="d-flex justify-content-center align-items-center">
                  <button className="btn btn-primary me-2" onClick={() => onSubmit(associations)}>Confirmar</button>
                  <button className="btn btn-danger" onClick={() => OnCancel()}>Cancelar</button>
                </Buttons>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AssociationSchoolAssessments
