import IClass from '@interfaces/IClass'
import React, { createContext, useRef, useState } from 'react'
import { generateToken } from '@helpers/Utils'
import { Outlet } from 'react-router-dom'

interface IBulkClassesContextContextProps {
  token: string
  classroom: IClass[]
  setClassroom: React.Dispatch<React.SetStateAction<IClass[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  pushClasses: (classes: IClass[]) => Promise<void>
  file: File|null
  setFile: React.Dispatch<React.SetStateAction<File|null>>
  fileRef: React.MutableRefObject<any>
  refreshToken: () => void
}

const BulkClassesContext = createContext<IBulkClassesContextContextProps>({ } as IBulkClassesContextContextProps)

export const BulkClassesProvider: React.FC<any> = () => {
  const [ token, setToken ] = useState<string>(generateToken(32))
  const [ classroom, setClassroom ] = useState<IClass[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ file, setFile ] = useState<File|null>(null)

  const fileRef = useRef<any>()

  const refreshToken = () => setToken(generateToken(32))

  const pushClasses = (classroom: IClass[]) : Promise<void> => new Promise((resolve) => {
    setClassroom(classroom)
    setTimeout(() => resolve(), 1000)
  })

  const value = {
    token,
    classroom,
    setClassroom,
    isLoading,
    setIsLoading,
    pushClasses,
    file,
    setFile,
    fileRef,
    refreshToken,
  }

  return (
    <BulkClassesContext.Provider value={value}>
      <Outlet />
    </BulkClassesContext.Provider>
  )
}

export default BulkClassesContext
