import IAccessAssociation from '@interfaces/IAccessAssociation'
import React, { createContext, useRef, useState } from 'react'
import { generateToken } from '@helpers/Utils'
import { Outlet } from 'react-router-dom'

interface IAccessAssociationContextProps {
  token: string
  associations: IAccessAssociation[]
  setAssociations: React.Dispatch<React.SetStateAction<IAccessAssociation[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  pushAssociations: (associations: IAccessAssociation[]) => Promise<void>
  file: File|null
  setFile: React.Dispatch<React.SetStateAction<File|null>>
  fileRef: React.MutableRefObject<any>
  refreshToken: () => void
}

const AccessAssociationContext = createContext<IAccessAssociationContextProps>({ } as IAccessAssociationContextProps)

export const AccessAssociationProvider: React.FC<any> = () => {
  const [ token, setToken ] = useState<string>(generateToken(32))
  const [ associations, setAssociations ] = useState<IAccessAssociation[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ file, setFile ] = useState<File|null>(null)

  const fileRef = useRef<any>()

  const refreshToken = () => setToken(generateToken(32))

  const pushAssociations = (associations: IAccessAssociation[]) : Promise<void> => new Promise((resolve) => {
    setAssociations(associations)
    setTimeout(() => resolve(), 1000)
  })

  const value = {
    token,
    associations,
    setAssociations,
    isLoading,
    setIsLoading,
    pushAssociations,
    file,
    setFile,
    fileRef,
    refreshToken,
  }

  return (
    <AccessAssociationContext.Provider value={value}>
      <Outlet />
    </AccessAssociationContext.Provider>
  )
}

export default AccessAssociationContext
