import React, { useContext, useEffect, useState } from 'react'
import AppContext from '@contexts/App'
import * as $School from '@services/School'
import * as $App from '@services/App'
import Select from 'react-select'
import { Spinner } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const App: React.FC<any> = () => {
  const { app, setApp, setApps, clients, isLoading, setIsLoading, setCanEdit } = useContext(AppContext)

  const [ schools, setSchools ] = useState<any[]>([])

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (app?.clientId > 0) {
      $School.all({
        clientId: app.clientId,
      }).then(({ data: schools }: any) => setSchools(schools))
    }
  }, [app?.clientId])

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde...',
      html: app.id > 0 ? 'Atualizando registro...' : 'Criando registro...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    if (app.id > 0) {
      $App.update(app).then(({ data: apps }: any) => {
        setApps(apps)
        SweetAlert.close()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao atualizar o registro!',
        })
      }).finally(onClose)
    } else {
      $App.create(app).then(({ data: apps }: any) => {
        setApps(apps)
        SweetAlert.close()
      }).catch((e: any) => {
        SweetAlert.fire({
          title: 'Erro',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao registrar!',
        })
      }).finally(onClose)
    }
  }

  const onClientChange = ({ value, label }: any) => setApp({
    ...app,
    clientId: Number(value),
    client: {
      id: Number(value),
      name: label,
    },
  })

  const onSchoolChange = (value: any|null) => {
    if (value === null) {
      setApp({
        ...app,
        schoolId: null,
        school: null,
      })
    } else {
      setApp({
        ...app,
        schoolId: Number(value.value),
        school: {
          id: Number(value.value),
          name: value.label,
        },
      })
    }
  }

  const onClose = () => {
    setCanEdit(true)
    setIsLoading(false)
    setSchools([])
    setApp(null)
  }

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => setApp({
    ...app,
    [name]: value.length > 0 ? value : null,
  })

  if (app === null) {
    return null
  }

  return (
    <div className="col-12 col-md-3">
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="card-body">
            <div className="form-group mb-3">
              <label htmlFor="name" className="form-label mb-0">Nome</label>
              <input type="text" id="name" name="name" className="form-control" defaultValue={app?.name} onChange={handleInputChange} required />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="appId" className="form-label mb-0">App ID</label>
              <input type="text" id="appId" className="form-control" value={app?.appId} disabled required />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="appSecret" className="form-label mb-0">App secret</label>
              <input type="text" id="appSecret" name="appSecret" className="form-control" defaultValue={app?.appSecret} onChange={handleInputChange} />
            </div>

            <div className="form-group mb-3">
              <label>Cliente:</label>
              {clients.length ? (
                <Select
                  options={clients.map((client: any) => ({ value: client.id, label: client.name }))}
                  placeholder="Selecione um cliente"
                  isDisabled={!clients.length}
                  onChange={onClientChange}
                  defaultValue={{ value: app?.client?.id, label: app?.client?.name }}
                  isSearchable
                  required
                />
              ) : null}
            </div>

            <div className="form-group mb-3">
              <label>Escola:</label>
              <Select
                onChange={onSchoolChange}
                options={schools.map((school: any) => ({ value: school.id, label: school.name }))}
                defaultValue={app?.school ? { value: app?.school?.id, label: app?.school?.name } : null}
                placeholder="Selecione uma escola"
                isDisabled={!schools.length}
                isSearchable
                isClearable
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="userId" className="form-label mb-0">Usuário ID</label>
              <input type="text" id="userId" name="userId" className="form-control" defaultValue={app?.userId} onChange={handleInputChange} placeholder="Informe o ID de um usuário acadêmico" />
            </div>

            <div className="form-group">
              <label htmlFor="description" className="form-label mb-0">Descrição</label>
              <textarea name="description" className="form-control" rows={5} id="description" defaultValue={app?.description} onChange={handleInputChange}></textarea>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end">
            <button type="button" className="btn btn-outline-secondary pe-3 ps-3 me-2" onClick={onClose} disabled={isLoading}>Fechar</button>
            <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={isLoading}>
              <span>{app?.id > 0 ? 'Atualizar' : 'Salvar'}</span> {isLoading && <Spinner animation="border" size="sm" variant="light" className="ms-2" />}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default App
