const columns: any[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Planilha',
    accessor: 'name',
  },
  {
    Header: 'Administrador',
    accessor: 'adminName',
  },
  {
    Header: 'Migrados',
    accessor: 'amountMigrated',
  },
  {
    Header: 'Total',
    accessor: 'amount',
  },
  {
    Header: 'Data de upload',
    accessor: 'date',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    Cell: (props: { value: Date }) => {
      return props.value ? new Date(props.value).toLocaleString() : 'Não possui'
    },
  },
]

export default columns
