import styled from 'styled-components'

export const Card = styled.div`
  position: sticky;
  bottom: 0;
  top: 120px;
`

export const Buttons = styled.div`
  @media (max-width: 576px) {
    .btn {
      width: 100%;
    }
  }
`
