import React from 'react'
import IUserLog from '@interfaces/IUserLog'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const UserLogs: React.FC<any> = ({ data }: any) => {
  const { t } = useTranslation()

  return (
    <div className="table-responsive">
      <table className="table table-striped table-dashed mb-0">
        <thead>
          <tr>
            <th>Usuário</th>
            <th>Alteração</th>
            <th>Valor anterior</th>
            <th>Novo valor</th>
            <th>Ação</th>
            <th>Data</th>
          </tr>
        </thead>

        <tbody>
          {data.length > 0 ? data.map((item : IUserLog) => (
            <tr key={item.id}>
              <td>{item.userName}</td>
              <td>{t(`userLogs.${item.columnName.toLowerCase()}`)}</td>
              <td>{item.oldValue}</td>
              <td>{item.newValue}</td>
              <td>{t(`userLogs.${item.action.toLowerCase()}`)}</td>
              <td>{moment(item.createdAt).format('D/MM/YYYY [ás] H:mm:ss')}</td>
            </tr>
          )) : (
            <tr>
              <td colSpan={6} className="text-center">Nenhuma alteração encontrada.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default UserLogs
