import React, { useMemo } from 'react'
import IUserSchool from '@interfaces/IUserSchool'
import { BsCardChecklist, BsFillCalendarDateFill } from 'react-icons/bs'

const useUserSchoolColumns = (onChange: (userSchool: IUserSchool) => void): any => {
  return useMemo(() => [
    {
      Header: 'Nome',
      Cell: ({ row: { original: userSchool } }: any) => (
        <>
          <div className="row">
            <div className="col-6">
              {userSchool.assessmentId != null ? userSchool.assessmentId + ' - ' : ''} {userSchool.name}
              {userSchool.hasAnswers ? <BsCardChecklist className="ms-2" title="Possui respostas" color="red" /> : null}
            </div>
            <div className="col-4">
              {validateLimitDate(userSchool.inscriptionLimitDate ?? null) ? (<>
                <BsFillCalendarDateFill className="ms-2 me-2" title="Fora do período de inscrição" color="orange" />
                <small>Data limite: {new Date(userSchool.inscriptionLimitDate).toLocaleString()}</small>
              </>) : null}
            </div>
          </div>
        </>
      ),
    },
    {
      Header: 'Ativo',
      Cell: ({ row: { original: userSchool } }: any) => (
        <input
          type="checkbox"
          name="activeUserSchool"
          title={userSchool.hasAnswers || validateLimitDate(userSchool.inscriptionLimitDate) ? 'Não é possível alterar!' : ''}
          value={userSchool.AccessUser}
          defaultChecked={userSchool.AccessUser ? true : false}
          disabled={userSchool.hasAnswers || validateLimitDate(userSchool.inscriptionLimitDate)}
          onChange={() => onChange(userSchool)}
        />
      ),
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])
}

function validateLimitDate(limitDate: Date) {
  const dateNow = new Date()
  const mounth = (dateNow.getMonth() + 1)

  const limit = new Date(limitDate)
  const limitDay = limit.getDate()
  const limitMonth = limit.getMonth() + 1
  const limitYear = limit.getFullYear()
  const limitHour = limit.getHours()
  const limitMinute = limit.getMinutes()

  return limitDate != null && dateNow.getFullYear() > limitYear ||
    dateNow.getFullYear() == limitYear && mounth > limitMonth ||
    dateNow.getFullYear() == limitYear && mounth == limitMonth && dateNow.getDate() > limitDay ||
    dateNow.getFullYear() == limitYear && mounth == limitMonth && dateNow.getDate() == limitDay && dateNow.getHours() > limitHour ||
    dateNow.getFullYear() == limitYear && mounth == limitMonth && dateNow.getDate() == limitDay
    && dateNow.getHours() == limitHour && dateNow.getMinutes() > limitMinute ? true : false
}

export default useUserSchoolColumns
