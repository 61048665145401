import styled from 'styled-components'

export const Container = styled.div`
  .form-control {
    padding: 10px 16px !important;
  }

  > .flexmonster-container {
    position: relative;

    > .loading-container {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0.8);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      z-index: 999;
    }
  }
`
