import React, { useMemo } from 'react'
import * as Bootstrap from 'react-bootstrap'

interface IPagination {
  page: number,
  pageCount: number,
  canPreviousPage: boolean,
  canNextPage: boolean,
  nextPage: () => void,
  previousPage: () => void,
  onClick: (page: number) => void;
}

const Pagination: React.FC<IPagination> = ({ page, pageCount, canPreviousPage, canNextPage, nextPage, previousPage, onClick }: IPagination) => {
  const pageNumbers = useMemo((): Array<number> => {
    const numbers = []

    let start = page - 2, end = page + 2

    if (end > pageCount) {
      start -= (end - pageCount)
      end = pageCount
    }

    if (start <= 0) {
      end += ((start - 1) * (-1))
      start = 0
    }

    end = end > pageCount ? pageCount : end

    for (let i = start; i < end; i++) numbers.push(i)

    return numbers
  }, [ page, pageCount ])

  return (
    <Bootstrap.Pagination className="mb-0">
      <Bootstrap.Pagination.First disabled={!canPreviousPage} onClick={() => onClick(0)} />
      <Bootstrap.Pagination.Prev disabled={!canPreviousPage} onClick={previousPage} />

      {pageNumbers.map((pageNumber: number) => (
        <Bootstrap.Pagination.Item key={pageNumber} active={pageNumber === page} onClick={() => onClick(pageNumber)}>
          {pageNumber + 1}
        </Bootstrap.Pagination.Item>
      ))}

      <Bootstrap.Pagination.Next disabled={!canNextPage} onClick={nextPage} />
      <Bootstrap.Pagination.Last disabled={!canNextPage} onClick={() => onClick(pageCount - 1)} />
    </Bootstrap.Pagination>
  )
}

export default Pagination
