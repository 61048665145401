import styled from 'styled-components'

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  > .btn {
    width: 50%;
    display: block;
  }
`
