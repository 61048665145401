const columns: any[] = [
  {
    Header: 'Qtd. Auditoria',
    accessor: 'amountAudit',
  },
  {
    Header: 'Qtd. Erros',
    accessor: 'amountError',
  },
  {
    Header: 'ID Escola',
    accessor: 'schoolId',
  },
  {
    Header: 'Escola',
    accessor: 'fantasyName',
  },
  {
    Header: 'Simulado',
    accessor: 'assessmentName',
  },
]

export default columns
