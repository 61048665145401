import ISchedule from '@interfaces/ISchedule'
import axios, { AxiosResponse } from 'axios'

export const all = (): Promise<AxiosResponse<ISchedule[]>> => axios.get('schedules')

export const find = (id: number): Promise<AxiosResponse<ISchedule>> => axios.get(`schedules/${id}`)

export const store = (data: FormData): Promise<AxiosResponse<ISchedule>> => axios.post('schedules', data)

export const update = (id: number, data: FormData): Promise<AxiosResponse<ISchedule>> => axios.put(`schedules/${id}`, data)

const $Schedule = {
  all,
  find,
  store,
  update,
}

export default $Schedule
