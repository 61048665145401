import IAssociationSchoolAssessment from '@interfaces/IAssociationSchoolAssessment'
import axios from 'axios'

export const findAssessments = (clientId: number): Promise<any> => {
  return axios.get(`/association-school-assessments/${clientId}/assessments`)
}

export const search = (associations: IAssociationSchoolAssessment): Promise<any> => axios.post('/association-school-assessments/search', associations)

export const store = (associations: IAssociationSchoolAssessment): Promise<any> => axios.post('/association-school-assessments', associations)
