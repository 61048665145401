import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import IPermissionProfile from '@interfaces/IPermissionProfile'
import * as $PermissionProfile from '@services/PermissionProfile'
import { Buttons } from './PermissionsProfiles.styles'

const PermissionProfiles: React.FC<any> = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ permissionProfiles, setPermissionProfiles ] = useState<IPermissionProfile[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    $PermissionProfile.all().then(({ data }: any) => setPermissionProfiles(data)).finally(() => setIsLoading(false))
  }, [])

  return (
    <>
      <Buttons className="row">
        <div className="col-12 col-sm-8 mb-3 d-flex">
          <button
            className="btn btn-outline-primary ps-4 pe-4 me-1"
            disabled={isLoading}
            onClick={() => navigate('/permissoes')}
          >Permissões</button>

          <button
            className="btn btn-primary ps-4 pe-4 me-1"
            disabled={isLoading}
          >Perfil de permissões</button>
        </div>

        <div className="col-12 col-sm-4 mb-3 d-flex justify-content-sm-end">
          <Link
            to="/perfil-de-permissoes/cadastrar"
            className="btn btn-primary ps-4 pe-4"
          >Cadastrar perfil</Link>
        </div>
      </Buttons>

      <div className="card">
        <div className="card-header">Perfil de permissões</div>

        <div className="card-body p-0">
          {!isLoading ? (
            <div className="table-responsive">
              <table className="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>Qtd. de permissões</th>
                    <th className="text-right" style={{ width: 80 }}>Ações</th>
                  </tr>
                </thead>

                <tbody>
                  {permissionProfiles.length > 0 ? permissionProfiles.map((permissionProfile: IPermissionProfile) => (
                    <tr key={permissionProfile.id}>
                      <td>{permissionProfile.id}</td>
                      <td>{permissionProfile.name}</td>
                      <td>{permissionProfile.description}</td>
                      <td>{permissionProfile.permissionsLength}</td>
                      <td>
                        <Link to={`/perfil-de-permissoes/${permissionProfile.id}/editar`} className="btn btn-sm ps-4 pe-4 btn-primary">Editar</Link>
                      </td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan={5} style={{ textAlign: 'center' }}>Nenhum resultado encontrado.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="d-flex justify-content-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PermissionProfiles
