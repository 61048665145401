import React, { createContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import * as $ReportmentAudit from '@services/ReportmentAudit'
import IReportmentAudit from '@interfaces/IReportmentAudit'

type ReportmentAuditContextProps = {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  isLoadingModal: boolean
  setIsLoadingModal: React.Dispatch<React.SetStateAction<boolean>>
  reportment: IReportmentAudit
  setReportment: React.Dispatch<React.SetStateAction<IReportmentAudit>>
  reportments: IReportmentAudit[]
  setReportments: React.Dispatch<React.SetStateAction<IReportmentAudit[]>>
}

const ReportmentAuditContext = createContext<ReportmentAuditContextProps>({ } as ReportmentAuditContextProps)

export const ReportmentAuditProvider: React.FC<any> = () => {
  const [ showModal, setShowModal ] = useState<boolean>(false)
  const [ isLoadingModal, setIsLoadingModal ] = useState<boolean>(true)
  const [ reportment, setReportment ] = useState<IReportmentAudit>({} as IReportmentAudit)
  const [ reportments, setReportments ] = useState<IReportmentAudit[]>([])

  useEffect(() => {
    if (reportment.type)
      $ReportmentAudit.details(reportment.schoolId, reportment.assessmentID, reportment.type ?? 1).then(({ data }: any) => {
        setReportments(data)
      }).finally(() => setIsLoadingModal(false))
  }, [reportment])

  return (
    <ReportmentAuditContext.Provider
      value={{
        showModal,
        setShowModal,
        isLoadingModal,
        setIsLoadingModal,
        reportment,
        setReportment,
        reportments,
        setReportments,
      }}
    >
      <Outlet />
    </ReportmentAuditContext.Provider>
  )
}

export default ReportmentAuditContext
