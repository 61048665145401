export default {
  common: {
    month: {
      january: 'Janeiro',
      february: 'Fevereiro',
      march: 'Março',
      april: 'Abril',
      may: 'Maio',
      june: 'Junho',
      july: 'Julho',
      august: 'Agosto',
      september: 'Setembro',
      october: 'Outubro',
      november: 'Novembro',
      december: 'Dezembro'
    },
    dayOfWeek: {
      sunday: 'Domingo',
      monday: 'Segunda-feira',
      tuesday: 'Terça-feira',
      wednesday: 'Quarta-feira',
      thursday: 'Quinta-feira',
      friday: 'Sexta-feira',
      saturday: 'Sábado'
    },
    dayOfMonth: {
      '1st': '1º',
      '2nd': '2º',
      '3rd': '3º',
      '4th': '4º',
      '5th': '5º',
      '6th': '6º',
      '7th': '7º',
      '8th': '8º',
      '9th': '9º',
      '10th': '10º',
      '11th': '11º',
      '12th': '12º',
      '13th': '13º',
      '14th': '14º',
      '15th': '15º',
      '16th': '16º',
      '17th': '17º',
      '18th': '18º',
      '19th': '19º',
      '20th': '20º',
      '21st': '21º',
      '22nd': '22º',
      '23rd': '23º',
      '24th': '24º',
      '25th': '25º',
      '26th': '26º',
      '27th': '27º',
      '28th': '28º',
      '29th': '29º',
      '30th': '30º',
      '31st': '31º'
    }
  },
  tabs: {
    seconds: 'Segundos',
    minutes: 'Minutos',
    hours: 'Horas',
    day: 'Dia',
    month: 'Mês',
    year: 'Ano'
  },
  quartz: {
    day: {
      every: {
        label: 'Todos os dias'
      },
      dayOfWeekIncrement: {
        label1: 'Todos os',
        label2: 'dias começando em'
      },
      dayOfMonthIncrement: {
        label1: 'Todos os',
        label2: 'dias começando no',
        label3: 'do mês'
      },
      dayOfWeekAnd: {
        label: 'Dia específico da semana (escolha um ou mais)'
      },
      dayOfWeekRange: {
        label1: 'Todos os dias entre',
        label2: 'e'
      },
      dayOfMonthAnd: {
        label: 'Dia específico do mês (escolha um ou mais)'
      },
      dayOfMonthLastDay: {
        label: 'No último dia do mês'
      },
      dayOfMonthLastDayWeek: {
        label: 'No último dia útil do mês'
      },
      dayOfWeekLastNTHDayWeek: {
        label1: 'No último',
        label2: 'do mês'
      },
      dayOfMonthDaysBeforeEndMonth: {
        label: 'dias antes do fim do mês'
      },
      dayOfMonthNearestWeekDayOfMonth: {
        label1: 'Dia útil mais próximo (segunda a sexta) do',
        label2: 'do mês'
      },
      dayOfWeekNTHWeekDayOfMonth: {
        label1: 'No',
        label2: 'do mês'
      }
    },
    month: {
      every: {
        label: 'Todo mês'
      },
      increment: {
        label1: 'Todo',
        label2: 'mês(es) a partir do mês',
      },
      and: {
        label: 'Mês específico (escolha um ou mais)'
      },
      range: {
        label1: 'Todo mês entre o mês',
        label2: 'e o mês'
      }
    },
    second: {
      every: {
        label: 'Todo segundo'
      },
      increment: {
        label1: 'Todo',
        label2: 'segundo(s) a partir do segundo',
      },
      and: {
        label: 'Segundo específico (escolha um ou mais)'
      },
      range: {
        label1: 'Todo segundo entre o segundo',
        label2: 'e o segundo'
      }
    },
    minute: {
      every: {
        label: 'Todo minuto'
      },
      increment: {
        label1: 'Todo',
        label2: 'minuto(s) a partir do minuto',
      },
      and: {
        label: 'Minuto específico (escolha um ou mais)'
      },
      range: {
        label1: 'Todo minuto entre o minuto',
        label2: 'e o minuto'
      }
    },
    hour: {
      every: {
        label: 'Toda hora'
      },
      increment: {
        label1: 'Toda',
        label2: 'hora(s) a partir da hora',
      },
      and: {
        label: 'Hora específica (escolha uma ou mais)'
      },
      range: {
        label1: 'Toda hora entre a hora',
        label2: 'e a hora'
      }
    },
    year: {
      every: {
        label: 'Qualquer ano'
      },
      increment: {
        label1: 'Todo',
        label2: 'ano(s) a partir do ano',
      },
      and: {
        label: 'Ano específico (escolha um ou mais)'
      },
      range: {
        label1: 'Todo ano entre o ano',
        label2: 'e o ano'
      }
    }
  },
  unix: {
    day: {
      every: {
        label: 'Todo dia'
      },
      dayOfWeekIncrement: {
        label1: 'Todo',
        label2: 'dia(s) da semana'
      },
      dayOfMonthIncrement: {
        label1: 'Todo',
        label2: 'dia(s) do mês'
      },
      dayOfWeekAnd: {
        label: 'Dia específico da semana (escolha um ou mais)'
      },
      dayOfMonthAnd: {
        label: 'Dia específico do mês (escolha um ou mais)'
      }
    },
    month: {
      every: {
        label: 'Todo mês'
      },
      increment: {
        label1: 'Todo',
        label2: 'mês(es)',
      },
      and: {
        label: 'Mês específico (escolha um ou mais)'
      },
      range: {
        label1: 'Todo mês entre o mês',
        label2: 'e o mês'
      }
    },
    minute: {
      every: {
        label: 'Todo minuto'
      },
      increment: {
        label1: 'Todo',
        label2: 'minuto(s)',
      },
      and: {
        label: 'Minuto específico (escolha um ou mais)'
      },
      range: {
        label1: 'Todo minuto entre o minuto',
        label2: 'e o minuto'
      }
    },
    hour: {
      every: {
        label: 'Toda hora'
      },
      increment: {
        label1: 'Toda',
        label2: 'hora(s)',
      },
      and: {
        label: 'Hora específica (escolha uma ou mais)'
      },
      range: {
        label1: 'Toda hora entre a hora',
        label2: 'e a hora'
      }
    }
  }
}
