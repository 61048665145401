import React, { useContext, useEffect, useMemo, useState } from 'react'
import PermissionProfileContext from '@contexts/PermissionProfile'
import IPermissionProfileAssign from '@interfaces/IPermissionProfileAssign'
import CreatableSelect from 'react-select/creatable'
import { Spinner } from 'react-bootstrap'
import * as $Client from '@services/Client'
import * as $School from '@services/School'
import * as $Student from '@services/Users/Student'
import IClient from '@interfaces/IClient'
import IUser from '@interfaces/IUser'
import ISchool from '@interfaces/ISchool'
import * as Utils from '@helpers/Utils'
import Select from '@components/Select/Select'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Buttons } from './PermissionProfileAssigns.styles'

const PermissionProfileAssigns: React.FC<any> = () => {
  const { permissionProfileId, permissionProfile, setPermissionProfile } = useContext(PermissionProfileContext)

  const [ showAssociation, setShowAssociation ] = useState<boolean>(false)
  const [ clients, setClients ] = useState<any[]>([])
  const [ schools, setSchools ] = useState<any[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ client, setClient ] = useState<any>({
    id: 0,
    name: '',
  } as any)
  const [ school, setSchool ] = useState<any>({
    id: null,
    name: null,
  } as any)
  const [ users, setUsers ] = useState<any[]>([])
  const [ user, setUser ] = useState<any>({
    id: null,
    name: null,
  } as any)

  useEffect(() => {
    $Client.all().then(({ data }: any) => setClients(data)).finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    if (client.id > 0) {
      setIsLoading(true)
      setSchools([])
      setUsers([])
      setSchool({
        id: null,
        name: null,
      })
      setUser({
        id: null,
        name: null,
      })
      $School.findByClient(client.id).then(({ data }: any) => setSchools(data)).finally(() => setIsLoading(false))
    }
  }, [client])

  useEffect(() => {
    setUsers([])
    if (client.id > 0 && school.id > 0) {
      $Student.all(school.id, {
        select: 'id,name',
      }).then(({ data }: any) => setUsers(data))
    }
  }, [client, school])

  const clientOptions = useMemo(() => clients.map((client: IClient) => ({
    value: client.id,
    label: client.name,
  })), [clients])

  const schoolOptions = useMemo(() => {
    if (!client.id)
      return []

    const schoolOptions: any[] = schools.map((school: ISchool) => ({
      value: school.id,
      label: school.fantasyName,
    }))

    schoolOptions.unshift({
      value: -1,
      label: 'Cadastrar sem escola',
    })

    return schoolOptions
  }, [client.id, schools])

  const userOptions = useMemo(() => {
    if (!school.id)
      return []

    const userOptions: any[] = users.map((user: IUser) => ({
      value: user.id,
      label: user.name,
    }))

    userOptions.unshift({
      value: null,
      label: 'Cadastrar sem usuário',
    })

    return userOptions
  }, [school.id, users])

  const handleClientIdChange = ({ label: name, value: id }: any) => setClient({
    id,
    name,
  })

  const handleSchoolIdChange = ({ label: name, value: id }: any) => setSchool({
    id,
    name,
  })

  const handleUserIdChange = ({ label: name, value: id }: any) => setUser({
    id: id,
    name: name,
  })

  const onDelete = (permissionProfileAssignId: number) => setPermissionProfile({
    ...permissionProfile,
    permissionProfileAssigns: permissionProfile.permissionProfileAssigns.filter(permissionProfileAssign => permissionProfileAssign.id !== permissionProfileAssignId),
  })

  const onSubmit = () => {
    if (!Array.isArray(permissionProfile.permissionProfileAssigns)) {
      permissionProfile.permissionProfileAssigns = []
    }

    permissionProfile.permissionProfileAssigns.push({
      id: -Utils.random(),
      permissionProfileId: permissionProfile.id,
      clientId: client.id,
      clientName: client.name,
      schoolId: school.id > 0 ? school.id : null,
      schoolName: school.name,
      userId: user.id,
      userName: user.name
    } as IPermissionProfileAssign)

    setPermissionProfile({ ...permissionProfile })
  }

  const onCancel = () => {
    setShowAssociation(false)
    setClient({
      id: 0,
      name: '',
    })
    setSchool({
      id: null,
      name: null,
    })
    setUser({
      id: null,
      name: null,
    })
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="mb-0">Associações</h6>
          <button
            className="btn btn-primary pe-3 ps-3"
            disabled={showAssociation}
            onClick={() => setShowAssociation(true)}
          >Associar cliente/escola</button>
        </div>
      </div>

      {showAssociation && (
        <div className="card-body pb-0 mb-2 border-bottom">
          <div className="row">
            <div className="col-12 col-md-4 mb-3">
              <div className="form-group">
                <label htmlFor="clientId">Cliente:</label>
                {clients.length ? (
                  <Select
                    type="primary"
                    onChange={handleClientIdChange}
                    options={clientOptions}
                    placeholder="Selecione um cliente"
                    isDisabled={!clients.length}
                    isSearchable
                    required
                  />
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-3 mb-3">
              <div className="form-group">
                <label htmlFor="clientId">Escola:</label>
                {!isLoading ? (
                  <Select
                    type="primary"
                    onChange={handleSchoolIdChange}
                    options={schoolOptions}
                    placeholder="Selecione a escola"
                    isDisabled={!client.id || !schools.length}
                    isSearchable
                    required
                  />
                ) :
                  (
                    <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                      <Skeleton count={1} height={36} />
                    </SkeletonTheme>
                  )}
              </div>
            </div>

            <div className="col-12 col-md-3 mb-3">
              <div className="form-group">
                <label htmlFor="userId">Usuário:</label>
                {!isLoading ? (
                  <CreatableSelect
                    type="primary"
                    onChange={handleUserIdChange}
                    options={userOptions}
                    placeholder="Selecione ou digite o ID do usuário"
                    isDisabled={!school.id && school.id !== -1}
                    isSearchable
                    required
                  />
                ) : (
                  <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                    <Skeleton count={1} height={36} />
                  </SkeletonTheme>
                )}
              </div>
            </div>

            <div className="col-12 col-md-2 d-flex align-items-end mb-3">
              <Buttons>
                <button className="btn btn-primary btn-sm pe-3 ps-3" disabled={!client.id} onClick={onSubmit}>Associar</button>
                <button className="btn btn-outline-danger btn-sm pe-3 ps-3" onClick={onCancel}>Fechar</button>
              </Buttons>
            </div>
          </div>
        </div>
      )}

      <div className="card-body p-0">
        {(permissionProfileId && permissionProfile.id) || !permissionProfileId ? (
          <div className="table-responsive">
            <table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>Cliente</th>
                  <th>Escola</th>
                  <th>Usuário</th>
                  <th className="text-right" style={{ width: 70 }}>Excluir</th>
                </tr>
              </thead>

              <tbody>
                {permissionProfile?.permissionProfileAssigns?.length ? permissionProfile.permissionProfileAssigns.map(permissionProfileAssign => (
                  <tr key={permissionProfileAssign.id}>
                    <td>
                      {permissionProfileAssign.clientName ?? (
                        <span className="text-muted">N/A</span>
                      )}
                    </td>
                    <td>
                      {permissionProfileAssign.schoolName ?? (
                        <span className="text-muted">N/A</span>
                      )}
                    </td>
                    <td>
                      {permissionProfileAssign.userName ?? (
                        <span className="text-muted">N/A</span>
                      )}
                    </td>
                    <td>
                      <button className="btn btn-outline-danger btn-sm pe-3 ps-3" onClick={() => onDelete(permissionProfileAssign.id)}>Excluir</button>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={3} className="text-center">
                      <span className="text-muted">Nenhuma associação encontrada</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="d-flex justify-content-center p-3">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    </div>
  )
}

export default PermissionProfileAssigns
