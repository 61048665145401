import React, { useCallback, useContext, useEffect, useState } from 'react'
import IUser from '@interfaces/IUser'
import * as $Manager from '@services/Users/Manager'
import * as $Platform from '@services/Platform'
import { Link, useNavigate, useParams } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import useUpdateEffect from '@hooks/useUpdateEffect'
import Swal from 'sweetalert2'
import AuthContext from '@contexts/Auth'
import axios from 'axios'
import IPlatform from '@interfaces/IPlatform'

const ManagersEdit: React.FC<any> = () => {
  const { client } = useContext(AuthContext)

  const [ platformIds, setPlatformIds ] = useState<number[]>([])
  const [ platforms, setPlatforms ] = useState<IPlatform[]>([])
  const [ manager, setManager ] = useState<IUser>({} as IUser)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const { id: managerId, schoolId }: any = useParams()

  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)

  useUpdateEffect(() => {
    if (client.id !== manager.clientId) {
      navigate('/gestores')
    }
  }, [client])

  useEffect(() => {
    axios.all([
      $Manager.find(schoolId, managerId),
      $Manager.platforms(schoolId, managerId),
      $Platform.allFromSchoolId(schoolId),
    ]).then(axios.spread(({ data: manager }: any, { data: platformIds }: any, { data: platforms }: any) => {
      setManager(manager)
      setPlatformIds(platformIds)
      setPlatforms(platforms.reduce((acc: IPlatform[], platform: IPlatform) => {
        if (!acc.find(p => p.id === platform.id)) acc.push(platform)
        return acc
      }, []))
    })).finally(() => setIsLoading(false))
  }, [schoolId, managerId])

  const handlePlatformIdChange = ({ target: { checked, value: value } }: React.ChangeEvent<HTMLInputElement>) => {
    const platformId: number = parseInt(value)

    if (checked) {
      setPlatformIds([ ...platformIds, platformId ])
    } else {
      setPlatformIds(platformIds.filter(id => id !== platformId))
    }
  }

  const handleUserChange = (e: any) => {
    const { name, value } = e.target

    setManager({
      ...manager,
      [name]: value,
    })
  }

  const update = useCallback(() => {
    setIsLoading(true)

    SweetAlert.showLoading()

    $Manager.update(schoolId, manager.id as number, {
      name: manager.name,
      email: manager.email,
      nickname: manager?.nickname,
      identifier: manager?.identifier,
    } as IUser).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Gestor atualizado com sucesso!',
        icon: 'success'
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao atualizar o gestor!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }, [SweetAlert, schoolId, manager])

  const updatePlatforms = useCallback(() => {
    setIsLoading(true)

    SweetAlert.showLoading()

    $Manager.updatePlatforms(schoolId, manager.id as number, platformIds).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Plataformas atualizadas com sucesso!',
        icon: 'success'
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao atualizar as platformas!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }, [SweetAlert, schoolId, manager.id, platformIds])

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/gestores" className="btn btn-outline-primary">Voltar</Link>
      </div>

      <div className="card mb-3">
        <div className="card-header">
          Editar gestor ({manager?.nickname})
        </div>

        <div className="card-body pb-0">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="nickname">Apelido</label>
                <input type="text" className="form-control" name="nickname" id="nickname" placeholder="Apelido" defaultValue={manager?.nickname} onChange={handleUserChange} />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="name">Nome</label>
                <input type="text" className="form-control" name="name" id="name" placeholder="Nome" defaultValue={manager?.name} onChange={handleUserChange} />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="email">E-mail</label>
                <input type="email" className="form-control" name="email" id="email" placeholder="E-mail" defaultValue={manager?.email} onChange={handleUserChange} />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="identifier">RA</label>
                <input type="identifier" className="form-control" name="identifier" id="identifier" placeholder="RA" defaultValue={manager?.identifier} onChange={handleUserChange} />
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <button className="btn btn-primary" onClick={update} disabled={isLoading}>Atualizar cadastro</button>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          Associação de plataformas
        </div>

        <div className="card-body p-0">
          <table className="table table-striped mb-0">
            <thead>
              <tr>
                {platforms.map((platform: IPlatform) => (
                  <th className="text-center" key={platform.id}>{platform.name}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              <tr>
                {platforms.map((platform: IPlatform) => (
                  <td className="text-center" key={platform.id}>
                    <input
                      type="checkbox"
                      value={platform.id}
                      checked={platformIds.includes(platform.id)} onChange={handlePlatformIdChange}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <button className="btn btn-primary" onClick={updatePlatforms} disabled={isLoading}>Atualizar plataformas</button>
        </div>
      </div>
    </>
  )
}

export default ManagersEdit
