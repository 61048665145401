import React, { useCallback, useContext, useState } from 'react'
import StudentAndProductsContext from '@contexts/StudentAndProducts'
import IDegreeGroup from '@interfaces/IDegreeGroup'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Section, DegreeGroup } from './Association.styles'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { getCssProperty } from '@helpers/Theme'
import * as $Student from '@services/Users/Student'
import IPlatformAssociationProduct from '@interfaces/PlatformAssociation/IPlatformAssociationProduct'
import IPlatformAssociationSelection from '@interfaces/PlatformAssociation/IPlatformAssociationSelection'

const Association: React.FC<any> = () => {
  const { student, isLoading, setIsLoading, degreeGroups, selectedAssociation, setSelectedAssociation, refresh } = useContext(StudentAndProductsContext)

  const [ activeDegreeGroupIds, setActiveDegreeGroupIds ] = useState<number[]>([])

  const SweetAlert = withReactContent(Swal)

  const addPlatform = useCallback((platformId: number, productId?: number) => {
    const platformIndex = selectedAssociation.findIndex((platform: IPlatformAssociationProduct) => platform.id === platformId)

    if (platformIndex === -1) {
      selectedAssociation.push({
        id: platformId,
        productIds: productId ? [ productId ] : [],
      })
    } else if (platformIndex !== -1 && productId) {
      const productIndex = selectedAssociation[platformIndex].productIds.findIndex((id: number) => id === productId)

      if (productIndex === -1) {
        selectedAssociation[platformIndex].productIds.push(productId)
      }
    }

    setSelectedAssociation([ ...selectedAssociation ])
  }, [selectedAssociation, setSelectedAssociation])

  const removePlatform = useCallback((platformId: number, productId?: number) => {
    const platformIndex = selectedAssociation.findIndex((platform: IPlatformAssociationProduct) => platform.id === platformId)

    if (platformIndex !== -1) {
      if (productId) {
        const productIndex = selectedAssociation[platformIndex].productIds.findIndex((id: number) => id === productId)

        if (productIndex !== -1) {
          selectedAssociation[platformIndex].productIds.splice(productIndex, 1)

          if (selectedAssociation[platformIndex].productIds.length === 0) {
            selectedAssociation.splice(platformIndex, 1)
          }
        }
      } else {
        selectedAssociation.splice(platformIndex, 1)
      }
    }

    setSelectedAssociation([ ...selectedAssociation ])
  }, [selectedAssociation, setSelectedAssociation])

  const handleActiveDegreeGroupId = (degreeGroupId: number) => {
    if (activeDegreeGroupIds.includes(degreeGroupId)) {
      setActiveDegreeGroupIds(activeDegreeGroupIds.filter((id: number) => id !== degreeGroupId))
    } else {
      setActiveDegreeGroupIds([...activeDegreeGroupIds, degreeGroupId])
    }
  }

  const isChecked = useCallback((platformId: number, productId?: number) => {
    if (selectedAssociation?.length > 0) {
      const platform: IPlatformAssociationProduct = selectedAssociation.find((platform: IPlatformAssociationProduct) => platform.id === platformId) as IPlatformAssociationProduct

      if (platform) {
        if (productId) {
          return platform.productIds.includes(productId)
        } else {
          return true
        }
      }
    }

    return false
  }, [selectedAssociation])

  const onChange = (platformId: number, productId?: number) => {
    const checked = isChecked(platformId, productId)

    if (!checked) {
      addPlatform(platformId, productId)
    } else {
      removePlatform(platformId, productId)
    }
  }

  const confirm = () => {
    setIsLoading(true)
    SweetAlert.showLoading()

    $Student.associate(student.schoolId as number, student.id, {
      degreeId: student.degreeId,
      classId: student.classId,
      platforms: selectedAssociation,
    } as IPlatformAssociationSelection).then(() => SweetAlert.fire({
      icon: 'success',
      title: 'Alunos associados com sucesso!',
      text: 'Os alunos foram associados com sucesso as plataformas selecionadas.',
    })).then(() => refresh()).catch(() => SweetAlert.fire({
      icon: 'error',
      title: 'Erro ao associar alunos!',
      text: 'Ocorreu um erro ao associar os alunos a plataformas selecionadas.',
    })).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <div className="card mb-4">
      <div className="card-header">Associação</div>

      <div className="card-body pb-0">
        {degreeGroups.map((items: IDegreeGroup[]) => {
          const degreeGroup = items[0]
          const isActive: boolean = activeDegreeGroupIds.includes(degreeGroup.degreeGroupId)

          return (
            <Section
              key={degreeGroup.degreeGroupId}
              className="mb-3"
            >
              <DegreeGroup.Header onClick={() => handleActiveDegreeGroupId(degreeGroup.degreeGroupId)}>
                <label>{items[0]?.degreeGroupName}</label>
                <div>
                  {isActive ? (
                    <FiChevronUp
                      color={getCssProperty('--secondary-color')}
                      size={20}
                    />
                  ) : (
                    <FiChevronDown
                      color={getCssProperty('--secondary-color')}
                      size={20}
                    />
                  )}
                </div>
              </DegreeGroup.Header>

              {isActive && items.length > 0 && (
                <DegreeGroup.Items className="pt-3">
                  {items.map((item: IDegreeGroup) => {
                    const checked: boolean = isChecked(item.platformId, item.productId)
                    const currentAccessAmount = checked ? item.currentAccessAmount + 1 : item.currentAccessAmount

                    return (
                      <DegreeGroup.Item key={degreeGroup.degreeGroupId + '-' + item.productId}>
                        <div className="form-check d-flex">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ minWidth: 14 }}
                            checked={checked}
                            onChange={() => onChange(item.platformId, item.productId)}
                          />
                          <label className="form-check-label">{item.productName ?? item.platformName}</label>
                        </div>

                        <div className={'badge p-1 ' + (currentAccessAmount === 0 || item.maxAccessAmount === -1 ? 'bg-secondary' : (currentAccessAmount > item.maxAccessAmount ? 'bg-danger' : 'bg-primary'))}>
                          {currentAccessAmount} {item.maxAccessAmount !== -1 ? `/ ${item.maxAccessAmount}` : ''}
                        </div>
                      </DegreeGroup.Item>
                    )
                  })}
                </DegreeGroup.Items>
              )}
            </Section>
          )
        })}
      </div>

      <div className="card-footer d-flex justify-content-end">
        <button
          className="btn btn-primary"
          onClick={confirm}
          disabled={isLoading || !selectedAssociation?.length}
        >Confirmar</button>
      </div>
    </div>
  )
}

export default Association
