import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import * as $Permission from '@services/Permission'
import IPermission from '@interfaces/IPermission'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { Buttons } from './Permissions.styles'
import * as Utils from '@helpers/Utils'
import Permission from './Permission/Permission'

const Permissions: React.FC<any> = () => {
  const [ permission, setPermission ] = useState<IPermission|null>(null)
  const [ permissions, setPermissions ] = useState<IPermission[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)

  const getPermissions = () => {
    $Permission.all().then(({ data }: any) => setPermissions(data)).finally(() => setIsLoading(false))
  }

  useEffect(getPermissions, [])

  const update = () => {
    if (permission === null || !permission?.id)
      return

    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde',
      text: 'Salvando atualizações...',
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $Permission.update(permission).then(() => {
      getPermissions()

      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Permissão atualizada com sucesso.',
        icon: 'success',
        didOpen: () => SweetAlert.hideLoading(),
      })
    }).catch(() => setIsLoading(false))
  }

  const save = () => {
    setIsLoading(true)

    if (permission === null)
      return

    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde',
      text: 'Cadastrando permissão...',
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $Permission.store(permission).then(({ data: permission }: any) => {
      getPermissions()
      setPermission(permission)

      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Permissão cadastrada com sucesso.',
        icon: 'success',
        didOpen: () => SweetAlert.hideLoading(),
      })
    }).catch(() => setIsLoading(false))
  }

  const cancel = () => setPermission(null)

  return (
    <>
      <Buttons className="row">
        <div className="col-12 col-sm-8 mb-3 d-flex">
          <button
            className="btn btn-primary ps-4 pe-4 me-1"
            disabled={isLoading}
            onClick={() => setPermission(null)}
          >Permissões</button>

          <button
            className="btn btn-outline-primary ps-4 pe-4 ms-1"
            disabled={isLoading}
            onClick={() => navigate('/perfil-de-permissoes')}
          >Perfil de permissões</button>
        </div>

        <div className="col-12 col-sm-4 mb-3 d-flex justify-content-sm-end">
          <button
            className="btn btn-primary ps-4 pe-4"
            disabled={isLoading || permission !== null}
            onClick={() => setPermission({
              id: -Utils.random(),
            } as IPermission)}
          >Cadastrar permissão</button>
        </div>
      </Buttons>

      <div className="row">
        <div className={(!permission ? 'col-12 col-md-12' : 'col-12 col-md-8') + ' order-2 order-md-1'}>
          <div className="card mb-3">
            <div className="card-header">
              Permissões
            </div>

            <div className="card-body p-0">
              {!isLoading || permissions.length ? (
                <div className="table-responsive">
                  <table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Permissão</th>
                        <th style={{ width: 120 }}>Ações</th>
                      </tr>
                    </thead>

                    <tbody>
                      {permissions.length > 0 ? permissions.map((item: IPermission) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>
                            <button
                              className="btn btn-sm ps-4 pe-4 btn-primary"
                              onClick={() => setPermission(item)}
                              disabled={item.id === permission?.id || isLoading}
                            >Editar</button>
                          </td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan={2} style={{ textAlign: 'center' }}>Nenhum resultado encontrado.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="d-flex justify-content-center p-3">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>
          </div>
        </div>

        {permission !== null && (
          <div className="col-12 col-md-4 order-1 order-md-2">
            <Permission
              permission={permission}
              setPermission={setPermission}
              onSave={() => permission?.id > 0 ? update() : save()}
              onCancel={cancel}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Permissions
