import styled from 'styled-components'

export const Search = styled.div`
  @media (min-width: 576px) {
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
  }
`

export const Buttons = styled.div`
  @media (max-width: 768px) {
    > .btn {
      width: 100%;
      display: block;
    }
  }
`
