import React, { useState } from 'react'
import Button from '@components/Button/Button'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as $ReportCard from '@services/ReportCard'

const UpdateCache: React.FC<any> = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ assessmentId, setAssessmentId ] = useState<number>(0)

  const SweetAlert = withReactContent(Swal)

  const handleAssessmentIdChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setAssessmentId(Number(value))

  const update = () => {
    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Atualizando cache...',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $ReportCard.updateCache(assessmentId).then(() => SweetAlert.fire({
      title: 'Sucesso!',
      text: 'Boletim atualizado com sucesso.',
      icon: 'success'
    })).catch(() => SweetAlert.fire({
      title: 'Erro!',
      text: 'Falha ao atualizar boletim.',
      icon: 'error'
    })).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <div className="card">
      <div className="card-header">
        Gerar Boletim
      </div>

      <div className="card-body">
        <div className="row pt-5 pb-5">
          <div className="col-12 col-md-6 offset-md-3">
            <div className="form-group mb-3">
              <label htmlFor="ID">ID do Simulado</label>
              <input
                id="assessmentId"
                name="assessmentId"
                className="form-control"
                placeholder="Insira o ID do Simulado"
                onChange={handleAssessmentIdChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card-footer d-flex justify-content-end">
        <Button
          className="btn btn-primary"
          disabled={isLoading || assessmentId <= 0}
          isLoading={isLoading}
          onClick={update}
        >
          Gerar
        </Button>
      </div>
    </div>
  )
}

export default UpdateCache
