const columns: any[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Nome',
    accessor: 'name',
  },
  {
    Header: 'E-mail',
    accessor: 'email',
  },
]

export default columns
