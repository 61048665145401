import React, { createContext, useState } from 'react'
import useStickyState from '../hooks/useStickyState'
import { Outlet } from 'react-router-dom'

interface IPanelContext {
  sidebarCollapsed: boolean
  setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  activeMenuPath: string
  setActiveMenuPath: React.Dispatch<React.SetStateAction<string>>
}

const PanelContext = createContext<IPanelContext>({ } as IPanelContext)

export const PanelProvider: React.FC<any> = () => {
  const [ sidebarCollapsed, setSidebarCollapsed ] = useStickyState<boolean>(false, 'sidebarCollapsed')
  const [ activeMenuPath, setActiveMenuPath ] = useState<string>('/')

  const value = {
    sidebarCollapsed,
    setSidebarCollapsed,
    activeMenuPath,
    setActiveMenuPath,
  }

  return (
    <PanelContext.Provider value={value}>
      <Outlet />
    </PanelContext.Provider>
  )
}

export default PanelContext
