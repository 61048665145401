import React, { useContext, useState } from 'react'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'
import * as $EntityRegistration from '@services/Users/Registration'
import RegistrationContext from '@contexts/Registration/Registration'
import Button from '@components/Button/Button'

type IDeleteProps = {
  entity: IRegistrationEntity;
}

const Delete: React.FC<any> = ({ entity }: IDeleteProps) => {
  const { token, setValidations, removeEntity } = useContext(RegistrationContext)

  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const destroy = () => {
    if (entity?.id) {
      setIsLoading(true)

      $EntityRegistration.registrationDestroy(token, entity.id).then(({ data: validations }: any) => {
        setValidations(validations)
        removeEntity(entity)
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }

  return (
    <Button className="btn btn-sm btn-danger text-white" onClick={destroy} disabled={isLoading} isLoading={isLoading}>Descartar</Button>
  )
}

export default Delete
