import React from 'react'
import { ButtonProps } from 'react-bootstrap'
import { Spinner } from 'react-bootstrap'

type Props = ButtonProps & {
  isLoading: boolean,
  loadingText?: string,
}

const Button: React.FC<Props> = ({ isLoading, loadingText, children, ...props }: Props) => {
  return (
    <button {...props}>
      <span>{isLoading ? (loadingText ?? children) : children}</span> {isLoading ? <Spinner animation="border" variant="light" size="sm" className="ms-2" /> : null}
    </button>
  )
}

export default Button
