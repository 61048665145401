import React, { createRef, useEffect } from 'react'
import IPermission from '@interfaces/IPermission'
import { Card } from './../Permissions.styles'

type Props = {
  isLoading: boolean
  permission: IPermission
  setPermission: React.Dispatch<React.SetStateAction<IPermission>>
  onSave: (permission: IPermission) => void
  onCancel: () => void
}

const Permission: React.FC<any> = ({ isLoading, permission, setPermission, onSave, onCancel }: Props) => {
  const inputRef = createRef<any>()

  useEffect(() => {
    inputRef.current.value = permission.name ?? ''
  }, [inputRef, permission])

  const handleNameChange = ({ target: { name, value } }: any) => setPermission({
    ...permission,
    [name]: value.replace(/\s/g, '_').toUpperCase(),
  })

  const handleOnSave = () => onSave(permission)

  return (
    <Card className="card mb-3">
      <div className="card-header">
        {permission?.id > 0 ? 'Editar Permissão' : 'Nova Permissão'}
      </div>

      <div className="card-body">
        <div className="form-group">
          <label htmlFor="name">Permissão:</label>
          <input
            id="name"
            type="text"
            name="name"
            ref={inputRef}
            className="form-control"
            disabled={isLoading}
            defaultValue={permission.name ?? ''}
            onChange={handleNameChange}
          />
        </div>
      </div>

      <div className="card-footer">
        <div className="row">
          <div className="col-6">
            <button className="btn btn-outline-primary pe-2 pe-lg-4 ps-2 ps-lg-4" onClick={onCancel} disabled={isLoading}>Fechar</button>
          </div>

          <div className="col-6 d-flex justify-content-end">
            <button className="btn btn-primary pe-2 pe-lg-4 ps-2 ps-lg-4" onClick={handleOnSave} disabled={isLoading}>
              {permission.id > 0 ? 'Atualizar' : 'Salvar'}
            </button>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Permission
