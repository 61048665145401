import React from 'react'
import { Outlet } from 'react-router-dom'
import { ReactComponent as Logotipo } from './../../resources/svg/evolucional.svg'
import { Layout } from './Auth.styles'

const Auth: React.FC = () => {
  return (
    <Layout className="auth-layout">
      <header className="main-header">
        <figure className="logotipo">
          <a href="/">
            <Logotipo />
          </a>
        </figure>
      </header>

      <main className="main-content">
        <section className="auth-container">
          <Outlet />
        </section>
      </main>
    </Layout>
  )
}

export default Auth
