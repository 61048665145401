import React, { useContext, useEffect, useMemo, useState } from 'react'
import ReportmentContext from '@contexts/Reportment'
import IReportmentSchool from '@interfaces/IReportmentSchool'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Select from '@components/Select/Select'
import CreatableSelect from 'react-select/creatable'
import * as $Client from '@services/Client'
import * as $Network from '@services/Network'
import * as $School from '@services/School'
import IClient from '@interfaces/IClient'
import ISchool from '@interfaces/ISchool'
import * as Utils from '@helpers/Utils'
import { Buttons } from './ReportmentSchools.styles'
import * as $Reportment from '@services/Reportment'
import IUser from '@interfaces/IUser'
import INetwork from '@interfaces/INetwork'

const ReportmentSchools: React.FC = () => {
  const { reportment, setReportment, showAssociation, setShowAssociation } = useContext(ReportmentContext)

  const [ clients, setClients ] = useState<any[]>([])
  const [ schools, setSchools ] = useState<any[]>([])
  const [ networks, setNetworks ] = useState<any[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ client, setClient ] = useState<any>({
    id: 0,
    name: '',
  } as any)
  const [ school, setSchool ] = useState<any>({
    id: 0,
    name: null,
  } as any)
  const [ network, setNetwork ] = useState<any>({
    id: 0,
    name: null,
  } as any)
  const [ users, setUsers ] = useState<any[]>([])
  const [ user, setUser ] = useState<any>({
    id: 0,
    name: null,
  } as any)

  useEffect(() => {
    $Client.all().then(({ data }: any) => setClients(data)).finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    if (client.id > 0) {
      setIsLoading(true)
      setSchools([])
      setUsers([])
      setNetwork({ id: 0, name: null })
      setSchool({ id: 0, name: null })
      setUser({ id: 0, name: null })

      $Network.all({
        clientId: client.id,
      }).then(({ data }: any) => setNetworks(data)).finally(() => setIsLoading(false))
    } else {
      setNetworks([])
    }
  }, [client.id])

  useEffect(() => {
    if (client.id > 0 && (network.id !== 0 && network.id !== -1)) {
      setIsLoading(true)
      setUsers([])
      setSchool({ id: 0, name: null })
      setUser({ id: 0, name: null })

      $School.all({
        clientId: client.id,
        networkId: network.id > 0 ? network.id : null,
      }).then(({ data }: any) => setSchools(data)).finally(() => setIsLoading(false))
    } else {
      setSchools([])
    }
  }, [client, network.id])

  useEffect(() => {
    if (client.id > 0 && (school.id !== 0 && school.id !== -1)) {
      $Reportment.managers(client.id, school.id > 0 ? school.id : 0).then(({ data }: any) => setUsers(data))
    } else {
      setUsers([])
    }
  }, [client.id, school.id])

  const clientOptions = useMemo(() => clients.map((client: IClient) => ({
    value: client.id,
    label: client.name,
  })), [clients])

  const networkOptions = useMemo(() => {
    if (!client.id)
      return []

    const networkOptions: any[] = networks.map((network: INetwork) => ({
      value: network.id,
      label: network.name,
    }))

    networkOptions.unshift({
      value: -1,
      label: 'Cadastrar sem rede',
    })

    networkOptions.unshift({
      value: -2,
      label: 'Cadastrar todas as redes',
    })

    return networkOptions
  }, [client.id, networks])

  const schoolOptions = useMemo(() => {
    if (!client.id)
      return []

    const schoolOptions: any[] = schools.map((school: ISchool) => ({
      value: school.id,
      label: school.fantasyName,
    }))

    schoolOptions.unshift({
      value: -1,
      label: 'Cadastrar sem escola',
    })

    schoolOptions.unshift({
      value: -2,
      label: 'Cadastrar todas as escolas',
    })

    return schoolOptions
  }, [client.id, schools])

  const userOptions = useMemo(() => {
    if (!school.id)
      return []

    const userOptions: any[] = users.map((user: IUser) => ({
      value: user.id,
      label: user.name,
    }))

    userOptions.unshift({
      value: null,
      label: 'Cadastrar sem usuário',
    })

    return userOptions
  }, [school.id, users])

  const handleClientIdChange = ({ label: name, value: id }: any) => setClient({
    id,
    name,
  })

  const handleNetworkIdChange = ({ label: name, value: id }: any) => setNetwork({
    id,
    name,
  })

  const handleSchoolIdChange = ({ label: name, value: id }: any) => setSchool({
    id,
    name,
  })

  const handleUserIdChange = ({ label: name, value: id }: any) => setUser({
    id: id,
    name: name,
  })

  const onDelete = (reportmentSchoolId: number) => setReportment({
    ...reportment,
    reportmentSchools: reportment.reportmentSchools.filter((reportmentSchool: IReportmentSchool) => reportmentSchool.id !== reportmentSchoolId),
  })

  const onSubmit = () => setReportment({
    ...reportment,
    reportmentSchools: [
      ...reportment.reportmentSchools,
      {
        id: -Utils.random(),
        reportmentId: reportment.id,
        clientId: client.id,
        clientName: client.name,
        networkId: network.id > 0 ? network.id : null,
        networkName: network.name,
        schoolId: school.id > 0 ? school.id : null,
        schoolName: school.name,
        userId: user.id > 0 ? user.id : null,
        userName: user.name
      } as IReportmentSchool,
    ],
  })

  const onCancel = () => {
    setShowAssociation(false)

    setClient({ id: 0, name: null })
    setNetwork({ id: 0, name: null })
    setSchool({ id: 0, name: null })
    setUser({ id: 0, name: null })
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="mb-0">Associação cliente/escola</h6>
          <button
            type="button"
            className="btn btn-primary pe-3 ps-3"
            disabled={showAssociation}
            onClick={() => setShowAssociation(true)}
          >Associar cliente/escola</button>
        </div>
      </div>

      {showAssociation && (
        <div className="card-body pb-0 mb-2 border-bottom">
          <div className="row">
            <div className="col-12 col-md-3 mb-3">
              <div className="form-group">
                <label htmlFor="clientId">Cliente:</label>
                {clients.length ? (
                  <Select
                    type="primary"
                    onChange={handleClientIdChange}
                    options={clientOptions}
                    placeholder="Selecione um cliente"
                    isDisabled={!clients.length}
                    isSearchable
                    required
                  />
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-3 mb-3">
              <div className="form-group">
                <label htmlFor="clientId">Rede:</label>
                <Select
                  type="primary"
                  onChange={handleNetworkIdChange}
                  options={networkOptions}
                  placeholder="Selecione a rede"
                  isDisabled={!client.id || !networks.length || (network.id === 0 && isLoading)}
                  isSearchable
                  required
                />
              </div>
            </div>

            <div className="col-12 col-md-3 mb-3">
              <div className="form-group">
                <label htmlFor="clientId">Escola:</label>
                <Select
                  type="primary"
                  onChange={handleSchoolIdChange}
                  options={schoolOptions}
                  placeholder="Selecione a escola"
                  isDisabled={!client.id || !schools.length || (school.id === 0 && isLoading)}
                  isSearchable
                  required
                />
              </div>
            </div>

            <div className="col-12 col-md-3 mb-3">
              <div className="form-group">
                <label htmlFor="userId">Usuário:</label>
                <CreatableSelect
                  type="primary"
                  onChange={handleUserIdChange}
                  options={userOptions}
                  placeholder="Selecione ou digite o ID do usuário"
                  isDisabled={!school.id || school.id == -1 || (user.id === 0 && isLoading)}
                  isSearchable
                  required
                />
              </div>
            </div>

            <div className="col-12 col-md-2 d-flex align-items-end mb-3">
              <Buttons>
                <button type="button" className="btn btn-primary btn-sm pe-3 ps-3" disabled={!client.id} onClick={onSubmit}>Associar</button>
                <button type="button" className="btn btn-outline-danger btn-sm pe-3 ps-3" onClick={onCancel}>Cancelar</button>
              </Buttons>
            </div>
          </div>
        </div>
      )}

      <div className="card-body p-0">
        <div className="table-responsive">
          <table className="table table-default mb-0">
            <thead>
              <tr>
                <th>ID Cliente</th>
                <th>Cliente</th>
                <th>ID Rede</th>
                <th>Rede</th>
                <th>ID Escola</th>
                <th>Escola</th>
                <th>ID Usuário</th>
                <th>Usuário</th>
                <th style={{ width: 80 }}>Ações</th>
              </tr>
            </thead>

            <tbody>
              {reportment.reportmentSchools.length ? reportment.reportmentSchools.map((reportmentSchool: IReportmentSchool) => (
                <tr key={reportmentSchool.id}>
                  <td>{reportmentSchool.clientId}</td>
                  <td>{reportmentSchool.clientName}</td>
                  <td>
                    {reportmentSchool.networkId ?? (
                      <span className="text-muted">N/A</span>
                    )}
                  </td>
                  <td>
                    {reportmentSchool.networkId ? reportmentSchool.networkName : (
                      <span className="text-muted">N/A</span>
                    )}
                  </td>
                  <td>
                    {reportmentSchool.schoolId ?? (
                      <span className="text-muted">N/A</span>
                    )}
                  </td>
                  <td>
                    {reportmentSchool.schoolId ? reportmentSchool.schoolName : (
                      <span className="text-muted">N/A</span>
                    )}
                  </td>
                  <td>
                    {reportmentSchool.userId ?? (
                      <span className="text-muted">N/A</span>
                    )}
                  </td>
                  <td>
                    {reportmentSchool.userId ? reportmentSchool.userName : (
                      <span className="text-muted">N/A</span>
                    )}
                  </td>
                  <td>
                    <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => onDelete(reportmentSchool.id)}>Excluir</button>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan={9}>
                    <div className="text-center">
                      Nenhum cliente/escola associado.
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ReportmentSchools
