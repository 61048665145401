import React, { useCallback, useContext } from 'react'
import BulkClassesContext from '@contexts/BulkClasses'
import Button from '@components/Button/Button'
import Download from './Download/Download'
import Upload from './Upload/Upload'
import * as $Utils from '@helpers/Utils'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as $Class from '@services/Class'
import IClass from '@interfaces/IClass'

const BulkClasses: React.FC<any> = () => {
  const { token, refreshToken, classroom, setClassroom, isLoading, setIsLoading, fileRef, setFile } = useContext(BulkClassesContext)

  const SweetAlert = withReactContent(Swal)

  const store = useCallback(() => {
    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde',
      text: 'Validando...',
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading()
    })

    $Class.bulkStore(token, classroom).then(({ data: { errors } }: any) => {
      SweetAlert.fire({
        title: 'Inserção efetuada!',
        text: errors?.length > 0 ? 'A inserção foi parcialmente efetuada, verifique as turmas que já existiam em nossa base.' : 'Inserção em massa efetuada com sucesso!',
        icon: errors?.length > 0 ? 'warning' : 'success',
      }).then(() => {
        setFile(null)
        setClassroom([])
        refreshToken()

        if (errors?.length > 0) {
          const list = [
            [ 'ID da Escola', 'ID da Turma', 'Nome da Turma', 'Série' ],
          ]

          errors.forEach((classes: IClass) => list.push([
            classes.schoolId.toString(),
            classes.id?.toString(),
            classes.name?.toString() ?? '',
            classes.degreeName?.toString() ?? '',
          ]))

          $Utils.exportXLSX(list, 'Turmas', `Turmas - ${token}.xlsx`)
        }

        fileRef.current.value = null
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao efetuar a associação em massa, tente novamente!',
        icon: 'error',
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }, [SweetAlert, classroom, fileRef, refreshToken, setClassroom, setFile, setIsLoading, token])

  const deleteAll = useCallback(() => {
    SweetAlert.fire({
      title: 'Tem certeza?',
      text: 'Prosseguir e limpar todos os registros?',
      icon: 'warning',
    }).then((result) => {
      if (result.value) {
        fileRef.current.value = null
        setFile(null)
        setClassroom([])
      }
    })
  }, [SweetAlert, fileRef, setClassroom, setFile])

  return (
    <>
      <div className="card">
        <div className="card-header">
          Turmas em massa
        </div>

        <div className="card-body">
          <div className="row pt-5 pb-5">
            <div className="col-12 col-md-8 offset-md-2 text-center">
              <div className="d-flex align-items-center">
                <Download />
                <Upload />
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-between">
          <div>
            <button
              className="btn btn-danger me-2"
              onClick={deleteAll}
              disabled={!classroom?.length}
            >Descartar</button>

            <Button
              className="btn btn-primary"
              disabled={isLoading || !classroom?.length}
              isLoading={isLoading && classroom.length > 0}
              onClick={store}
            >
              Executar cadastro
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default BulkClasses
