import React, { useEffect, useState } from 'react'
import ISchedule from '@interfaces/ISchedule'
import { Link, useNavigate, useParams } from 'react-router-dom'
import $Schedule from '@services/Schedule'
import { ReUnixCron } from '@sbzen/re-cron'
import moment from 'moment'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Localization from './Localization'
import { Spinner } from 'react-bootstrap'

const Schedule: React.FC = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ schedule, setSchedule ] = useState<ISchedule>({
    expression: '* * * * *',
  } as ISchedule)

  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)

  const { id } = useParams()

  const scheduleId = (
    isNaN(Number(id)) ? null : Number(id)
  )

  useEffect(() => {
    if (scheduleId) {
      $Schedule.find(scheduleId).then(({ data }) => setSchedule(data)).finally(() => setIsLoading(false))
    } else {
      setIsLoading(false)
    }
  }, [scheduleId])

  const onCronExpressionChanged = (expression: string) => setSchedule({
    ...schedule,
    expression,
  } as ISchedule)

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)

    SweetAlert.fire({
      title: schedule?.id ? 'Atualizando agendamento' : 'Salvando agendamento',
      text: 'Aguarde...',
      didOpen: () => SweetAlert.showLoading(),
    })

    try {
      if (schedule.id) {
        await $Schedule.update(schedule.id, formData)

        SweetAlert.fire({
          icon: 'success',
          title: 'Agendamento atualizado com sucesso!',
        })
      } else {
        const { data: schedule } = await $Schedule.store(formData)

        SweetAlert.fire({
          icon: 'success',
          title: 'Agendamento salvo com sucesso!',
        }).then(() => navigate(`/agendamentos/${schedule.id}/editar`))
      }
    } catch (error) {
      console.error(error)

      SweetAlert.fire({
        icon: 'error',
        title: 'Ops! Ocorreu um erro ao salvar o agendamento',
      })
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex justify-content-end gap-3 mb-3">
        <Link to="/agendamentos" className="btn btn-outline-primary pe-3 ps-3">Voltar</Link>

        <button type="submit" className="btn btn-primary pe-3 ps-3">
          {schedule?.id ? 'Atualizar' : 'Salvar'}
        </button>
      </div>

      {(schedule?.id || !scheduleId) ? (
        <>
          <div className="card mb-3">
            <div className="card-header">
              {!schedule?.id ? (isLoading ? 'Carregando...' : 'Criar agendamento') : `Editar agendamento (${schedule.name})`}
            </div>

            <div className="card-body pb-0">
              <div className="row">
                <div className="col col-12">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Nome do agendamento:</label>
                    <input type="text" name="name" className="form-control" defaultValue={schedule.name} required />
                  </div>
                </div>

                <div className="col col-12 col-md-4">
                  <div className="form-group mb-3">
                    <label htmlFor="action">Ação:</label>
                    <select name="action" className="form-control" defaultValue={schedule.action} required>
                      <option value="query">Execução de comando SQL</option>
                      <option value="request">Requisição de URL</option>
                    </select>
                  </div>
                </div>

                <div className="col col-12 col-md-4">
                  <div className="form-group mb-3">
                    <label htmlFor="startAt">Data de início:</label>
                    <input type="datetime-local" name="startAt" className="form-control" defaultValue={moment(schedule.startAt).format('YYYY-MM-DDTHH:mm')} required />
                  </div>
                </div>

                <div className="col col-12 col-md-4">
                  <div className="form-group mb-3">
                    <label htmlFor="endAt">Data de término:</label>
                    <input type="datetime-local" name="endAt" className="form-control" defaultValue={schedule.endAt ? moment(schedule.endAt).format('YYYY-MM-DDTHH:mm') : ''} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-header">Agendamento</div>

            <div className="card-body p-0">
              <ReUnixCron
                localization={Localization}
                renderYearsFrom={new Date().getFullYear()}
                renderYearsTo={moment().add('+20', 'years').year()}
                value={schedule.expression}
                onChange={onCronExpressionChanged}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <label className="mb-0" htmlFor="command">Comando</label>
            </div>

            <div className="card-body">
              <div className="form-group">
                <textarea name="command" className="form-control" rows={5} defaultValue={schedule.command} required />
              </div>
            </div>
          </div>

          <input type="hidden" name="expression" value={schedule.expression} />
        </>
      ) : (
        <div className="card">
          <div className="card-body d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        </div>
      )}
    </form>
  )
}

export default Schedule
