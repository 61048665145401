import styled from 'styled-components'

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fff;
  transition: all 0.5s ease-out;
  visibility: hidden;
  z-index: -1;
  opacity: 0;

  > .overlay-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-50%);
    position: relative;
    top: 50%;

    > .spinner {
      width: 75px;
      height: 75px;
      content: '';
      display: block;
      -webkit-animation: spin 1.6s ease-in-out infinite;
      -moz-animation: spin 1.6s ease-in-out infinite;
      animation: spin 1.6s ease-in-out infinite;

      > svg {
        width: 100%;
        height: 100%;
        stroke: var(--primary-color);
      }

      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }

      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform:rotate(360deg);
        }
      }
    }

    > .text {
      margin-top: 20px;
    }
  }

  &.is-loading {
    opacity: 1;
    visibility: visible;
    display: block;
    z-index: 9999;
  }
`
