import styled from 'styled-components'

export const Screen = styled.div`
  > .title {
    max-width: 280px;
  }

  > .form {
    > .form-group {
      > .col-form-label {
        text-align: right;
      }

      > div {
        > .form-control {
          background-color: transparent;
        }
      }
    }

    > .button {
      > .btn {
        width: 100%;
        display: block;
      }
    }
  }
`
