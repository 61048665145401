import axios from 'axios'
import IPermissionProfile from '@interfaces/IPermissionProfile'

export const all = (): Promise<any> => {
  return axios.get('/permission-profiles')
}

export const find = (permissionProfileId: number): Promise<any> => {
  return axios.get('/permission-profiles/' + permissionProfileId)
}

export const store = (permissionProfile: IPermissionProfile): Promise<any> => {
  return axios.post('/permission-profiles', permissionProfile)
}

export const update = (permissionProfile: IPermissionProfile): Promise<any> => {
  return axios.put('/permission-profiles/' + permissionProfile.id, permissionProfile)
}
