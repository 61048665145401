import React, { useContext, useRef } from 'react'
import { Spinner } from 'react-bootstrap'
import { BiHelpCircle } from 'react-icons/bi'
import ReactTooltip from 'react-tooltip'
import ReportmentContext from '@contexts/Reportment'
import IPlatform from '@interfaces/IPlatform'
import ReportmentSchools from '@components/ReportmentSchools/ReportmentSchools'
import CodeMirror from '@uiw/react-codemirror'
import { sql } from '@codemirror/lang-sql'
import ReportmentPreview from '@components/ReportmentPreview/ReportmentPreview'
import Buttons from './Buttons/Buttons'
import Charts from './Charts/Charts'
import ImageUpload from '@components/ImageUpload/ImageUpload'
import { useNavigate } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import * as $Reportment from '@services/Reportment'
import Swal from 'sweetalert2'
import objectToFormData from '@helpers/ObjectToFormData'
import Chart from './Charts/Chart/Chart'
import Filters from './Filters/Filters'

const Reportment: React.FC<any> = () => {
  const { reportment, setReportment, platforms, categories, isLoading, setShowAssociation, setShowPreviewModal } = useContext(ReportmentContext)

  const isFreeRef = useRef<any>(null)
  const requiredFiltersRef = useRef<any>(null)
  const SweetAlert = withReactContent(Swal)

  const navigate = useNavigate()

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => setReportment({
    ...reportment,
    [name]: value,
  })

  const handleFileChange = (file: File) => setReportment({
    ...reportment,
    file,
  })

  const onSave = () => {
    SweetAlert.showLoading()

    const formData = objectToFormData(reportment)

    $Reportment.store(formData).then(({ data: reportment }: any) => SweetAlert.fire({
      title: 'Sucesso!',
      text: 'Relatório cadastrado com sucesso!',
      icon: 'success'
    }).then(() => navigate(`/relatorios/${reportment.id}/editar`))).catch((e: any) => SweetAlert.fire({
      title: 'Erro!',
      text: e.response?.data?.message ?? 'Ocorreu um erro ao salvar o relatório!',
      icon: 'error'
    })).finally(() => SweetAlert.hideLoading())
  }

  const onUpdate = () => {
    SweetAlert.showLoading()

    const formData = objectToFormData(reportment)

    $Reportment.update(reportment.id, formData).then(({ data: reportment }: any) => {
      setReportment(reportment)
      setShowAssociation(false)

      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Relatório atualizado com sucesso!',
        icon: 'success'
      })
    }).catch(() => SweetAlert.fire({
      title: 'Erro!',
      text: 'Erro ao atualizar relatório!',
      icon: 'error'
    })).finally(() => SweetAlert.hideLoading())
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (reportment.id > 0) {
      onUpdate()
    } else {
      onSave()
    }
  }

  return (
    <>
      <Chart />

      <ReportmentPreview />

      <form onSubmit={onSubmit}>
        <Buttons />

        <div className="card mb-3">
          <div className="card-header">
            {!reportment?.id ? (isLoading ? 'Carregando...' : 'Criar relatório') : `Editar relatório (${reportment?.name})`}
          </div>

          <div className="card-body pb-0">
            {reportment?.id || !isLoading ? (
              <>
                <div className="row">
                  <div className="col-12 col-md-3">
                    <ImageUpload
                      name="file"
                      label="Imagem"
                      accept="image/*"
                      className="mb-3"
                      cover={reportment?.images?.medium}
                      onFileChange={handleFileChange}
                    />
                  </div>

                  <div className="col-12 col-md-9">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group mb-3">
                          <label htmlFor="name">Título</label>
                          <input type="text" name="name" className="form-control" id="name" placeholder="Título" defaultValue={reportment?.name} onChange={handleInputChange} required />
                        </div>
                      </div>

                      <div className="col-12 col-md-3">
                        <div className="form-group mb-3">
                          <label htmlFor="platformId">Plataforma</label>
                          <select name="platformId" className="form-control" id="platformId" onChange={handleInputChange} defaultValue={reportment.platformId} required>
                            <option value="">Selecione uma plataforma</option>
                            {platforms.map((platform: IPlatform) => (
                              <option value={platform.id} key={platform.id}>{platform.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-12 col-md-3">
                        <div className="form-group mb-3">
                          <label htmlFor="reportmentCategoryId">Categoria</label>
                          <select name="reportmentCategoryId" className="form-control" id="reportmentCategoryId" onChange={handleInputChange} defaultValue={reportment.reportmentCategoryId} required>
                            <option value="">Selecione uma categoria</option>
                            {categories.map((category: any) => (
                              <option value={category.id} key={category.id}>{category.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label htmlFor="description">Descrição</label>
                          <textarea name="description" className="form-control p-3" id="description" rows={5} placeholder="Descrição" defaultValue={reportment?.description} onChange={handleInputChange} maxLength={1000} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="form-check form-switch p-0 mb-3">
                      <label className="form-check-label d-block" htmlFor="year">
                        <span className="me-1">Ano</span>
                      </label>

                      <input
                        type="number"
                        name="year"
                        className="form-control"
                        id="year"
                        placeholder="Ano"
                        defaultValue={String(reportment?.year)}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-check form-switch p-0 mb-3">
                      <label className="form-check-label d-block" htmlFor="canPreview">
                        <span className="me-1">Permitir pré-visualização</span>
                        <BiHelpCircle data-tip data-for="helpCircle" />
                        <ReactTooltip id="helpCircle" place="top" effect="solid">
                          Permitir a pré-visualização do relatório ou somente permitir a exportação do arquivo.
                        </ReactTooltip>
                      </label>

                      <select
                        id="canPreview"
                        name="canPreview"
                        className="form-control"
                        onChange={e => setReportment({
                          ...reportment,
                          canPreview: Number(e.target.value) === 1,
                        })}
                        defaultValue={reportment.canPreview ? 1 : 0}
                      >
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-check form-switch p-0 mb-3">
                      <label className="form-check-label d-block" htmlFor="isFree">
                        <span className="me-1">Execução de query livre?</span>
                        <BiHelpCircle data-tip data-for="isFreeTip" />
                        <ReactTooltip id="isFreeTip" place="top" effect="solid">
                          Permitir a execução da query livremente sem a concatenação de IDs.
                        </ReactTooltip>
                      </label>

                      <select
                        id="isFree"
                        ref={isFreeRef}
                        name="isFree"
                        className="form-control"
                        onChange={e => setReportment({
                          ...reportment,
                          isFree: Number(e.target.value) === 1,
                        })}
                        value={reportment.isFree ? 1 : 0}
                      >
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="form-check form-switch p-0 mb-3">
                      <label className="form-check-label d-block" htmlFor="requiredFilters">
                        <span className="me-1">Filtros requeridos?</span>
                        <BiHelpCircle data-tip data-for="requiredFiltersTip" />
                        <ReactTooltip id="requiredFiltersTip" place="top" effect="solid">
                          Somente exibir o flexmonster após a aplicação de todos os filtros.
                        </ReactTooltip>
                      </label>

                      <select
                        id="requiredFilters"
                        ref={requiredFiltersRef}
                        name="requiredFilters"
                        className="form-control"
                        onChange={e => setReportment({
                          ...reportment,
                          requiredFilters: Number(e.target.value) === 1,
                        })}
                        value={reportment.requiredFilters ? 1 : 0}
                      >
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="form-check form-switch p-0 mb-3">
                      <label className="form-check-label d-block" htmlFor="needsAuthorization">
                        <span className="me-1">Habilitar solicitação de acesso?</span>
                        <BiHelpCircle data-tip data-for="needsAuthorizationTip" />
                        <ReactTooltip id="needsAuthorizationTip" place="top" effect="solid">
                          Exibe o botão de solicitação de autorização de acesso ao relatório para o usuário.
                        </ReactTooltip>
                      </label>

                      <select
                        id="needsAuthorization"
                        name="needsAuthorization"
                        className="form-control"
                        onChange={e => setReportment({
                          ...reportment,
                          needsAuthorization: Number(e.target.value) === 1,
                        })}
                        value={reportment.needsAuthorization ? 1 : 0}
                      >
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="form-check form-switch p-0 mb-3">
                      <label className="form-check-label d-block" htmlFor="displayInGestao">
                        <span className="me-1">Exibir em gestão de usuários?</span>
                        <BiHelpCircle data-tip data-for="displayInGestaoTip" />
                        <ReactTooltip id="displayInGestaoTip" place="top" effect="solid">
                          Habilita a exibição do relatório no painel de gestão de usuários.
                        </ReactTooltip>
                      </label>

                      <select
                        id="displayInGestao"
                        name="displayInGestao"
                        className="form-control"
                        onChange={e => setReportment({
                          ...reportment,
                          displayInGestao: Number(e.target.value) === 1,
                        })}
                        value={reportment.displayInGestao ? 1 : 0}
                      >
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="form-check form-switch p-0 mb-3">
                      <label className="form-check-label d-block" htmlFor="displayInRelatorios">
                        <span className="me-1">Exibir em relatórios?</span>
                        <BiHelpCircle data-tip data-for="displayInRelatoriosTip" />
                        <ReactTooltip id="displayInRelatoriosTip" place="top" effect="solid">
                          Habilita a exibição do relatório no painel de Relatórios.
                        </ReactTooltip>
                      </label>

                      <select
                        id="displayInRelatorios"
                        name="displayInRelatorios"
                        className="form-control"
                        onChange={e => setReportment({
                          ...reportment,
                          displayInRelatorios: Number(e.target.value) === 1,
                        })}
                        value={reportment.displayInRelatorios ? 1 : 0}
                      >
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12">
                    {reportment.isFree ? (
                      <div className="alert alert-warning" role="alert">
                        Para utilizar as váriaveis de cliente, escola e usuário. Utilizei essas tags @clientId, @schoolId, @userId.
                      </div>
                    ) : null}
                    <div className="form-group mb-3">
                      <label htmlFor="query">SQL para execução do relatório</label>
                      <CodeMirror
                        style={{
                          border: '1px solid #eee',
                          borderRadius: '5px',
                        }}
                        value={reportment?.query}
                        height="300px"
                        extensions={[sql()]}
                        placeholder="SQL para execução do relatório"
                        onChange={(query: string) => setReportment({
                          ...reportment,
                          query,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center p-3">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </div>

          <div className="card-footer d-flex justify-content-end">
            <button type="button" className="btn btn-primary pe-3 ps-3" onClick={() => setShowPreviewModal(true)} disabled={!reportment.id}>Pré-visualizar relatório</button>
          </div>
        </div>

        {reportment?.id > 0 && (
          <Charts />
        )}

        <ReportmentSchools />

        {reportment?.id > 0 && (
          <Filters />
        )}

        <Buttons />
      </form>
    </>
  )
}

export default Reportment
