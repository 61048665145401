import React, { createContext, useCallback, useState } from 'react'
import RoleEnum from '@enums/Role.enum'
import useEntity from '@hooks/useEntity'
import RegistrationTypeEnum from '@enums/RegistrationType.enum'
import RegistrationStepEnum from '@enums/RegistrationStep.enum'
import IRegistrationEntityValidation from '@interfaces/IRegistrationEntityValidation'
import RegistrationTypeAction from '@actions/RegistrationTypeAction'
import RegistrationContextProps from './Registration.types'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'
import IDegree from '@interfaces/IDegree'
import * as $Registration from '@services/Users/Registration'
import useUpdateEffect from '@hooks/useUpdateEffect'
import { Outlet } from 'react-router-dom'

const generateToken = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

const RegistrationContext = createContext<RegistrationContextProps>({ } as RegistrationContextProps)

export const RegistrationProvider: React.FC<any> = () => {
  const [ token, setToken ] = useState<string>(generateToken())
  const { entities, setEntities, entity, setEntity } = useEntity()
  const [ role, setRole ] = useState<RoleEnum>(RoleEnum.Student)
  const [ registrationType, setRegistrationType ] = useState<RegistrationTypeEnum>(RegistrationTypeEnum.Upload)
  const [ step, setStep ] = useState<RegistrationStepEnum>(RegistrationStepEnum.Selection)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ validations, setValidations ] = useState<IRegistrationEntityValidation[]>([])
  const [ registrationTypeAction, setRegistrationTypeAction ] = useState<RegistrationTypeAction>(new RegistrationTypeAction(role))
  const [ entityLabels, setEntityLabels ] = useState<string[]>([ 'Alunos', 'Aluno' ])
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ usersUpdate, setUsersUpdate ] = useState<IRegistrationEntityValidation[]>([])

  useUpdateEffect(() => {
    setRegistrationTypeAction(new RegistrationTypeAction(role))
  }, [role])

  const addEntities = (entities: IRegistrationEntity[]) : Promise<void> => {
    return new Promise((resolve, reject) => {
      setEntities([])
      setValidations([])
      setUsersUpdate([])

      try {
        $Registration.store(role, token, entities)
          .then(({ data: { validations, rowIds, updates } }: any) => {
            setValidations(validations)
            setUsersUpdate(updates)

            rowIds.forEach((rowId: number, index: number) => {
              entities[index].id = rowId
            })

            try {
              // Pull entities with has validation to top of array
              validations.forEach((validation: IRegistrationEntityValidation) => {
                const index = entities.findIndex((entity: IRegistrationEntity) => entity.id === validation.rowId)

                if (index > -1) {
                  entities.unshift(entities.splice(index, 1)[0])
                }
              })

              usersUpdate.forEach((userUpdate: IRegistrationEntityValidation) => {
                const index = entities.findIndex((entity: IRegistrationEntity) => entity.id === userUpdate.rowId)

                if (index > -1) {
                  entities.unshift(entities.splice(index, 1)[0])
                }
              })
            } finally {
              // nothing happens...
            }

            setEntities([...entities])

            setTimeout(() => resolve(), 500)
          })
          .catch(reject)
      } catch (e) {
        reject()
      }
    })
  }

  const updateEntity = useCallback((entity: IRegistrationEntity) => {
    const index = entities.findIndex((e: IRegistrationEntity) => e.id === entity.id)

    if (index > -1) {
      entities[index] = entity
      setEntities([ ...entities ])
    }

    setEntity(entity)
  }, [entities, setEntities, setEntity])

  const removeEntity = useCallback((entity: IRegistrationEntity) => {
    const entityIndex = entities.findIndex((e: IRegistrationEntity) => e.id === entity.id)

    if (entityIndex > -1) {
      entities.splice(entityIndex, 1)
      setEntities([ ...entities ])
    }

    const validationIndex = validations.findIndex((v: IRegistrationEntityValidation) => v.rowId === entity.id)

    if (validationIndex > -1) {
      validations.splice(validationIndex, 1)
      setValidations([ ...validations ])
    }

    const updateIndex = usersUpdate.findIndex((u: IRegistrationEntityValidation) => u.rowId === entity.id)

    if (updateIndex > -1) {
      usersUpdate.splice(validationIndex, 1)
      setUsersUpdate([ ...usersUpdate ])
    }
  }, [entities, setEntities, validations, usersUpdate])

  const clearAll = () => {
    setEntities([])
    setValidations([])
    setUsersUpdate([])
    setToken(generateToken())
  }

  return (
    <RegistrationContext.Provider
      value={{
        token,
        setToken,
        entity,
        setEntity,
        entities,
        setEntities,
        addEntities,
        degrees,
        setDegrees,
        role,
        setRole,
        registrationType,
        setRegistrationType,
        step,
        setStep,
        isLoading,
        setIsLoading,
        validations,
        setValidations,
        registrationTypeAction,
        setRegistrationTypeAction,
        entityLabels,
        setEntityLabels,
        updateEntity,
        removeEntity,
        clearAll,
        usersUpdate,
        setUsersUpdate,
      }}
    >
      <Outlet />
    </RegistrationContext.Provider>
  )
}

export default RegistrationContext
