const columns: any[] = [
  {
    Header: 'ID Cartão',
    accessor: 'answerCardID',
  },
  {
    Header: 'Tipo',
    accessor: 'typeCard',
  },
  {
    Header: 'RE',
    accessor: 'userCode',
  },
  {
    Header: 'Aluno',
    accessor: 'StudentName',
  },
  {
    Header: 'Data inserção',
    accessor: 'insertedDate',
  },
  {
    Header: 'Upload',
    accessor: 'inserted',
  },
]

export default columns
